import { FC, MouseEvent, useEffect, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import {
	getRadabankCurrentCardOverdraftDataSelector,
	getRadabankCurrentCardSelector,
	getUserProfileSelector,
	showToast,
	updateRadabankOverdraftData,
	useAppDispatch,
	useAppSelector,
} from 'store';
import * as Yup from 'yup';
import { RadabankCardOverdraftRequestBanner } from 'components/RadabankCard';
import {
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	FIREBASE_EVENT_ANALYTICS_PAGE,
	RADABANK_CLIENT_REQUEST_OVER_INITIAL_VALUES,
} from 'utils/constants';
import { VALIDATION_ERROR } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { FirebaseAnalytics, RadabankService } from 'utils/services';
import {
	IRadabankClientRequestOverRequest,
	IRadabankInfoOptionsItem,
	IRadabankInfoSprDataResponse,
	IRadabankInternalCardCurrentRestDataResponse,
} from 'utils/types';
import { radabankCardOverdraftRequestValidationSchema } from 'utils/validation';
import { RadabankOverdraftAmountFormContent } from './OverdraftAmountFormContent';
import { RadabankOverdraftLetterFormContent } from './OverdraftLetterFormContent';
import styles from './index.module.scss';

interface RadabankCardOverdraftCreateProps {
	isShowSprData: boolean;
	onChangeShowSprData: (value: boolean) => void;
}

export const RadabankCardOverdraftCreate: FC<RadabankCardOverdraftCreateProps> = ({
	isShowSprData,
	onChangeShowSprData,
}) => {
	const dispatch = useAppDispatch();

	const overdraftData = useAppSelector(getRadabankCurrentCardOverdraftDataSelector);
	const currentCard = useAppSelector(getRadabankCurrentCardSelector);
	const profile = useAppSelector(getUserProfileSelector);

	const [isShowBanner, setIsShowBanner] = useState(false);
	const [internalSprData, setInternalSprData] = useState<IRadabankInfoSprDataResponse | null>(null);
	const [initialValues, setInitialValues] = useState(RADABANK_CLIENT_REQUEST_OVER_INITIAL_VALUES);

	const validationSchema = useMemo(() => {
		const amountSchema = Yup.string()
			.required(VALIDATION_ERROR.REQUIRED)
			.test('is-multiple-of-1000', 'Сума має бути кратна 1000', (value) => {
				return !(+value % 1000);
			});
		let minAmountSchema: Yup.StringSchema = Yup.string();
		let maxAmountSchema: Yup.StringSchema = Yup.string();
		if (overdraftData.data?.overamountmin) {
			minAmountSchema = amountSchema.concat(
				Yup.string().test('minsum', `Мінімальна сума ${overdraftData.data.overamountmin}`, (value) => {
					return (
						!!value && +value >= +(overdraftData.data as IRadabankInternalCardCurrentRestDataResponse).overamountmin
					);
				})
			);
		}
		if (overdraftData.data?.overamountmax) {
			maxAmountSchema = amountSchema.concat(
				Yup.string().test('maxsum', `Максимальна сума ${overdraftData.data.overamountmax}`, (value) => {
					return (
						!!value && +value <= +(overdraftData.data as IRadabankInternalCardCurrentRestDataResponse).overamountmax
					);
				})
			);
		}
		return radabankCardOverdraftRequestValidationSchema.concat(
			Yup.object().shape({
				amount: amountSchema.concat(minAmountSchema).concat(maxAmountSchema),
			})
		);
	}, [overdraftData.data]);

	useEffect(() => {
		if (overdraftData.data && currentCard && profile) {
			setInitialValues({
				...initialValues,
				amount: overdraftData.data.overdraft,
				cardid: currentCard.id,
				userId: profile.userId,
			});
		}
	}, [overdraftData.data, currentCard, profile]);

	useEffect(() => {
		if (profile) {
			handleGetInternalSprData(profile.userId);
		}
	}, [profile]);

	const handleGetInternalSprData = async (userId: string) => {
		try {
			const response = await RadabankService.getInternalSprData(userId);
			setInternalSprData(
				Object.entries(response.data).reduce(
					(acc, entry: [string, IRadabankInfoOptionsItem[]]) =>
						Array.isArray(entry[1])
							? {
									...acc,
									[entry[0]]: entry[1].map((item) => ({ label: item.name, value: item.id })),
							  }
							: acc,
					{} as IRadabankInfoSprDataResponse
				)
			);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleSubmit = async (values: IRadabankClientRequestOverRequest): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_OVERDRAFT,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CREATE
			);
			await dispatch(updateRadabankOverdraftData(values)).unwrap();
			setIsShowBanner(true);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleClickShowLetterForm = (event: MouseEvent<HTMLIonButtonElement>) => {
		event.preventDefault();
		event.stopPropagation();
		onChangeShowSprData(true);
	};

	if (isShowBanner) return <RadabankCardOverdraftRequestBanner />;
	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
		>
			<Form className={styles.content}>
				{isShowSprData && internalSprData ? (
					<RadabankOverdraftLetterFormContent internalSprData={internalSprData} />
				) : (
					<RadabankOverdraftAmountFormContent onSubmit={handleClickShowLetterForm} />
				)}
			</Form>
		</Formik>
	);
};

import { FC, PropsWithChildren, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { OPTIONAL_SOCIAL_STATUSES } from 'utils/constants';

export const SocialStatusContainer: FC<PropsWithChildren> = ({ children }) => {
	const { control, resetField } = useFormContext();

	const socialStatus = useWatch({ control, name: 'social_status' });

	const isOptionalFields = OPTIONAL_SOCIAL_STATUSES.includes(socialStatus);

	const handleResetOptionalFields = () => {
		resetField('work_place');
		resetField('work_dolg');
		resetField('term_work');
	};

	useEffect(() => {
		if (isOptionalFields) {
			handleResetOptionalFields();
		}
	}, [isOptionalFields]);

	return !isOptionalFields ? children : null;
};

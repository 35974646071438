import { AxiosResponse } from 'axios';
import { USER_API } from 'core';
import { USER_API_URL } from 'utils/enums';
import { removeEmptyProps } from 'utils/helpers';
import { IGetMetersListRequest, IMeterDataResponse, IUpdateMetersRequest } from 'utils/types';

// remove version from url (last 3 chars), for example original env value: https://www...com/v1
const baseURL = (process.env.REACT_APP_USER_API_URL as string).slice(0, -3);

class MetersService {
	static getMetersList = async (reqBody: IGetMetersListRequest): Promise<AxiosResponse<IMeterDataResponse[]>> =>
		USER_API.post(USER_API_URL.METER_GET_LIST, removeEmptyProps(reqBody), {
			baseURL,
		});

	static updateMeterValue = async (reqBody: IUpdateMetersRequest): Promise<AxiosResponse<IMeterDataResponse[]>> =>
		USER_API.post(USER_API_URL.METER_UPDATE_VALUE, removeEmptyProps(reqBody), {
			baseURL,
		});
}

export { MetersService };

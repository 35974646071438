export enum ROUTER_URL {
	MAIN = '/',

	PROFILE = '/profile',
	SETTINGS = '/profile/settings',
	METERS = '/profile/meters',

	SERVICES_CATEGORIES = '/profile/services',
	SERVICES_BY_CATEGORY_ID = '/profile/services/:categoryId',
	SERVICE = '/profile/services/:categoryId/:serviceId',
	SERVICE_PROVIDER = '/profile/services/:categoryId/:serviceId',
	SERVICES_ARCHIVE_LIST = '/profile/services/archive',
	SERVICES_ARCHIVE_REPORT = '/profile/services/archive/:id',

	RECEIPT_MAIN = '/profile/receipt',
	SINGLE_RECEIPT = '/profile/receipt',
	PROFILE_RECEIPT = '/profile/receipt/:apartmentAccountId',
	RECEIPT_ARCHIVE_LIST = '/profile/receipt/:apartmentAccountId/archive',
	RECEIPT_ARCHIVE = '/profile/receipt/:apartmentAccountId/archive/:id',

	ADD_ADDRESS = '/profile/add-address',

	PUSH_NOTIFICATIONS_BANNER = '/profile/push/banner',
	CAMERA_ACCESS_BANNER = '/profile/camera-access/banner',

	PAYMENT_STATUS_MAIN = '/profile/payment/status',
	RECEIPT_PAYMENT_STATUS = '/profile/payment/status/:apartmentAccountId/:billId',
	SERVICE_PAYMENT_STATUS = '/profile/payment/status/:categoryId/:serviceId/:billId',

	RADABANK_CARD = '/profile/card',
	RADABANK_CARD_CREATE_CARD_BANNER = '/profile/card/banner',
	RADABANK_CARD_AUTH_INFO_BEGIN = '/profile/card/auth/info-begin',
	RADABANK_CARD_AUTH_APPROVE_CODE = '/profile/card/auth/approve-code',
	RADABANK_CARD_AUTH_INFO_DIYA_BEGIN = '/profile/card/auth/info-diya',
	RADABANK_CARD_AUTH_DOCUMENTS_SUBMISSION = '/profile/card/auth/documents-submition',
	RADABANK_CARD_AUTH_INFO_IMAGE_SET_PHOTO = '/profile/card/auth/set-photo',
	RADABANK_CARD_AUTH_INFO_SET_ANKETA = '/profile/card/auth/set-anketa',
	RADABANK_CARD_AUTH_INFO_SIGN_DOCUMENTS = '/profile/card/auth/sign-documents',
	RADABANK_CARD_AUTH_INFO_IMAGE_SET_SIGN = '/profile/card/auth/set-sign',
	RADABANK_CARD_AUTH_SIGN_DOCUMENTS_SUBMISSION = '/profile/card/auth/sign-documents-submition',
	RADABANK_CARD_AUTH_INTERNAL_AUTHORIZE = '/profile/card/auth/verify-sms',
	RADABANK_CARD_INTERNAL_ACTIVATE = '/profile/card/internal-activate',
	RADABANK_CARD_INTERNAL_CREATE_CARD = '/profile/card/create-card',
	RADABANK_CARD_INTERNAL_CREATE_CARD_SUBMITION = '/profile/card/create-card-submition',
	RADABANK_CARD_SETTINGS = '/profile/card/settings',
	RADABANK_CARD_OPERATION = '/profile/card/operation',
	RADABANK_CARD_OPERATION_BANNER = '/profile/card/operation/banner',
	RADABANK_CARD_OPERATION_TOP_UP = '/profile/card/operation-card-top-up',
	RADABANK_CARD_ORDER = '/profile/card/order',
	RADABANK_CARD_REISSUE = '/profile/card/reissue',
	RADABANK_CARD_CREDIT_LIMIT = '/profile/card/credit-limit',
	RADABANK_CARD_CREDIT_LIMIT_IN_PROGRESS = '/profile/card/credit-limit-in-progress',
	RADABANK_CARD_SECURE_SETTINGS = '/profile/card/secure-settings',
	RADABANK_CARD_BLOCK_CARD = '/profile/card/block-card',
	RADABANK_CARD_UNBLOCK_CARD = '/profile/card/unblock-card',
	RADABANK_CARD_DETAILS_AND_DOCUMENTS = '/profile/card/details-and-documents',
	RADABANK_CARD_DETAILS_AND_DOCUMENTS_BALANCE = '/profile/card/details-and-documents/balance',
	RADABANK_CARD_PIN_CODE_CHANGE = '/profile/card/pin-code-change',
	RADABANK_CARD_PIN_CODE_SMS = '/profile/card/pin-code-sms',
	RADABANK_CARD_SMS_INFO = '/profile/card/sms-info',
	RADABANK_CARD_AUTH_STATUS = '/profile/card/status',
	RADABANK_CARD_OTHER_DEVICE = '/profile/card/other-device',
	RADABANK_CARD_OVERDRAFT_DETAILS = '/profile/card/overdraft-details',

	AUTH = '/auth',
	AUTH_CHECK_PHONE = '/auth/check-phone',
	AUTH_LOGIN = '/auth/login',
	AUTH_LOGIN_VERIFY_DEVICE = '/auth/verify-device',
	AUTH_PASSWORD_SETUP = '/auth/pin-setup',
	AUTH_VERIFY_USER_SIGNUP = '/auth/verify-user',
	AUTH_UPDATE_PROFILE = '/auth/update-profile',
	AUTH_SIGN_UP = '/auth/sign-up',
	AUTH_PASSWORD_RESTORE = '/auth/password/restore',
	AUTH_RESTORE_VERIFY = '/auth/password/restore/verify',

	RECEIPT_PAY_DEEPLINK = '/pay-by-params',
	RECEIPT_PAY_PUBLIC = '/bill',
	RECEIPT_PAY_PUBLIC_STATUS = '/pay/status/:billId',

	WELCOME = '/welcome',
	ERROR = '/error',
	ERROR_NETWORK = '/error/network',
	NOT_FOUND = '/not-found',
}

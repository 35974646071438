import { FC } from 'react';
import { Typography } from '@mui/material';
import { getAmountTitle } from 'utils/helpers';
import styles from './index.module.scss';

interface RadabankFeePreviewProps {
	amount: number;
	currency: string;
}

export const RadabankFeePreview: FC<RadabankFeePreviewProps> = ({ amount, currency }) => {
	return (
		<div className={styles.content}>
			<Typography color="#404040" variant="body2">
				Комісія
			</Typography>
			<Typography color="#404040" variant="body2" textAlign="right">
				{getAmountTitle(amount, currency)}
			</Typography>
		</div>
	);
};

import CardBlockIcon from 'assets/icons/radabank/card-block.svg';
import CardCreditLimitIcon from 'assets/icons/radabank/card-credit-limit.svg';
import CardDocumentsIcon from 'assets/icons/radabank/card-documents.svg';
import CardOrderIcon from 'assets/icons/radabank/card-order.svg';
import CardPinIcon from 'assets/icons/radabank/card-pin.svg';
import CardPinSmsIcon from 'assets/icons/radabank/card-pin-sms.svg';
// import CardReissueIcon from 'assets/icons/radabank/card-reissue.svg';
import CardSecureIcon from 'assets/icons/radabank/card-secure.svg';
import CardSmsInfoIcon from 'assets/icons/radabank/card-sms-info.svg';
import CardUnblockIcon from 'assets/icons/radabank/card-unblock.svg';
import { ROUTER_URL } from 'utils/enums';
import { INavbarItem } from 'utils/types';

export const CARD_OPERATIONS_NAV_LIST: INavbarItem[] = [
	{
		label: 'Замовити пластикову картку',
		icon: CardOrderIcon,
		path: ROUTER_URL.RADABANK_CARD_ORDER,
		description: 'Доставка пластикової картки Новою Поштою',
	},
	// {
	// 	label: 'Перевипустити картку',
	// 	icon: CardReissueIcon,
	// 	path: ROUTER_URL.RADABANK_CARD_REISSUE,
	// 	description: 'Замінити картку на пластикову картку',
	// },
	{
		label: 'Кредитний ліміт',
		icon: CardCreditLimitIcon,
		path: ROUTER_URL.RADABANK_CARD_CREDIT_LIMIT,
		description: 'Змінити суму ліміту овердрафту відповідно до умов ДКБО ФО',
	},
	{
		label: 'Налаштування безпеки',
		icon: CardSecureIcon,
		path: ROUTER_URL.RADABANK_CARD_SECURE_SETTINGS,
		description: 'Змінити ліміти карти',
	},
	{
		label: 'Блокування картки',
		icon: CardBlockIcon,
		path: ROUTER_URL.RADABANK_CARD_BLOCK_CARD,
		description: 'Блокування викраденої або загубленої карти',
	},
	{
		label: 'Розблокування замороженої картки',
		icon: CardUnblockIcon,
		path: ROUTER_URL.RADABANK_CARD_UNBLOCK_CARD,
		description: 'Розблокування викраденої або загубленої карти неможливе',
	},
];

export const CARD_DATA_NAV_LIST: INavbarItem[] = [
	{
		label: 'Реквізити та документи',
		icon: CardDocumentsIcon,
		path: ROUTER_URL.RADABANK_CARD_DETAILS_AND_DOCUMENTS,
		description: 'Реквізити картки для поповнення',
	},
];

export const CARD_SETTINGS_NAV_LIST_PUBLIC: INavbarItem[] = [
	{
		label: 'СМС інформування',
		icon: CardSmsInfoIcon,
		path: ROUTER_URL.RADABANK_CARD_SMS_INFO,
	},
];

export const CARD_SETTINGS_NAV_LIST_PRIVATE: INavbarItem[] = [
	{
		label: 'Зміна PIN-коду картки',
		icon: CardPinIcon,
		path: ROUTER_URL.RADABANK_CARD_PIN_CODE_CHANGE,
	},
	{
		label: 'СМС з PIN-кодом картки',
		icon: CardPinSmsIcon,
		path: ROUTER_URL.RADABANK_CARD_PIN_CODE_SMS,
	},
];

import { FC } from 'react';
import { useParams } from 'react-router';
import { Typography } from '@mui/material';
import { getRadabankCurrentCardSelector, getRadabankTransactionByIdSelector, useAppSelector } from 'store';
import { RadabankSectionHeader } from 'components/RadabankCard';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { getAmountTitle } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics } from 'utils/services';
import styles from './styles.module.scss';

export const RadabankTransactionDetailsPage: FC = () => {
	const { transactionId } = useParams<{ transactionId: string }>();

	const handleClickBack = useGoBackRadabank();

	const transaction = useAppSelector((state) => getRadabankTransactionByIdSelector(state, transactionId));
	const currentCard = useAppSelector(getRadabankCurrentCardSelector);

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.TRANSACTION_DETAILS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	if (!transaction || !currentCard) return null;

	const formattedDsTextInfo = transaction.dstextinfo.replace(/chr\(10\)|\n/g, '<br />');

	return (
		<div className="page">
			<RadabankSectionHeader title="Деталі платежу" onClickBack={handleGoBack} />
			<div className={styles.transaction}>
				<div className={styles.transaction__header}>
					<img
						src={`${process.env.REACT_APP_RADABANK_CATEGORY_IMAGE_URL}/${transaction.merchantpicture}`}
						alt=""
						className={styles.transaction__icon}
					/>
					<Typography variant="caption" color="var(--color-neutral-500)">
						{transaction.categoryname}
					</Typography>
				</div>

				<div className={styles.transaction__date_amount}>
					<Typography color="var(--color-neutral-500)">{transaction.dateonline}</Typography>

					<Typography variant="h4" fontWeight="600">
						{getAmountTitle(transaction.amount, currentCard.curriso)}
					</Typography>
				</div>

				<div className={styles.transaction__destination_merchant}>
					<Typography>{transaction.merchantname}</Typography>
					{formattedDsTextInfo ? (
						<Typography component="p" dangerouslySetInnerHTML={{ __html: formattedDsTextInfo }} />
					) : (
						<Typography>{transaction.destination}</Typography>
					)}
				</div>
			</div>
		</div>
	);
};

import { FC } from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as InfoIcon } from 'assets/icons/info-circle.svg';
import styles from './index.module.scss';

interface RadabankOperationDetailRowProps {
	title: string;
	description: string;
	isShowInfo?: boolean;
}

export const RadabankOperationDetailRow: FC<RadabankOperationDetailRowProps> = ({ title, description, isShowInfo }) => (
	<div className={styles.content}>
		<div className={styles.content__title}>
			<Typography variant="caption" color="var(--color-neutral-500)">
				{title}
			</Typography>
			{!!isShowInfo && <InfoIcon className={styles.content__icon} />}
		</div>
		<Typography variant="subtitle2">{description}</Typography>
	</div>
);

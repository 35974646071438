// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UpdatePasswordNative_wrapper__7XHFl > button {
  position: absolute;
  border-radius: var(--spacing-3);
  z-index: 1;
  padding: var(--spacing-3);
  top: calc(env(safe-area-inset-top) + 10px);
  left: 25px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.UpdatePasswordNative_wrapper__7XHFl > button svg path {
  stroke: var(--color-neutral-white);
}
.UpdatePasswordNative_wrapper__content__mEZl- {
  padding-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Auth/PasswordSetup/UpdatePasswordNative/index.module.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,+BAAA;EACA,UAAA;EACA,yBAAA;EACA,0CAAA;EACA,UAAA;EACA,0CAAA;AAAJ;AAEI;EACE,kCAAA;AAAN;AAIE;EACE,iBAAA;AAFJ","sourcesContent":[".wrapper {\n  & > button {\n    position: absolute;\n    border-radius: var(--spacing-3);\n    z-index: 1;\n    padding: var(--spacing-3);\n    top: calc(env(safe-area-inset-top) + 10px);\n    left: 25px;\n    border: 1px solid rgba(255, 255, 255, 0.3);\n\n    & svg path {\n      stroke: var(--color-neutral-white);\n    }\n  }\n\n  &__content {\n    padding-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `UpdatePasswordNative_wrapper__7XHFl`,
	"wrapper__content": `UpdatePasswordNative_wrapper__content__mEZl-`
};
export default ___CSS_LOADER_EXPORT___;

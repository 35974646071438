import dayjs from 'dayjs';
import * as Yup from 'yup';
import {
	OPTIONAL_SOCIAL_STATUSES,
	RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT,
	RADABANK_MIN_ALLOWED_TRANSACTION_AMOUNT,
} from 'utils/constants';
import { RADABANK_VALIDATION_ERROR, VALIDATION_ERROR } from 'utils/enums';
import { checkIsRadabankIban, getAmountTitle, validateAmountLimits } from 'utils/helpers';
import { radankPhoneValidationSchema } from './phone.schema';
import { emailValidationSchema } from './user.schema';

const requireIfSocialStatusIsNotOptional = () =>
	Yup.string().when('social_status', ([socialStatus], schema) => {
		return OPTIONAL_SOCIAL_STATUSES.includes(socialStatus) ? schema : schema.required(VALIDATION_ERROR.REQUIRED);
	});

export const radabankSurveyGeneralStepValidationSchema = Yup.object().shape({
	secret: Yup.string()
		.required(VALIDATION_ERROR.REQUIRED)
		.test('first character', VALIDATION_ERROR.FIRST_CHARACTER, (value) => !/^[-']/.test(value))
		.matches(/^[a-zA-Z0-9-]*$/g, VALIDATION_ERROR.LATIN_LETTERS_AND_NUMBERS),
	email: emailValidationSchema.required(VALIDATION_ERROR.REQUIRED),
	social_status: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	work_place: requireIfSocialStatusIsNotOptional(),
	work_dolg: requireIfSocialStatusIsNotOptional(),
	term_work: requireIfSocialStatusIsNotOptional(),
	amount_in: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	amount_out: Yup.string()
		.required(VALIDATION_ERROR.REQUIRED)
		.test('is-less-than-amount-in', VALIDATION_ERROR.AMOUNT_OUT_LESS_THAN_AMOUNT_IN, (value, { parent }) => {
			const amountIn = Number(parent.amount_in);
			const amountOut = Number(value);

			return amountIn >= amountOut;
		}),
});

export const radabankSurveyPropertyStatusStepValidationSchema = Yup.object().shape({
	scope_enterprise: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	amount_add: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	have_flat: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	have_house: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	have_car: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	have_land: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	have_deposit: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	source_income: Yup.string().when('amount_add', ([amountAdd], schema) =>
		Number(amountAdd) ? schema.required(VALIDATION_ERROR.REQUIRED) : schema
	),
	reason_credit: Yup.string().required(VALIDATION_ERROR.REQUIRED),
});

export const radabankSurveyHomeAddressStepValidationSchema = Yup.object().shape({
	adr_input: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	adr_region_id: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	adr_district_id: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	adr_citytype_id: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	adr_city_id: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	adr_streettype_id: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	adr_street: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	adr_house: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	adr_flat: Yup.string().required(VALIDATION_ERROR.REQUIRED),
});

export const radabankSurveyTogglersStepValidationSchema = Yup.object().shape({
	isfop: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	nalog_resident_usa: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	nalog_resident_other: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	connect_agressor: Yup.string().required(VALIDATION_ERROR.REQUIRED),
});

export const radabankSurveySocialStatusValidationSchema = Yup.object().shape({
	marital_status: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	edication: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	criminal: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	contact_phone: radankPhoneValidationSchema,
	contact_lastname: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	contact_firstname: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	contact_middlename: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	contact_person: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	contact_person_ext: Yup.string().when('contact_person', {
		is: '12',
		then: (schema) => {
			return schema.required(VALIDATION_ERROR.REQUIRED);
		},
	}),
});

export const radabankCardCreateCardPaymentValidationSchema = Yup.object().shape({
	card: Yup.string().required(VALIDATION_ERROR.REQUIRED).min(16, VALIDATION_ERROR.REQUIRED),
	amount: Yup.string()
		.required(VALIDATION_ERROR.REQUIRED)
		.test(
			'match-limits',
			`Сума повинна бути від ${getAmountTitle(RADABANK_MIN_ALLOWED_TRANSACTION_AMOUNT)} до ${getAmountTitle(
				RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT
			)}`,
			(value) =>
				validateAmountLimits(value, RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT, RADABANK_MIN_ALLOWED_TRANSACTION_AMOUNT)
		),
});

export const radabankCardCreateTransferPaymentValidationSchema = Yup.object().shape({
	amount: Yup.string()
		.required(VALIDATION_ERROR.REQUIRED)
		.test(
			'match-limits',
			`Сума повинна бути від ${getAmountTitle(RADABANK_MIN_ALLOWED_TRANSACTION_AMOUNT)} до ${getAmountTitle(
				RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT
			)}`,
			(value) =>
				validateAmountLimits(value, RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT, RADABANK_MIN_ALLOWED_TRANSACTION_AMOUNT)
		),
	ibanrec: Yup.string()
		.min(29, RADABANK_VALIDATION_ERROR.IBAN)
		.max(29, RADABANK_VALIDATION_ERROR.IBAN)
		.required(VALIDATION_ERROR.REQUIRED),
	namerec: Yup.string()
		.when('ibanrec', (values: string[], schema) =>
			!checkIsRadabankIban(values[0] ?? '')
				? schema.required(VALIDATION_ERROR.REQUIRED).max(38, 'Максимальна довжина поля 38 символів')
				: schema
		)
		.when('isIPN', (value: boolean[], schema) => (!value[0] ? schema.notRequired() : schema)),
	innrec: Yup.string()
		.when('ibanrec', (values: string[], schema) =>
			!checkIsRadabankIban(values[0] ?? '') ? schema.required(VALIDATION_ERROR.REQUIRED) : schema
		)
		.when('isIPN', (value: boolean[], schema) => (!value[0] ? schema.notRequired() : schema))
		.max(10, 'Максимальна довжина поля 10 символів'),
	docseriarec: Yup.string().test({
		name: 'docseriarec',
		test: (value, context) =>
			value?.length && value.length !== 2
				? context.createError({ message: 'Мінімальна довжина поля 2 символи' })
				: true,
	}),
	docnumberrec: Yup.string()
		.when('isIPN', (value: (boolean | string)[], schema) =>
			!value[0] ? schema.required(VALIDATION_ERROR.REQUIRED).min(9, 'Мінімальна довжина поля 9 символи') : schema
		)
		.when('docseriarec', (value: (boolean | string)[], schema) =>
			value[0]
				? schema
						.required(VALIDATION_ERROR.REQUIRED)
						.min(6, 'Мінімальна довжина поля 6 символи')
						.max(6, 'Максимальна довжина поля 6 символи')
				: schema
		),
	destination: Yup.string()
		.required(VALIDATION_ERROR.REQUIRED)
		.min(6, 'Мінімальна довжина поля 6 символів')
		.max(160, 'Максимальна довжина поля 160 символів'),
	countryrec: Yup.string().when('isResident', (value: boolean[], schema) =>
		!value[0] ? schema.required(VALIDATION_ERROR.REQUIRED) : schema
	),
});

export const radabankCardCreateTransferBudgetPaymentValidationSchema = Yup.object().shape({
	amount: Yup.string()
		.required(VALIDATION_ERROR.REQUIRED)
		.test(
			'match-limits',
			`Сума повинна бути від ${getAmountTitle(RADABANK_MIN_ALLOWED_TRANSACTION_AMOUNT)} до ${getAmountTitle(
				RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT
			)}`,
			(value) =>
				validateAmountLimits(value, RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT, RADABANK_MIN_ALLOWED_TRANSACTION_AMOUNT)
		),
	ibanrec: Yup.string()
		.min(29, RADABANK_VALIDATION_ERROR.IBAN)
		.max(29, RADABANK_VALIDATION_ERROR.IBAN)
		.required(VALIDATION_ERROR.REQUIRED),
	namerec: Yup.string().required(VALIDATION_ERROR.REQUIRED).max(38, 'Максимальна довжина поля 38 символів'),
	innrec: Yup.string().required(VALIDATION_ERROR.REQUIRED).max(10, 'Максимальна довжина поля 10 символів'),
	budgetcode: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	budgetadd: Yup.string().required(VALIDATION_ERROR.REQUIRED),
});

export const radabankCardNPoshtaCreateRequestValidationSchema = Yup.object().shape({
	cityref: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	// streetref: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	divref: Yup.string().required(VALIDATION_ERROR.REQUIRED),
});

export const radabankCardOverdraftRequestValidationSchema = Yup.object().shape({
	criminal: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	amount_out: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	contact_phone: radankPhoneValidationSchema,
	contact_lastname: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	contact_firstname: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	contact_middlename: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	contact_person: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	term_work: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	maritial_status: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	education: Yup.string().required(VALIDATION_ERROR.REQUIRED),
});

export const radabankAddInternetRuleValidationSchema = Yup.object().shape({
	datestart: Yup.string()
		.required(VALIDATION_ERROR.REQUIRED)
		.test('is-before-finish', 'Некоректна дата', (value, { parent }) => {
			const { datefinish } = parent;
			const formattedStartDate = dayjs(value).startOf('d');

			if (formattedStartDate.isBefore(dayjs().startOf('d'))) {
				return false;
			}

			if (datefinish) {
				const formattedFinishDate = dayjs(datefinish).endOf('d');

				return formattedStartDate.isBefore(formattedFinishDate);
			}
			return true;
		}),
	datefinish: Yup.string()
		.required(VALIDATION_ERROR.REQUIRED)
		.test('is-after-start', 'Некоректна дата', (value, { parent }) => {
			const { datestart } = parent;
			const formattedFinishDate = dayjs(value).endOf('d');

			if (formattedFinishDate.isBefore(dayjs().startOf('d'))) {
				return false;
			}

			if (datestart) {
				const formattedStartDate = dayjs(datestart).startOf('d');

				return formattedStartDate.isBefore(formattedFinishDate);
			}
			return true;
		}),
});

export const radabankAddCountryRuleValidationSchema = radabankAddInternetRuleValidationSchema.concat(
	Yup.object().shape({
		countryid: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	})
);

export const radabankAddCurrencyRuleValidationSchema = radabankAddInternetRuleValidationSchema.concat(
	Yup.object().shape({
		currid: Yup.string().required(VALIDATION_ERROR.REQUIRED),
	})
);

export const radabankSmsInfoValidationSchema = Yup.object().shape({
	phone: radankPhoneValidationSchema,
});

export const radabankTopUpValidationSchema = Yup.string().test('is-valid-amount', (value) =>
	validateAmountLimits(value ?? '', RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT, RADABANK_MIN_ALLOWED_TRANSACTION_AMOUNT)
);

import { FC } from 'react';
import { useHistory } from 'react-router';
import { Capacitor } from '@capacitor/core';
import { Typography } from '@mui/material';
import { ReactComponent as LogoSvg } from 'assets/images/logo-circle-shadow.svg';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import { FirebaseAnalytics } from 'utils/services';
import styles from './index.module.scss';

export const HostCardBanner: FC = () => {
	const history = useHistory();

	const handleClickCreateCard = (): void => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SETTINGS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.CREATE_CARD_RADABANK
		);
		if (Capacitor.isNativePlatform()) {
			history.push(ROUTER_URL.RADABANK_CARD);
		} else {
			history.push(ROUTER_URL.PROFILE, { initialSlide: 1 });
		}
	};

	return (
		<div className={styles.wrapper} onClick={handleClickCreateCard}>
			<div className={styles.logo}>
				<LogoSvg />
			</div>
			<div className={styles.content}>
				<Typography textTransform="uppercase" className={styles.title} variant="h5" color="var(--color-neutral-white)">
					Host card
				</Typography>
				<div>
					<Typography className={styles.subtitle} variant="subtitle1" color="#FFC89A">
						Замовити картку зараз
					</Typography>
				</div>
			</div>
		</div>
	);
};

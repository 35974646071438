// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServicesCategories_wrapper__-KT1\\+ {
  background-color: var(--color-neutral-white);
}
.ServicesCategories_wrapper__-KT1\\+ .ServicesCategories_header__Vq6oo {
  flex-direction: row;
  gap: var(--spacing-4);
}
.ServicesCategories_wrapper__-KT1\\+ .ServicesCategories_header__button__\\+kbYS svg path {
  fill: var(--color-primary-500);
}`, "",{"version":3,"sources":["webpack://./src/pages/ServicesCategories/index.module.scss"],"names":[],"mappings":"AAGA;EACC,4CAAA;AAFD;AAIC;EACC,mBAAA;EACA,qBAAA;AAFF;AAKG;EACC,8BAAA;AAHJ","sourcesContent":["@import 'assets/styles/mixins';\n@import 'assets/styles/media';\n\n.wrapper {\n\tbackground-color: var(--color-neutral-white);\n\n\t.header {\n\t\tflex-direction: row;\n\t\tgap: var(--spacing-4);\n\n\t\t&__button {\n\t\t\tsvg path {\n\t\t\t\tfill: var(--color-primary-500);\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ServicesCategories_wrapper__-KT1+`,
	"header": `ServicesCategories_header__Vq6oo`,
	"header__button": `ServicesCategories_header__button__+kbYS`
};
export default ___CSS_LOADER_EXPORT___;

import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { updateUserProfile } from 'store/user-service/actions';
import { ConfirmPasswordNative, SetPasswordNative } from 'components/Auth';
import { ArrowBackButton, Loader } from 'components/shared';
import { ERROR_CODE, ERROR_MESSAGE, SETUP_PASSWORD_STEP, STORAGE_KEY } from 'utils/enums';
import { decryptData, encryptData, getErrorMessage, getErrorResponse } from 'utils/helpers';
import { StorageService } from 'utils/services';
import { PinScreenMobileVariantsType } from '../helpers';
import styles from './index.module.scss';

interface ChangeUserPasswordProps {
	onClose: () => void;
}

export const UpdatePasswordNative: FC<ChangeUserPasswordProps> = ({ onClose }) => {
    const dispatch = useAppDispatch();

	const [step, setStep] = useState<SETUP_PASSWORD_STEP>(SETUP_PASSWORD_STEP.CURRENT);
	const [userPassword, setUserPassword] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [passwordData, setPasswordData] = useState({
		currentPassword: '',
		password: '',
		confirmPassword: '',
	});

	useEffect(() => {
		StorageService.get(STORAGE_KEY.USER_PASSWORD).then((value) => {
			if (value) {
				setUserPassword(decryptData(value as string));
			}
		});
	}, []);

	const handleClose = () => {
		if (step === SETUP_PASSWORD_STEP.CONFIRM) {
			setPasswordData({ ...passwordData, password: '' });
			setStep(SETUP_PASSWORD_STEP.SET);
		} else {
			onClose();
		}
	};

	const handleChangePassword = (event: ChangeEvent<HTMLInputElement>): void => {
		setPasswordData({ ...passwordData, [event.target.name]: event.target.value });
	};

	const onSubmit = async () => {
		try {
			setIsLoading(true)
			await dispatch(updateUserProfile({ password: userPassword, newPassword: passwordData.password })).unwrap();
			await StorageService.set(STORAGE_KEY.USER_PASSWORD, encryptData(passwordData.password));
			setIsLoading(false);
			dispatch(showToast({ message: 'Пароль змінено успішно', color: 'success' }));
			onClose();
		} catch (error) {
			const errorResponse = getErrorResponse(error);
			dispatch(
				showToast({
					message:
						errorResponse?.errorData?.code === ERROR_CODE.INVALID_CURRENT_PASSWORD
							? ERROR_MESSAGE.INVALID_CURRENT_PASSWORD
							: getErrorMessage(error),
				})
			);
			setPasswordData({ ...passwordData, confirmPassword: '' });
			setIsLoading(false);
		}
	};

	const content = useMemo(() => {
		switch (step) {
			case SETUP_PASSWORD_STEP.SET:
				return (
					<SetPasswordNative
						contentClasses={styles.wrapper__content}
						isVisibleDescription={false}
						value={passwordData.password}
						onChange={handleChangePassword}
						type={PinScreenMobileVariantsType.SetNewPassword}
						maxLength={6}
						onSubmit={() => setStep(SETUP_PASSWORD_STEP.CONFIRM)}
					/>
				);

			case SETUP_PASSWORD_STEP.CONFIRM:
				return (
					<ConfirmPasswordNative
						contentClasses={styles.wrapper__content}
						isVisibleDescription={false}
						value={passwordData.confirmPassword}
						password={passwordData.password}
						onChange={handleChangePassword}
						maxLength={6}
						onSubmit={onSubmit}
						variant={PinScreenMobileVariantsType.ConfirmNewPassword}
					/>
				);

			default:
				return (
					<ConfirmPasswordNative
						contentClasses={styles.wrapper__content}
						isVisibleDescription={false}
						value={passwordData.currentPassword}
						password={userPassword}
						maxLength={6}
						onChange={(e) => setPasswordData({ ...passwordData, currentPassword: e.target.value })}
						onSubmit={() => setStep(SETUP_PASSWORD_STEP.SET)}
						variant={PinScreenMobileVariantsType.CheckCurrentPassword}
					/>
				);
		}
	}, [passwordData, step]);

	return (
		<>
			{isLoading && <Loader />}
			<div className={styles.wrapper}>
				<ArrowBackButton onClick={handleClose} />
				{content}
			</div>
		</>
	);
};

import { FC, HTMLProps, MouseEvent, useState } from 'react';
import { useHistory } from 'react-router';
import { IconButton } from '@mui/material';
import { ReactComponent as SettingsSVG } from 'assets/icons/tabs/settings.svg';
import classNames from 'classnames';
import { getUserProfileSelector, showToast, useAppDispatch, useAppSelector } from 'store';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { FirebaseAnalytics, RadabankService } from 'utils/services';
import { IUserProfileResponse } from 'utils/types';
import { RadabankCardDetails } from '../CardDetails';
import { RadabankCardPreview } from '../CardPreview';
import { RadabankCardPreviewSecured } from '../CardPreviewSecured';
import styles from './index.module.scss';

export const RadabankCardFlip: FC<HTMLProps<HTMLDivElement>> = ({ className, children, ...props }) => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const profile = useAppSelector(getUserProfileSelector);

	const [cvv, setCvv] = useState('');
	const [cvvIsLoading, setCvvIsLoading] = useState(false);
	const [isFlipped, setIsFlipped] = useState(false);

	const handleClickCard = () => {
		const analyticsEvent = isFlipped
			? FIREBASE_EVENT_ANALYTICS_BUTTON.FLIP_CARD_FRONT
			: FIREBASE_EVENT_ANALYTICS_BUTTON.FLIP_CARD_BACK;
		FirebaseAnalytics.logEvent(FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_MAIN, analyticsEvent);
		setIsFlipped(!isFlipped);
	};

	const handleClickSettings = (event: MouseEvent) => {
		event.stopPropagation();
		history.push(ROUTER_URL.RADABANK_CARD_SETTINGS);
	};

	const handleGetCVV = async (cardId: string): Promise<void> => {
		try {
			setCvvIsLoading(true);
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_MAIN,
				FIREBASE_EVENT_ANALYTICS_BUTTON.GET_CVV
			);
			const response = await RadabankService.getCvvCode({
				cardid: cardId,
				userId: (profile as IUserProfileResponse).userId,
			});
			setCvv(response.data.vcode);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setCvvIsLoading(false);
		}
	};

	return (
		<div
			{...props}
			className={classNames(styles.card, className, {
				[styles.card_flipped]: isFlipped,
			})}
			onClick={handleClickCard}
		>
			<div className={styles.card__inner}>
				<div className={styles.card__inner_front}>
					<RadabankCardPreview className={styles.card__preview}>
						<RadabankCardDetails isShowCreditDetails />
						<IconButton onClick={handleClickSettings} className={styles.settings}>
							<SettingsSVG />
						</IconButton>
					</RadabankCardPreview>
				</div>
				<div className={styles.card__inner_back}>
					<RadabankCardPreviewSecured cvv={cvv} onGetCvv={handleGetCVV} isLoading={cvvIsLoading} />
				</div>
			</div>
		</div>
	);
};

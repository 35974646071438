import { FC, useState } from 'react';
import { useHistory } from 'react-router';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import {
	getRadabankCurrentCardSelector,
	getUserProfileSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { Navbar } from 'components/Navbar';
import {
	RadabankCardDetails,
	RadabankCardPreview,
	RadabankFeePreview,
	RadabankOnboardingContent,
	RadabankSectionHeader,
} from 'components/RadabankCard';
import { ArrowBackButton, CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import { getErrorMessage, getMaskedPhone } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics, RadabankService } from 'utils/services';
import { IUserProfileResponse } from 'utils/types';
import styles from './index.module.scss';

export const RadabankPinCodeSmsPage: FC = () => {
	const handleClickBack = useGoBackRadabank();

	const history = useHistory();

	const dispatch = useAppDispatch();

	const currentCard = useAppSelector(getRadabankCurrentCardSelector);
	const profile = useAppSelector(getUserProfileSelector);

	const [isShowBanner, setIsShowBanner] = useState(false);

	const handleClickRemindPinCard = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.PIN_CODE_SMS,
				FIREBASE_EVENT_ANALYTICS_BUTTON.SEND
			);
			if (currentCard) {
				dispatch(setAppLoading(true));
				await RadabankService.remindPinCard({
					userId: (profile as IUserProfileResponse).userId,
					cardid: currentCard.id,
					currensy: currentCard.currnbu,
					cardidsend: currentCard.id,
					// has no methods to get data, default is 0
					isfop: '0',
					iscrs: '0',
				});
				setIsShowBanner(true);
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleClickRedirectMain = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.PIN_CODE_SMS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_HOME
		);
		history.push(ROUTER_URL.RADABANK_CARD);
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.PIN_CODE_SMS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	if (!currentCard) return <Navbar />;

	return isShowBanner ? (
		<div className="host-card-page-color-primary">
			<ArrowBackButton onClick={handleGoBack} />
			<RadabankOnboardingContent title="СМС відправлено!" />
			<CustomButton label="На головну" color="secondary" onClick={handleClickRedirectMain} />
		</div>
	) : (
		<div className={classNames('host-card-page-color-white-auth', styles.page)}>
			<RadabankSectionHeader title="СМС з PIN-кодом картки" onClickBack={handleGoBack} />
			<div className={styles.content}>
				<Typography variant="body2" color="var(--color-neutral-500)" marginBottom="var(--spacing-2)">
					Номер для отримання СМС
				</Typography>
				<Typography variant="h5" marginBottom="var(--spacing-6)">
					{getMaskedPhone((profile as IUserProfileResponse).phone)}
				</Typography>
				<Typography variant="caption" color="var(--color-neutral-600)" marginBottom="var(--spacing-1)">
					Джерело для сплати комісії
				</Typography>
				<RadabankCardPreview className={styles.card}>
					<RadabankCardDetails />
				</RadabankCardPreview>
			</div>
			<RadabankFeePreview amount={0} currency={currentCard.curriso} />
			<CustomButton onClick={handleClickRemindPinCard} label="Надіслати" />
		</div>
	);
};

import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ReactComponent as ArrowSvg } from 'assets/icons/arrow-left.svg';
import dayjs from 'dayjs';
import { getSingleReceipt } from 'store/bill/actions';
import { getBillReceiptSelector } from 'store/bill/selectors';
import { useAppDispatch } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { getUserProfileSelector } from 'store/user-service/selectors';
import { CalendarModal } from 'components/CalendarModal';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ERROR_CODE, ROUTER_URL } from 'utils/enums';
import { formatDateView, getErrorMessage, getErrorResponse } from 'utils/helpers';
import { useOpen } from 'utils/hooks';
import { FirebaseAnalytics } from 'utils/services';
import { ISingleReceipt } from 'utils/types';
import { MapActionButton } from './Button';
import { ReceiptMenu } from './ReceiptMenu';
import styles from './index.module.scss';

export const Actions: FC = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const { handleClose, handleOpen, isOpen } = useOpen();

	const receipt = useSelector(getBillReceiptSelector);
	const profile = useSelector(getUserProfileSelector);

	const handleChangePeriod = async (date: string): Promise<void> => {
		try {
			if (dayjs(date).format('MM') === receipt.data?.month) {
				handleClose();
				return;
			}

			const data = await dispatch(
				getSingleReceipt({
					apartmentAccountId: (receipt.data as ISingleReceipt).apartmentAccountId,
					month: dayjs(date).format('MM'),
					year: dayjs(date).year().toString(),
					userId: profile?.userId,
				})
			).unwrap();
			handleClose();
			if (!data || data?.month !== dayjs(date).format('MM')) {
				dispatch(showToast({ message: `Квитанцію за ${formatDateView(date, 'MM.YYYY')} не знайдено` }));
			}
		} catch (error) {
			const errorResponse = getErrorResponse(error);
			let message = getErrorMessage(error);

			if (errorResponse?.errorData?.code === ERROR_CODE.RECEIPT_NOT_FOUND) {
				message = `Єдину квитанцію за ${formatDateView(date)} не знайдено`;
			}
			dispatch(showToast({ message }));
		}
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SINGLE_RECEIPT,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		history.push(ROUTER_URL.PROFILE);
	};

	return (
		<div className={styles.wrapper}>
			<MapActionButton icon={<ArrowSvg />} onClick={handleGoBack} />
			{receipt.data && (
				<>
					<ReceiptMenu apartmentAccountId={receipt.data.apartmentAccountId} onClickChangePeriod={handleOpen} />
					<CalendarModal
						onSubmit={handleChangePeriod}
						isOpen={isOpen}
						onClose={handleClose}
						month={receipt.data.month}
						year={dayjs(receipt.data.createdAt).format('YYYY')}
					/>
				</>
			)}
		</div>
	);
};

import { FC, useEffect, useRef } from 'react';
import { RefresherEventDetail } from '@ionic/react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import {
	getAppLoadingSelector,
	getRadabankCurrentCardSelector,
	getRadabankInternalCardsList,
	getRadabankInternalOperationsList,
	getRadabankOperationsPaymentsIdListSelector,
	getRadabankOperationsSelector,
	getRadabankStatusPaymentById,
	getRadabanlInternalIsLoadingCardsListSelector,
	getUserProfileSelector,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { Navbar } from 'components/Navbar';
import { RadabankCardWithOperations, RadabankOperationsList } from 'components/RadabankCard';
import { Loader } from 'components/shared';
import { DATE_FORMAT, RADABANK_BOOLEAN_VALUE, RADABANK_CARD_BLOCK_LEVEL, RADABANK_PAYMENT_STATUS } from 'utils/enums';
import { getErrorMessage, getRadabankOperationsListDateStart } from 'utils/helpers';
import { IUserProfileResponse } from 'utils/types';
import styles from './index.module.scss';

export const RadabankMainPage: FC = () => {
	const dispatch = useAppDispatch();

	const intervalRef = useRef<NodeJS.Timeout | null>(null);

	const profile = useAppSelector(getUserProfileSelector);
	const cardData = useAppSelector(getRadabankCurrentCardSelector);
	const paymentsIdList = useAppSelector(getRadabankOperationsPaymentsIdListSelector);
	const isAppLoading = useAppSelector(getAppLoadingSelector);
	const isLoadingCardsList = useAppSelector(getRadabanlInternalIsLoadingCardsListSelector);
	const cardOperations = useAppSelector(getRadabankOperationsSelector);

	const isPaymentAllowed = cardData?.add_payment === RADABANK_BOOLEAN_VALUE.TRUE;
	const isCardFrozen = cardData?.blocklevel === RADABANK_CARD_BLOCK_LEVEL.FREEZED;

	useEffect(() => {
		if (paymentsIdList.length && profile && cardData && !intervalRef.current) {
			intervalRef.current = setInterval(() => {
				handleCheckPaymentsStatus(profile.userId, cardData.id, paymentsIdList);
			}, 1500);
		} else if (!paymentsIdList.length && intervalRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
	}, [paymentsIdList.length, profile, cardData]);

	useEffect(() => {
		if (cardData?.id) {
			if (!cardOperations.list.length) {
				handleGetOperationsList(cardData.id);
			} else {
				handleUpdateCurrentDateOperationsList(cardData.id);
			}
		}
	}, [cardData?.id]);

	const handleCheckPaymentsStatus = async (userId: string, cardId: string, paymentsIdList: string[]): Promise<void> => {
		try {
			const promises = paymentsIdList.map((item) =>
				dispatch(getRadabankStatusPaymentById({ userId, requestid: item })).unwrap()
			);
			const promisesResponseList = await Promise.allSettled(promises);
			const isShouldUpdateData = promisesResponseList.some(
				(item) =>
					item.status === 'fulfilled' &&
					(item.value.status === RADABANK_PAYMENT_STATUS.SUCCESS ||
						item.value.status === RADABANK_PAYMENT_STATUS.REJECTED)
			);
			if (isShouldUpdateData) {
				await dispatch(getRadabankInternalCardsList((profile as IUserProfileResponse).userId)).unwrap();
				await dispatch(
					getRadabankInternalOperationsList({
						userId: (profile as IUserProfileResponse).userId,
						datestart: dayjs().format(DATE_FORMAT.RADABANK_REQUEST),
						datefinish: dayjs().format(DATE_FORMAT.RADABANK_REQUEST),
						cardid: cardId,
					})
				).unwrap();
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleGetOperationsList = async (cardId: string): Promise<void> => {
		try {
			let dateEnd = dayjs().format(DATE_FORMAT.RADABANK_REQUEST);
			if (cardOperations.list.length) {
				dateEnd = dayjs(cardOperations.list[cardOperations.list.length - 1][0]?.dateonline, DATE_FORMAT.RADABANK_REQUEST)
					.add(-1, 'day')
					.format(DATE_FORMAT.RADABANK_REQUEST);
			}
			await dispatch(
				getRadabankInternalOperationsList({
					cardid: cardId,
					userId: (profile as IUserProfileResponse).userId,
					datestart: getRadabankOperationsListDateStart(dateEnd),
					datefinish: dateEnd,
				})
			).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleUpdateCurrentDateOperationsList = async (cardId: string): Promise<void> => {
		try {
			const currentDate = dayjs().format(DATE_FORMAT.RADABANK_REQUEST);
			await dispatch(
				getRadabankInternalOperationsList({
					cardid: cardId,
					userId: (profile as IUserProfileResponse).userId,
					datestart: currentDate,
					datefinish: currentDate,
				})
			).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleRefreshThePage = async (e: CustomEvent<RefresherEventDetail>) => {
		try {
			if (profile?.userId) {
				await dispatch(getRadabankInternalCardsList(profile.userId)).unwrap();
				await handleUpdateCurrentDateOperationsList(cardData?.id ?? '');
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			e.detail.complete();
		}
	};

	return (
		<>
			{!!cardData && (
				<div className={classNames('page', styles.wrapper)}>
					<RadabankCardWithOperations
						onRefresh={handleRefreshThePage}
						isFrozen={isCardFrozen}
						isPaymentAllowed={isPaymentAllowed}
					/>

					<RadabankOperationsList
						isLoading={cardOperations.isLoading}
						cardOperations={cardOperations.list}
						onObserve={handleGetOperationsList}
					/>
				</div>
			)}
			<Navbar />
			{!isAppLoading && isLoadingCardsList && !cardData && <Loader />}
		</>
	);
};

import { FC, useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { Typography } from '@mui/material';
import { getRadabankCardExistsSelector } from 'store';
import { getCardsList } from 'store/cards/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openModal } from 'store/system/reducer';
import { showToast } from 'store/toastify/reducer';
import { getUserAvatar, getUserProfile } from 'store/user-service/actions';
import {
	getIsPumbUserAccessSelector,
	getIsUserHasFullAccessSelector,
	getUserProfileSelector
} from 'store/user-service/selectors';
import { Navbar } from 'components/Navbar';
import {
	AccountDeleteModalContent,
	AppSettings,
	Card,
	CardItem,
	HostCardBanner,
	LogoutButton,
	UserCards,
	UserProfile,
} from 'components/Settings';
import { CustomButton, ProviderDefaultInfoModal } from 'components/shared';
import { UserInfo } from 'components/UserInfo';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { getAppVersion, getErrorMessage } from 'utils/helpers';
import { FirebaseAnalytics } from 'utils/services';
import { IUserProfileResponse } from 'utils/types';
import styles from './index.module.scss';

export const SettingsPage: FC = () => {
	const dispatch = useAppDispatch();

	const profile: IUserProfileResponse | null = useAppSelector(getUserProfileSelector);
	const isHasFullAccess = useAppSelector(getIsUserHasFullAccessSelector);
	const isUserNotPUMB = useAppSelector(getIsPumbUserAccessSelector)
	const isHideRadabankBanner = useAppSelector(getRadabankCardExistsSelector);

	const [appVersion, setAppVersion] = useState('');

	useEffect(() => {
		if (!profile) {
			handleGetUserProfile();
		}

		getAppVersion().then((appVersion) => setAppVersion(appVersion));
	}, []);

	useEffect(() => {
		if (profile?.userId) {
			getUserCards(profile?.userId);
			dispatch(getUserAvatar(profile.userId));
		}
	}, [profile?.userId]);

	const handleGetUserProfile = async () => {
		try {
			await dispatch(getUserProfile()).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const getUserCards = async (userId: string): Promise<void> => {
		try {
			await dispatch(getCardsList(userId)).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleOpenSupportModal = (): void => {
		FirebaseAnalytics.logEvent(FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SETTINGS, FIREBASE_EVENT_ANALYTICS_BUTTON.SUPPORT);
		dispatch(
			openModal({
				content: <ProviderDefaultInfoModal data={null} />,
			})
		);
	};

	const handleClickPrivacy = (): void => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SETTINGS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.PRIVACY_POLICY
		);
	};

	const handleClickAccountDelete = (): void => {
		dispatch(
			openModal({
				content: <AccountDeleteModalContent pageName={FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SETTINGS} />,
			})
		);
	};

	return (
		<>
			<div className="page page-scrollable">
				<div className={styles.content}>
					<Card>
						<UserInfo editable />
					</Card>
					{isHasFullAccess && (
						<Card title="Збережені картки">
							<UserCards />
						</Card>
					)}
					{isHasFullAccess && !isHideRadabankBanner && isUserNotPUMB && <HostCardBanner />}
					<Card title="Особисті дані">
						<UserProfile />
					</Card>
					{Capacitor.isNativePlatform() && (
						<Card title="Налаштування додатку">
							<AppSettings />
						</Card>
					)}
					<Card title="Посилання">
						<CardItem label="Служба підтримки" onClick={handleOpenSupportModal} />
						<a href={process.env.REACT_APP_AGREEMENT_URL} target="_blank" rel="noreferrer">
							<CardItem label="Користувацька угода" onClick={handleClickPrivacy} />
						</a>
						{Capacitor.getPlatform() === 'ios' && (
							<CustomButton label="Видалити акаунт" fill="clear" color="danger" onClick={handleClickAccountDelete} />
						)}
					</Card>
					{!!appVersion && (
						<Card>
							<Typography variant="body2" color="--color-neutral-500" textAlign="center">
								{appVersion}
							</Typography>
						</Card>
					)}
					<LogoutButton />
				</div>
			</div>
			<Navbar />
		</>
	);
};

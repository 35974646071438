import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
	getRadabanlInternalClientSelector,
	getUserProfileSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import {
	RadabankInternalActivateConfirmation,
	RadabankInternalActivateSuccessBanner,
} from 'components/RadabankCard/InternalActivate';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import { decryptData, getErrorMessage } from 'utils/helpers';
import { FirebaseAnalytics, RadabankService, StorageService } from 'utils/services';

/**
 *
 * The page for activation users which already registerd in RB24
 */
export const RadabankInternalActivatePage: FC = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const profile = useAppSelector(getUserProfileSelector);
	const radabankInternalClient = useAppSelector(getRadabanlInternalClientSelector);

	const [isShowSuccessBanner, setIsShowSuccessBanner] = useState(false);

	useEffect(() => {
		handleStartActivation();
	}, []);

	const handleStartActivation = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.ACTIVATE_RB_USER,
				FIREBASE_EVENT_ANALYTICS_BUTTON.RESEND_OTP
			);
			dispatch(setAppLoading(true));

			const password = await StorageService.get<string>(STORAGE_KEY.USER_PASSWORD);
			const decryptedPasswordData = decryptData(password ?? '');

			if (!decryptedPasswordData || !password || !profile || !radabankInternalClient) {
				return history.replace(ROUTER_URL.ERROR);
			}

			const { userId, phone } = profile;

			await RadabankService.startActivateInternal({ userId, phone: phone.slice(2) });
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleSubmit = async (otp: string): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.ACTIVATE_RB_USER,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CONTINUE
			);
			dispatch(setAppLoading(true));

			const password = await StorageService.get<string>(STORAGE_KEY.USER_PASSWORD);
			const decryptedPasswordData = decryptData(password ?? '');

			if (!profile || !password || !decryptedPasswordData) {
				return history.replace(ROUTER_URL.ERROR);
			}

			const {
				data: { username = '' },
			} = await RadabankService.internalVerifyActivateCode({
				userId: profile.userId,
				phone: profile.phone.slice(2),
				smsdata: otp,
			});

			await RadabankService.activateInternal({
				userId: profile.userId,
				passnew: decryptedPasswordData,
				passrenew: decryptedPasswordData,
				username,
				smsdata: otp,
			});

			setIsShowSuccessBanner(true);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	return isShowSuccessBanner ? (
		<RadabankInternalActivateSuccessBanner />
	) : (
		<RadabankInternalActivateConfirmation onResendCode={handleStartActivation} onSubmit={handleSubmit} />
	);
};

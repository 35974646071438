import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import {
	getRadabankCurrentCardSelector,
	getUserProfileSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { Navbar } from 'components/Navbar';
import {
	RadabankCardDetails,
	RadabankCardPreview,
	RadabankFeePreview,
	RadabankOnboardingContent,
	RadabankPinCode,
	RadabankSectionHeader,
} from 'components/RadabankCard';
import { ArrowBackButton, CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE, RADABANK_PIN_CODE_LENGTH } from 'utils/constants';
import { ERROR_MESSAGE, ROUTER_URL } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics, RadabankService } from 'utils/services';
import { IUserProfileResponse } from 'utils/types';
import styles from './index.module.scss';

export const RadabankPinCodeChangePage: FC = () => {
	const onClickBack = useGoBackRadabank();

	const history = useHistory();

	const dispatch = useAppDispatch();

	const currentCard = useAppSelector(getRadabankCurrentCardSelector);
	const profile = useAppSelector(getUserProfileSelector);

	const [pinCode, setPinCode] = useState('');
	const [repeatedPinCode, setRepeatedPinCode] = useState('');
	const [isRepeatedPin, setIsRepeatedPin] = useState(false);
	const [error, setError] = useState('');
	const [isShowDetails, setIsShowDetails] = useState(false);
	const [isShowBanner, setIsShowBanner] = useState(false);

	useEffect(() => {
		if (pinCode.length === RADABANK_PIN_CODE_LENGTH && repeatedPinCode.length === RADABANK_PIN_CODE_LENGTH) {
			if (pinCode === repeatedPinCode) {
				setIsShowDetails(true);
			} else {
				setError(ERROR_MESSAGE.PASSWORDS_DO_NOT_MATCH);
				setRepeatedPinCode('');
			}
		}

		if (repeatedPinCode.length !== RADABANK_PIN_CODE_LENGTH) {
			setError('');
		}
	}, [pinCode, repeatedPinCode]);

	useEffect(() => {
		if (pinCode.length === RADABANK_PIN_CODE_LENGTH) {
			setIsRepeatedPin(true);
		}
	}, [pinCode]);

	const handleChangePinCode = (event: ChangeEvent<HTMLInputElement>) => {
		setPinCode(event.target.value);
	};

	const handleChangeRepeatedPinCode = (event: ChangeEvent<HTMLInputElement>) => {
		setRepeatedPinCode(event.target.value);
	};

	const handleSubmitChangePinCard = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.PIN_CODE_CHANGE_SMS,
				FIREBASE_EVENT_ANALYTICS_BUTTON.NEXT
			);
			if (currentCard) {
				dispatch(setAppLoading(true));
				await RadabankService.changePinCard({
					userId: (profile as IUserProfileResponse).userId,
					cardid: currentCard.id,
					currensy: currentCard.currnbu,
					cardidsend: currentCard.id,
					vcode: pinCode,
					// has no methods to get data, default is 0
					isfop: '0',
					iscrs: '0',
				});
				setIsShowBanner(true);
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleClickRedirectMain = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.PIN_CODE_CHANGE_SMS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_HOME
		);
		history.push(ROUTER_URL.RADABANK_CARD);
	};

	const handleClickBack = () => {
		if (isRepeatedPin || isShowDetails) {
			setPinCode('');
			setRepeatedPinCode('');
			setIsRepeatedPin(false);
			setIsShowDetails(false);
		} else {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.PIN_CODE_CHANGE_SMS,
				FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
			);
			onClickBack();
		}
	};

	if (!currentCard) {
		return <Navbar />;
	}
	if (isShowBanner) {
		return (
			<div className="host-card-page-color-primary">
				<ArrowBackButton onClick={onClickBack} />
				<RadabankOnboardingContent
					title="Новий PIN-код створено!"
					description={[
						'Зверніть увагу.',
						'Для застосування PIN-коду вам необхідно провести будь-яку операцію в банкоматі',
					]}
				/>
				<CustomButton label="На головну" color="secondary" onClick={handleClickRedirectMain} />
			</div>
		);
	}
	return (
		<div className={classNames('host-card-page-color-white', styles.page)}>
			<RadabankSectionHeader title="Зміна PIN-коду картки" onClickBack={handleClickBack} />
			{isShowDetails ? (
				<>
					<div className={styles.content}>
						<Typography variant="caption" color="var(--color-neutral-600)" marginBottom="var(--spacing-1)">
							Джерело для сплати комісії
						</Typography>
						<RadabankCardPreview className={styles.card}>
							<RadabankCardDetails />
						</RadabankCardPreview>
					</div>
					<RadabankFeePreview amount={0} currency={currentCard.curriso} />
					<CustomButton onClick={handleSubmitChangePinCard} label="Змінити PIN" />
				</>
			) : (
				<RadabankPinCode
					value={isRepeatedPin ? repeatedPinCode : pinCode}
					onChange={isRepeatedPin ? handleChangeRepeatedPinCode : handleChangePinCode}
					maxLength={RADABANK_PIN_CODE_LENGTH}
					error={error}
					description={isRepeatedPin ? 'Підтвердіть новий PIN-код' : 'Придумайте новий PIN-код'}
				/>
			)}
		</div>
	);
};

/* eslint-disable no-param-reassign */
import { PayloadAction } from '@reduxjs/toolkit';
import { IRadabankReducer } from 'utils/types';
import { radabankInitialState } from './reducer';

export const resetRadabankReducerOperation = () => {
	return { ...radabankInitialState };
};

export const updateInfoStatusIdRadabankReducerOperation = (
	state: IRadabankReducer,
	{ payload }: PayloadAction<string>
) => {
	if (state.radabankInternalClient.data) {
		state.radabankInternalClient.data.infostatusid = payload;
	}
};

export const resetRadabankReducerOperationsOperation = (state: IRadabankReducer) => {
	if (state.radabankInternalClient) {
		state.currentCard.operations = radabankInitialState.currentCard.operations;
	}
};

import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
	CARD_DATA_NAV_LIST,
	CARD_OPERATIONS_NAV_LIST,
	CARD_SETTINGS_NAV_LIST_PRIVATE,
	CARD_SETTINGS_NAV_LIST_PUBLIC,
} from 'pages/Settings/helpers';
import { getIsCurrentCardBlockedSelector, getRadabankCurrentCardSelector, useAppSelector } from 'store';
import {
	RadabankOperationDetailInfo,
	RadabankSectionHeader,
	RadabankSettingsBlock,
	RadabankSettingsItem,
} from 'components/RadabankCard';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { RADABANK_BOOLEAN_VALUE, RADABANK_CARD_BLOCK_LEVEL, ROUTER_URL } from 'utils/enums';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics } from 'utils/services';
import { INavbarItem } from 'utils/types';
import styles from './index.module.scss';

export const RadabankSettingsPage: FC = () => {
	const cardData = useAppSelector(getRadabankCurrentCardSelector);
	const isCardBlocked = useAppSelector(getIsCurrentCardBlockedSelector);

	const handleClickBack = useGoBackRadabank();

	const [cardOperationsNavList, setCardOperationsNavList] = useState<INavbarItem[]>([]);
	const [cardDataNavList, setCardDataNavList] = useState<INavbarItem[]>([]);

	useEffect(() => {
		if (cardData) {
			const cardOperationsNavListPayload = CARD_OPERATIONS_NAV_LIST.filter((item) => {
				switch (item.path) {
					case ROUTER_URL.RADABANK_CARD_ORDER:
						return cardData.isreqnewcard === RADABANK_BOOLEAN_VALUE.TRUE;

					case ROUTER_URL.RADABANK_CARD_REISSUE:
						return cardData.add_perevip === RADABANK_BOOLEAN_VALUE.TRUE;

					case ROUTER_URL.RADABANK_CARD_BLOCK_CARD:
						return cardData.add_block === RADABANK_BOOLEAN_VALUE.TRUE;

					case ROUTER_URL.RADABANK_CARD_UNBLOCK_CARD:
						return cardData.add_unblock === RADABANK_BOOLEAN_VALUE.TRUE;

					case ROUTER_URL.RADABANK_CARD_SECURE_SETTINGS:
						return cardData.add_security === RADABANK_BOOLEAN_VALUE.TRUE;

					case ROUTER_URL.RADABANK_CARD_SMS_INFO:
						return cardData.add_smsinfo === RADABANK_BOOLEAN_VALUE.TRUE;

					case ROUTER_URL.RADABANK_CARD_CREDIT_LIMIT:
						return (
							cardData.isupdatelimit === RADABANK_BOOLEAN_VALUE.TRUE ||
							cardData.iscreatelimit === RADABANK_BOOLEAN_VALUE.TRUE
						);

					default:
						return true;
				}
			}).map((item) => {
				if (
					item.path === ROUTER_URL.RADABANK_CARD_CREDIT_LIMIT &&
					cardData.isrequestactive !== RADABANK_BOOLEAN_VALUE.FALSE &&
					cardData.blocklevel !== RADABANK_CARD_BLOCK_LEVEL.FREEZED
				) {
					return { ...item, path: ROUTER_URL.RADABANK_CARD_CREDIT_LIMIT_IN_PROGRESS };
				}
				return item;
			});
			const cardDataNavListPayload = CARD_DATA_NAV_LIST.filter((item) => {
				switch (item.path) {
					case ROUTER_URL.RADABANK_CARD_DETAILS_AND_DOCUMENTS:
						return (
							cardData.add_rekv === RADABANK_BOOLEAN_VALUE.TRUE ||
							cardData.add_printdogover === RADABANK_BOOLEAN_VALUE.TRUE ||
							cardData.add_printdogopen === RADABANK_BOOLEAN_VALUE.TRUE
						);

					default:
						return true;
				}
			});
			setCardOperationsNavList(cardOperationsNavListPayload);
			setCardDataNavList(cardDataNavListPayload);
		}
	}, [cardData]);

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.SETTINGS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	const cardSettingsNavList = isCardBlocked
		? CARD_SETTINGS_NAV_LIST_PUBLIC
		: [...CARD_SETTINGS_NAV_LIST_PRIVATE, ...CARD_SETTINGS_NAV_LIST_PUBLIC];

	return (
		<div className={classNames('page', 'page-scrollable', styles.wrapper)}>
			<RadabankSectionHeader title="Керування картою" onClickBack={handleGoBack} />
			{isCardBlocked && (
				<RadabankOperationDetailInfo
					className={styles.note}
					description="Керування багатьма функціями можливе лише для активної картки"
				/>
			)}

			{!!cardData && !!cardOperationsNavList.length && (
				<div className={styles.content}>
					<RadabankSettingsBlock title="Операції з карткою">
						{cardOperationsNavList.map((item) => (
							<RadabankSettingsItem key={item.path} data={item} />
						))}
					</RadabankSettingsBlock>
					<RadabankSettingsBlock title="Дані картки">
						{cardDataNavList.map((item) => (
							<RadabankSettingsItem key={item.path} data={item} />
						))}
					</RadabankSettingsBlock>
					<RadabankSettingsBlock title="Налаштування картки">
						{cardSettingsNavList.map((item) => (
							<RadabankSettingsItem key={item.path} data={item} />
						))}
					</RadabankSettingsBlock>
				</div>
			)}
		</div>
	);
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddPortmoneServiceModal_wrapper__UZzzO::part(content) {
  max-width: 684px;
  width: 80vw !important;
}
@media (max-width: 1024px) {
  .AddPortmoneServiceModal_wrapper__UZzzO::part(content) {
    width: 100vw !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Receipt/AddPortmoneServiceModal/index.module.scss","webpack://./src/assets/styles/media.scss"],"names":[],"mappings":"AAGC;EACC,gBAAA;EACA,sBAAA;AAFF;ACUC;EDJC;IACC,uBAAA;EAHD;AACF","sourcesContent":["@import 'assets/styles/media';\n\n.wrapper {\n\t&::part(content) {\n\t\tmax-width: 684px;\n\t\twidth: 80vw !important;\n\t}\n\n\t@include mobile {\n\t\t&::part(content) {\n\t\t\twidth: 100vw !important;\n\t\t}\n\t}\n}\n","@mixin tablet {\n\t@media (max-width: 1024px) {\n\t\t@content;\n\t}\n}\n\n@mixin desktop {\n\t@media (min-width: 1025px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile {\n\t@media (max-width: 1024px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile-md {\n\t@media (max-width:374px) {\n\t\t@content\n\t}\n}\n\n@mixin mobile-small {\n\t@media (max-width: 319px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile-small-height {\n\t@media (max-height: 668px) {\n\t\t@content;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `AddPortmoneServiceModal_wrapper__UZzzO`
};
export default ___CSS_LOADER_EXPORT___;

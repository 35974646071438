import HostIcon from 'assets/icons/host-icon.svg';
import WaterService from 'assets/icons/services/drop.svg';
import NaftogasService from 'assets/icons/services/fire.svg';
import HouseService from 'assets/icons/services/house.svg';
import EnergoService from 'assets/icons/services/lighthub.svg';
import TermService from 'assets/icons/services/temp.svg';
import TrashService from 'assets/icons/services/trash.svg';
import HostLogo from 'assets/images/host-logo.svg';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { jwtDecode } from 'jwt-decode';
import { ENV_VARIABLE, PORTMONE_SERVICE_CODE, PROVIDER_SORT_ORDER } from 'utils/constants';
import {
	BILL_TYPE,
	CURRENCY_LABEL,
	CUSTOM_PORTMONE_PROVIDER_TITLE,
	PROVIDER_TYPE,
	ROUTER_URL,
	SERVICE_CODE_KTS,
	SERVICE_CODE_KVD,
	STORAGE_KEY,
} from 'utils/enums';
import { SessionsService, StorageService } from 'utils/services';
import {
	IBillRequestData,
	IBillsReport,
	IBillsReportServiceData,
	IBillsSubBillItem,
	IData3DS2,
	IProviderServiceResponse,
	IReceiptOtherServiceResponse,
	IReceiptPayRequest,
	IReceiptRequest,
	IReceiptResponse,
	IReportPaymentReceiptExtraDataProviderItem,
	IServiceBillRequestTariffItems,
	ISingleArchiveExtraData,
	ISingleArchiveExtraProviderData,
	ISingleArchiveReceipt,
	ISingleArchiveReceiptResponse,
	ISingleReceiptPayProviderServiceRequest,
	ReceiptOtherServiceTariffItems,
} from 'utils/types';
import { encryptData } from './crypt';
import { convertCoinsToUAH, convertUAHToCoins } from './currency';
import { parseQueryString } from './query-string';
import { getReceiptRoute, redirectNotAuthorizedUser } from './router';
import {
	checkIsAmountServiceField,
	checkIsAmountServiceFieldName,
	findServiceProviderAccountIdValue,
	getContractNumberFieldName,
	getServiceBillRequestKeyCombination,
} from './services';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getProvideIconByType = (
	providerType: PROVIDER_TYPE,
	data: Omit<Partial<IProviderServiceResponse>, 'id'> | null = null
): string => {
	if (data?.code === PORTMONE_SERVICE_CODE.NAFTOGAS) return NaftogasService;
	if (data?.code === PORTMONE_SERVICE_CODE.ENERGOZBUT) return EnergoService;

	switch (providerType) {
		case PROVIDER_TYPE.KVBO:
			return TrashService;

		case PROVIDER_TYPE.KVD:
			return WaterService;

		case PROVIDER_TYPE.HCS:
			return HouseService;

		case PROVIDER_TYPE.KTS:
			return TermService;

		case PROVIDER_TYPE.PORTMONE:
			return data?.categoryImage ?? data?.imageUrl ?? HostIcon;

		default:
			return HostIcon;
	}
};

export const getProviderTitle = (
	data:
		| IBillsReportServiceData
		| IBillsSubBillItem
		| IProviderServiceResponse
		| IReceiptOtherServiceResponse
		| IReportPaymentReceiptExtraDataProviderItem
		| null
		| undefined = null
): string => {
	if (
		data?.code === ENV_VARIABLE.PORTMONE_PROVIDER_NAFTOGAZ_CODE &&
		((data as IBillsSubBillItem)?.provider === PROVIDER_TYPE.PORTMONE ||
			(data as IReportPaymentReceiptExtraDataProviderItem)?.providerType === PROVIDER_TYPE.PORTMONE)
	) {
		return CUSTOM_PORTMONE_PROVIDER_TITLE.NAFTOGAZ_UKRAINE;
	}
	return (
		(data as IProviderServiceResponse)?.title ??
		(data as IBillsSubBillItem)?.providerTitle ??
		(data as IBillsSubBillItem)?.payerData?.serviceName ??
		(data as IProviderServiceResponse)?.providerService ??
		''
	);
};

export const getProviderServiceTitle = (
	data: IBillsSubBillItem | IProviderServiceResponse | IReceiptOtherServiceResponse | null | undefined = null
): string =>
	(data as IBillsSubBillItem)?.serviceProviderData?.title ??
	(data as IProviderServiceResponse)?.serviceTitle ??
	(data as IProviderServiceResponse)?.title ??
	(data as IProviderServiceResponse)?.providerService ??
	'';

export const getProviderInfoDataByType = (data: IProviderServiceResponse | IBillsSubBillItem | null) => {
	let payload = {
		title: getProviderTitle(data),
		logo: '',
		contacts: { list: [] as string[], description: '' },
		schedule: '',
		link: '',
	};

	if (!data) {
		return {
			title: 'Служба підтримки HOST',
			logo: HostLogo,
			contacts: { list: ['0-800-33-2077', '095-77-211-77', '098-77-211-77'], description: '' },
			schedule: 'В робочі дні з 10:00 до 17:00, без перерви',
			link: 'https://hostpay.com.ua/',
		};
	}

	const providerType = (data as IBillsSubBillItem)?.provider ?? (data as IProviderServiceResponse).providerType;

	switch (providerType) {
		case PROVIDER_TYPE.KVBO:
			payload = {
				...payload,
				logo: 'https://cdn-images.portmone.com.ua/company/128_128/121550.png',
				contacts: { ...payload.contacts, list: ['725-21-58', '725-21-59', '0 800 403 577'] },
				schedule: "В робочі дні з 8:00 до 17:00, у п'ятницю до 16:00",
				link: 'https://kpkvpv.kharkiv.ua/',
			};
			break;

		case PROVIDER_TYPE.KVD:
			payload = {
				...payload,
				logo: 'https://cdn-images.portmone.com.ua/company/128_128/10611.png',
				contacts: { list: ['707-57-57'], description: 'цілодобово' },
				schedule: "В робочі дні з 8:00 до 17:00, у п'ятницю до 15:45",
				link: 'https://vodokanal.kharkov.ua/',
			};
			break;

		case PROVIDER_TYPE.HCS:
			payload = {
				...payload,
				logo: 'https://cdn-images.portmone.com.ua/company/128_128/10614.png ',
				contacts: { ...payload.contacts, list: ['760-70-62'] },
				schedule: 'Цілодобово',
				link: 'https://zhks.kharkov.ua/',
			};
			break;

		case PROVIDER_TYPE.KTS:
			payload = {
				...payload,
				logo: 'https://cdn-images.portmone.com.ua/company/128_128/10613.png ',
				contacts: { ...payload.contacts, list: ['341-41-40'] },
				schedule: 'Цілодобово',
				link: 'https://hts.kharkov.ua/',
			};
			break;

		default:
			break;
	}

	return payload;
};

export const getPhoneNumberFromString = (phoneString: string): string => {
	const phoneNumber = phoneString.replace(/\D/g, '');
	return phoneNumber.startsWith('0') ? phoneNumber : `057${phoneNumber}`;
};

export const getLocaleAmountString = (amount: string | number, separator = ' ', fractionDigits = 2): string =>
	(+amount)
		.toLocaleString('uk-UA', { minimumFractionDigits: fractionDigits })
		.replace(',', '.')
		.replace(' ', separator);

export const getAmountToPayString = (sum: number): string => {
	let value = '';
	if (sum === 0) {
		value = '0';
	} else if (sum < 0) {
		value = Math.abs(sum).toString();
	} else {
		value = `-${sum}`;
	}

	return value;
};

export const getAmountTitle = (amount: string | number, currency = '', fractionDigits = 2): string =>
	`${getLocaleAmountString(amount || 0, undefined, fractionDigits)} ${currency || CURRENCY_LABEL.UAH}`;

export const getProviderPreviewData = (serviceList: IProviderServiceResponse[]): string => {
	const totalToPay = serviceList.reduce((sum, item) => (item.amountToPay > 0 ? sum + item.amountToPay : sum), 0);
	return `o/p ${serviceList[0].providerAccountId}, ${getAmountTitle(getAmountToPayString(totalToPay))}`;
};

export const getProviderServicePayment = (data: IProviderServiceResponse): ISingleReceiptPayProviderServiceRequest => {
	const payload: ISingleReceiptPayProviderServiceRequest = {
		id: data.id,
		incomeBalance: data.amountToPay >= 0 ? `${data.amountToPay}` : '0',
		providerType: data.providerType,
		providerService: data.providerService,
		code: data.code,
	};
	if (data.code) {
		payload.code = data.code;
	}
	if (data.providerAccountId) {
		payload.providerAccountId = data.providerAccountId;
	}
	if (data.dataObject && data.serviceFields?.length && data.tarifItems) {
		const serviceFieldsDataKeyCombination = getServiceBillRequestKeyCombination(data.dataObject);
		const serviceFieldsData = serviceFieldsDataKeyCombination.reduce((acc, keyCombination) => {
			// eslint-disable-next-line dot-notation
			const value = (
				(data.tarifItems as IBillRequestData)?.[keyCombination.reqKeyName] as IServiceBillRequestTariffItems
			)?._;
			return value ? { ...acc, [keyCombination.fieldValueName]: value } : acc;
		}, {});
		payload.serviceFieldsData = serviceFieldsData;
	}
	return payload;
};

export const getOtherProviderServicePayment = (
	data: IReceiptOtherServiceResponse,
	amountToPay: string
): ISingleReceiptPayProviderServiceRequest => {
	const valueToPay = +amountToPay >= 0 ? amountToPay : '0';
	const payload: ISingleReceiptPayProviderServiceRequest = {
		serviceId: data.id,
		incomeBalance: valueToPay,
		providerType: data.provider,
		providerService: data.name,
		code: data?.code,
		serviceFieldsData: data.serviceFieldsValues.reduce(
			(acc, item) => ({ ...acc, [item.name]: checkIsAmountServiceField(item) ? valueToPay : item.value }),
			{}
		),
	};
	if (Array.isArray(data.tarifItems)) {
		payload.subServices = data.tarifItems
			.filter((item) => (item as IBillRequestData)?.amount && +(item as IBillRequestData).amount)
			.map((item: ReceiptOtherServiceTariffItems) => {
				const tarifItem = item as IBillRequestData;
				const payload = {
					payeeId: tarifItem.payee.$.id,
					amount: convertUAHToCoins(tarifItem.amount),
					serviceName: tarifItem.payee._,
					providerService: tarifItem.payee._,
					isShowServiceName: true,
				};
				const providerAccountIdFieldName = getContractNumberFieldName(data);
				return { ...payload, [providerAccountIdFieldName]: tarifItem.contractNumber._ };
			});
	}
	return payload;
};

export const getPortmoneServicePayRequestBody = (
	data: IReceiptOtherServiceResponse | IProviderServiceResponse
): ISingleReceiptPayProviderServiceRequest => {
	const valueToPay = '0';
	return {
		serviceId: data.id as string,
		incomeBalance: valueToPay,
		providerType: (data as IReceiptOtherServiceResponse)?.provider ?? (data as IProviderServiceResponse)?.providerType,
		providerService:
			(data as IReceiptOtherServiceResponse)?.name ?? (data as IProviderServiceResponse)?.providerService,
		code: data?.code,
		// serviceFieldsData: data.serviceFieldsValues.reduce(
		// 	(acc, item) => ({ ...acc, [item.name]: checkIsAmountServiceField(item) ? valueToPay : item.value }),
		// 	{}
		// ),
	};
};

export const serializeReceiptPayQuery = (data: IReceiptPayRequest): IReceiptPayRequest => {
	const { id, serviceProviderData, ...rest } = data;

	const result: Partial<IReceiptPayRequest> = { id: +id, ...rest };

	if (serviceProviderData) {
		// convert string array of objects to array
		const serviceProviderDataStringWithoutBrackets: string = (serviceProviderData as any).slice(1, -1);
		const serviceProviderWrappedString = `[${serviceProviderDataStringWithoutBrackets}]`;
		const serviceProviderJsonString = serviceProviderWrappedString.replace(/(['"])?([a-zA-Z0-9_]+)(['"])?:/g, '"$2": ');
		const serviceProviderJsonArray: ISingleReceiptPayProviderServiceRequest[] = JSON.parse(serviceProviderJsonString);
		result.serviceProviderData = serviceProviderJsonArray;
	}

	return result as IReceiptPayRequest;
};

// TODO: possible should drop convertation
export const serializeArchiveReceiptResponse = (data: ISingleArchiveReceiptResponse): ISingleArchiveReceipt => {
	const { extraData, ...rest } = data.data;
	const extraDataObj: ISingleArchiveExtraData = JSON.parse(extraData);
	return {
		...rest,
		extraData: {
			...extraDataObj,
			providerData: extraDataObj.providerData.map((item: ISingleArchiveExtraProviderData) => ({
				...item,
				amountToPay: convertCoinsToUAH(item.amountToPay),
				incomeBalance: convertCoinsToUAH(item.incomeBalance),
				currentPayed: `${item?.currentPayed ? convertCoinsToUAH(item.currentPayed) : 0}`,
				payed: convertCoinsToUAH(item.payed),
			})),
		},
	};
};

export const getData3DS = (): IData3DS2 => ({
	browserColorDepth: window.screen.colorDepth.toString(),
	browserLanguage: navigator.language,
	browserScreenHeight: window.screen.height.toString(),
	browserScreenWidth: window.screen.width.toString(),
	browserTZ: new Date().getTimezoneOffset().toString(),
	browserTZName: dayjs.tz.guess(),
	browserJavaEnabled: window.navigator.javaEnabled().toString(),
	windowWidth: window.innerWidth.toString(),
	windowHeight: window.innerHeight.toString(),
});

export const sortProvidersObjByType = (data: {
	[key: string]: IProviderServiceResponse[];
}): IProviderServiceResponse[][] =>
	PROVIDER_SORT_ORDER.reduce(
		(acc, item) => (data[item] ? [...acc, data[item]] : acc),
		[] as IProviderServiceResponse[][]
	);

export const sortReportProvidersByType = <
	T extends Pick<IBillsReport, 'provider'> | Pick<IProviderServiceResponse, 'providerType'>
>(
	data: T[]
): T[][] =>
	PROVIDER_SORT_ORDER.reduce((acc, item) => {
		const serviceListByProviderType = data.filter(
			(service) =>
				(service as IBillsReport)?.provider === item || (service as IProviderServiceResponse)?.providerType === item
		);
		return serviceListByProviderType.length ? [...acc, serviceListByProviderType] : acc;
	}, [] as T[][]);

export const getTotalFeeTitle = (amount: string | number): string =>
	+amount ? `Комісія: ${getAmountTitle(amount)}` : '0% комісія';

export const getStaticTotalFeeTitle = (amount: string | number): string =>
	`Комісія ${+amount ? 2 : 0}%, min 2 ${CURRENCY_LABEL.UAH}`;

export const getPortmoneDefaultServices = (data: {
	[key: string]: IProviderServiceResponse[];
}): (IProviderServiceResponse & { serviceList: IProviderServiceResponse[] })[] => {
	return data[PROVIDER_TYPE.PORTMONE].map((item) => ({ ...item, serviceList: [item] }));
};

export const getReceiptTitle = (data: Pick<IReceiptResponse, 'month' | 'createdAt'>) =>
	`Єдина квитанція${
		dayjs()
			.month(dayjs().month() - 1)
			.format('MM') !== data.month
			? ` за ${data.month}.${dayjs(data.createdAt).format('YYYY')}`
			: ''
	}`;

export const getProviderServiceBalanceByType = (
	data: IProviderServiceResponse | IReceiptOtherServiceResponse
): number => {
	return ((data as IReceiptOtherServiceResponse)?.tarifItems as IBillRequestData)?.amount &&
		+((data as IReceiptOtherServiceResponse).tarifItems as IBillRequestData).amount
		? // eslint-disable-next-line no-unsafe-optional-chaining
		  +((data as IReceiptOtherServiceResponse)?.tarifItems as IBillRequestData)?.amount
		: (data as IProviderServiceResponse)?.amountToPay ||
				(data as IReceiptOtherServiceResponse).amount - (data.payed ?? 0);
};

export const getReceiptPayRedirectUrl = async (queryString: string): Promise<string> => {
	let url: string = ROUTER_URL.ERROR;
	const queryParams = parseQueryString<{ token: string }>(queryString);

	if (queryParams.token !== String(null)) {
		const tokenData = jwtDecode<{ apartmentAccountId: number }>(queryParams.token);
		const userPhone = await StorageService.get(STORAGE_KEY.PHONE);
		const sessionId = await StorageService.get(STORAGE_KEY.SESSION_ID);
		const isPrevLoggedIn = await StorageService.get(STORAGE_KEY.IS_WAS_LOGGED_IN);

		url = getReceiptRoute(encryptData(tokenData.apartmentAccountId.toString()));

		if (userPhone && sessionId && isPrevLoggedIn) {
			await SessionsService.checkSession().catch(async () => {
				await redirectNotAuthorizedUser();
			});
		} else {
			url = ROUTER_URL.RECEIPT_PAY_PUBLIC;
		}
	} else {
		url = ROUTER_URL.RECEIPT_PAY_PUBLIC;
	}

	return url;
};

export const getCurrentReceiptMonth = (): string => {
	const currentMonth = dayjs().month();
	return (currentMonth === 0 ? 12 : currentMonth).toLocaleString('en-US', {
		minimumIntegerDigits: 2,
		useGrouping: false,
	});
};

export const getCurrentReceiptYear = (): string => {
	const currentMonth = dayjs().month();
	const currentYear = dayjs().year();
	return (currentMonth === 0 ? currentYear - 1 : currentYear).toString();
};

export const getReceiptRequestBody = (
	apartmentAccountId: string | number,
	userId: string,
	options?: Partial<IReceiptRequest>
): IReceiptRequest => ({
	apartmentAccountId: +apartmentAccountId,
	userId,
	month: getCurrentReceiptMonth(),
	year: dayjs().year().toString(),
	...options,
});

export const checkIsEmptySecondBillRequest = (data: IReceiptOtherServiceResponse) =>
	data.billType === BILL_TYPE.SECOND && !+data.amount && !(data.tarifItems as IBillRequestData)?.amount;

export const getOtherPortmoneAmountFromTariffItems = (
	tarifItems: ReceiptOtherServiceTariffItems | ReceiptOtherServiceTariffItems[]
): number => {
	let amount = 0;

	if (Array.isArray(tarifItems)) {
		amount = tarifItems.reduce((total, item) => {
			const amount = (item as IBillRequestData)?.amount;
			return +amount > 0 ? +amount + total : total;
		}, 0);
	} else if ((tarifItems as IBillRequestData)?.amount) {
		amount = +(tarifItems as IBillRequestData).amount;
	}
	return amount;
};

export const getPortmoneProviderPreviewData = (data: IReceiptOtherServiceResponse): string => {
	const amount = data?.tarifItems
		? getAmountTitle(-getOtherPortmoneAmountFromTariffItems(data.tarifItems))
		: getAmountTitle(data.amount - data.payed > 0 ? getAmountToPayString(data.amount - data.payed) : 0);
	const providerAccountIdValue = findServiceProviderAccountIdValue(data.serviceFieldsValues, data);
	return `${
		providerAccountIdValue ? `o/p ${providerAccountIdValue}${checkIsEmptySecondBillRequest(data) ? '' : ','} ` : ''
	}${checkIsEmptySecondBillRequest(data) ? '' : amount}`;
};

export const getReceiptPaymentTotalAmount = (list: ISingleReceiptPayProviderServiceRequest[]): number =>
	list.reduce((acc, item) => (Number(item.incomeBalance) >= 0 ? acc + Number(item.incomeBalance) : acc), 0);

export const convertServiceProviderDataUAHToCoins = (
	list: ISingleReceiptPayProviderServiceRequest[]
): ISingleReceiptPayProviderServiceRequest[] =>
	list
		.filter((item: ISingleReceiptPayProviderServiceRequest) => Number(item.incomeBalance) > 0)
		.map((item) => {
			const payload = { ...item, incomeBalance: convertUAHToCoins(item.incomeBalance).toString() };
			if (payload.serviceFieldsData) {
				const serviceFieldsData = Object.entries(payload.serviceFieldsData).reduce((acc, entry) => {
					return {
						...acc,
						[entry[0]]: checkIsAmountServiceFieldName(entry[0]) ? convertUAHToCoins(entry[1]).toString() : entry[1],
					};
				}, {});
				payload.serviceFieldsData = serviceFieldsData;
			}
			return payload;
		});

export const findServiceByCode = <
	T extends Pick<IProviderServiceResponse, 'code'> | Pick<IBillsSubBillItem, 'serviceProviderData'>
>(
	serviceList: T[],
	code: SERVICE_CODE_KVD | SERVICE_CODE_KTS
): T | undefined =>
	serviceList.find(
		(item) =>
			(item as IBillsSubBillItem)?.serviceProviderData?.code === code ||
			(item as IProviderServiceResponse)?.code === code
	);

export const getServicesNotGroupedByType = <
	T extends Pick<IProviderServiceResponse, 'code'> | Pick<IBillsSubBillItem, 'serviceProviderData'>
>(
	serviceList: T[],
	providerType: PROVIDER_TYPE
): T[] => {
	let excludedCodeList: (SERVICE_CODE_KVD | SERVICE_CODE_KTS)[] = [];
	switch (providerType) {
		case PROVIDER_TYPE.KVD:
			excludedCodeList = [
				SERVICE_CODE_KVD.WATTER_SUPPLY,
				SERVICE_CODE_KVD.DRAINAGE,
				SERVICE_CODE_KVD.CUSTOMER_SERVICE_WATTER_SUPPLY,
				SERVICE_CODE_KVD.MAINTENANCE_AND_SERVICE_WATTER_SUPPLY,
				SERVICE_CODE_KVD.MAINTENANCE_AND_SERVICE_DRAINAGE,
			];
			break;

		case PROVIDER_TYPE.KTS:
			excludedCodeList = [
				SERVICE_CODE_KTS.SUPPLY_SERVICE_THERMAL_ENERGY,
				SERVICE_CODE_KTS.SUPPLY_SERVICE_HOT_WATER,
				SERVICE_CODE_KTS.MAINTENANCE_AND_REPAIR_HEAT_SUPPLY,
				SERVICE_CODE_KTS.MAINTENANCE_AND_REPAIR_HOT_WATER,
			];
			break;

		default:
			break;
	}

	return serviceList.filter((item) => {
		const code = (item as IBillsSubBillItem)?.serviceProviderData?.code ?? (item as IProviderServiceResponse)?.code;
		return !excludedCodeList.includes(code as SERVICE_CODE_KVD);
	});
};

export const getGroupedServicesListByType = <T extends Pick<IProviderServiceResponse, 'code'>>(
	serviceList: T[],
	providerType: PROVIDER_TYPE
): { title: string | null; list: T[] }[] => {
	let payload: { title: string | null; list: (T | undefined)[] }[] = [];

	switch (providerType) {
		case PROVIDER_TYPE.KVD: {
			const waterSupply = findServiceByCode(serviceList, SERVICE_CODE_KVD.WATTER_SUPPLY);
			const drainage = findServiceByCode(serviceList, SERVICE_CODE_KVD.DRAINAGE);
			const customerServiceWaterSupply = findServiceByCode(
				serviceList,
				SERVICE_CODE_KVD.CUSTOMER_SERVICE_WATTER_SUPPLY
			);
			const customerServiceDrainage = findServiceByCode(serviceList, SERVICE_CODE_KVD.CUSTOMER_SERVICE_DRAINAGE);
			const maintenanceWaterSupply = findServiceByCode(
				serviceList,
				SERVICE_CODE_KVD.MAINTENANCE_AND_SERVICE_WATTER_SUPPLY
			);
			const maintenanceDrainage = findServiceByCode(serviceList, SERVICE_CODE_KVD.MAINTENANCE_AND_SERVICE_DRAINAGE);

			payload = [
				{ title: null, list: [waterSupply, drainage] },
				{ title: 'Плата за абонентське обслуговування:', list: [customerServiceWaterSupply, customerServiceDrainage] },
				{ title: 'Плата за утримання та обслуговування ВБС:', list: [maintenanceWaterSupply, maintenanceDrainage] },
				{ title: null, list: getServicesNotGroupedByType(serviceList, PROVIDER_TYPE.KVD) },
			];
			break;
		}

		case PROVIDER_TYPE.KTS: {
			const supplyServiceThermalEnergy = findServiceByCode(serviceList, SERVICE_CODE_KTS.SUPPLY_SERVICE_THERMAL_ENERGY);
			const supplyServiceHotWater = findServiceByCode(serviceList, SERVICE_CODE_KTS.SUPPLY_SERVICE_HOT_WATER);
			const maintenanceAndRepairHeatSupply = findServiceByCode(
				serviceList,
				SERVICE_CODE_KTS.MAINTENANCE_AND_REPAIR_HEAT_SUPPLY
			);
			const maintenanceAndRepairHOtWater = findServiceByCode(
				serviceList,
				SERVICE_CODE_KTS.MAINTENANCE_AND_REPAIR_HOT_WATER
			);

			payload = [
				{ title: 'Послуги з постачання:', list: [supplyServiceThermalEnergy, supplyServiceHotWater] },
				{
					title: 'ТО та ремонт ВБС:',
					list: [maintenanceAndRepairHeatSupply, maintenanceAndRepairHOtWater],
				},
				{ title: null, list: getServicesNotGroupedByType(serviceList, PROVIDER_TYPE.KTS) },
			];
			break;
		}

		default:
			payload = [{ title: '', list: serviceList }];
	}

	return payload
		.map((item) => ({
			...item,
			list: item.list.filter((serviceData) => !!serviceData) as T[],
		}))
		.filter((item) => {
			return item.list.length;
		});
};

export const removeReceiptList = async (): Promise<ISingleReceiptPayProviderServiceRequest[] | null> => {
	const result = await StorageService.get<ISingleReceiptPayProviderServiceRequest[]>(STORAGE_KEY.RECEIPT_SELECTED_LIST);
	await StorageService.remove(STORAGE_KEY.RECEIPT_SELECTED_LIST);
	return result;
};

export const convertCoinsInSingleReceiptResponse = (receipt: AxiosResponse<IReceiptResponse>) => {
	const { sumBalance, serviceProviderData, otherPaymentServices, ...rest } = receipt.data;
	const payload: IReceiptResponse = {
		...rest,
		sumBalance: convertCoinsToUAH(sumBalance),
		serviceProviderData: Object.entries(serviceProviderData).reduce((acc, entry) => {
			const key = entry[0];
			const value = entry[1].map((item) => {
				const payload = {
					...item,
					amountToPay: convertCoinsToUAH(item.amountToPay),
					incomeBalance: convertCoinsToUAH(item.incomeBalance),
					payed: item.payed ? convertCoinsToUAH(item.payed) : item.payed,
				};
				if (payload.fee) {
					payload.fee = convertCoinsToUAH(payload.fee);
				}
				return payload;
			});
			return { ...acc, [key]: value };
		}, {} as { [key: string]: IProviderServiceResponse[] }),
		otherPaymentServices: otherPaymentServices.map((item) => {
			const otherServiceData = {
				...item,
				amount: convertCoinsToUAH(item.amount),
				fee: convertCoinsToUAH(item.fee),
				payed: convertCoinsToUAH(item.payed),
				serviceFieldsValues: item.serviceFieldsValues.map((serviceFieldItem) => ({
					...serviceFieldItem,
					value: checkIsAmountServiceField(serviceFieldItem)
						? convertCoinsToUAH(serviceFieldItem.value).toString()
						: serviceFieldItem.value,
				})),
			};
			if (Array.isArray(otherServiceData.tarifItems)) {
				const unpaidTarifItems = otherServiceData.tarifItems.filter((tarifItem) => !tarifItem.error);
				otherServiceData.tarifItems = unpaidTarifItems.length ? unpaidTarifItems : otherServiceData.tarifItems[0];
			}
			return otherServiceData;
		}),
	};
	return payload;
};

import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { getServicesByCategory } from 'store/bill/actions';
import { resetOtherServicesList } from 'store/bill/reducer';
import { getServicesSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { getUserProfileSelector } from 'store/user-service/selectors';
import { Navbar } from 'components/Navbar';
import { ServiceBillRequestFields } from 'components/OtherServices';
import { PaymentMethodModal } from 'components/PaymentMethodModal';
import { CustomButton, Loader } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_PAGE, SERVICES_DEFAULT_REQUEST_BODY } from 'utils/constants';
import { LOCALE, PAY_TOKEN_TYPE, PAYMENT_PROVIDER, PROVIDER_TYPE, ROUTER_URL } from 'utils/enums';
import {
	convertCoinsToUAH,
	convertUAHToCoins,
	findPortmoneProviderAmountFieldValue,
	getAmountTitle,
	getContractNumberFieldName,
	getData3DS,
	getErrorMessage,
	getPortmoneServiceReturnUrl,
	getServicePaymentStatusRoute,
	getServicesByCategoryRoute,
	getUserFullName,
} from 'utils/helpers';
import { useOpen } from 'utils/hooks';
import { BillService, RadabankService } from 'utils/services';
import {
	IBillRequestData,
	IGetServiceDataResponse,
	IOtherServicePaymentRequest,
	IOtherServiceProviderServiceFieldsData,
	IPaymentResponse,
	IServiceBillRequestData,
	IServicesItemResponse,
	ISubServiceItem,
	StringOrNull,
} from 'utils/types';
import styles from './index.module.scss';

export const ServiceProviderPage: FC = () => {
	const history = useHistory();

	const { serviceId, categoryId } = useParams<{ serviceId: string; categoryId: string }>();

	const dispatch = useAppDispatch();

	const { isOpen, handleOpen, handleClose } = useOpen();

	const services = useAppSelector(getServicesSelector);
	const profile = useAppSelector(getUserProfileSelector);

	const [currentService, setCurrentService] = useState<IServicesItemResponse | null>(null);
	const [currentServiceData, setCurrentServiceData] = useState<IGetServiceDataResponse | null>(null);
	const [fee, setFee] = useState(0);
	const [totalAmount, setTotalAmount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [serviceRequestData, setServiceRequestData] = useState<IOtherServicePaymentRequest | null>(null);
	const [isShowDetails, setIsShowDetails] = useState(false);
	const [billRequestData, setBillRequestData] = useState<IServiceBillRequestData | IServiceBillRequestData[] | null>(
		null
	);

	useEffect(() => {
		return () => {
			dispatch(resetOtherServicesList());
		};
	}, []);

	useEffect(() => {
		if (!services.data.tableData.length) {
			getServices();
		} else if (!currentService) {
			const currentService = services.data.tableData.find((item: IServicesItemResponse) => item.id === serviceId);
			if (currentService) {
				setCurrentService(currentService);
			}
		}
	}, [services.data.tableData.length]);

	useEffect(() => {
		if (currentService && profile) {
			getCurrentServiceData(currentService.id, currentService.servicesContractsMap[0].contractId);

			const payload: IOtherServicePaymentRequest = {
				clientName: getUserFullName(profile),
				clientPhone: profile.phone,
				userId: profile.userId,
				returnUrl: getPortmoneServiceReturnUrl(categoryId, serviceId),
				serviceProviderData: [
					{
						code: currentService.code,
						serviceId: currentService.id,
						incomeBalance: convertUAHToCoins(0).toString(),
						fee: convertUAHToCoins(0).toString(),
						providerType: PROVIDER_TYPE.PORTMONE,
						providerService: currentService.name,
						serviceFieldsData: {},
					},
				],
			};

			setServiceRequestData(payload);
		}
	}, [profile, currentService]);

	useEffect(() => {
		if (totalAmount && serviceRequestData) {
			setServiceRequestData({
				...serviceRequestData,
				serviceProviderData: [
					{ ...serviceRequestData.serviceProviderData[0], incomeBalance: totalAmount.toString(), fee: fee.toString() },
				],
			});
		}
	}, [totalAmount, fee]);

	useEffect(() => {
		if (serviceRequestData) {
			setTotalAmount(+findPortmoneProviderAmountFieldValue(serviceRequestData));
		}
	}, [serviceRequestData?.serviceProviderData[0]?.serviceFieldsData]);

	useEffect(() => {
		if (serviceRequestData?.serviceProviderData.length && Array.isArray(billRequestData)) {
			const subServices: ISubServiceItem[] = billRequestData
				.filter((item) => !item.error && (item as IBillRequestData)?.amount && +(item as IBillRequestData).amount)
				.map((tarifItem: IServiceBillRequestData) => {
					const payload = {
						payeeId: (tarifItem as IBillRequestData).payee.$.id,
						amount: convertUAHToCoins((tarifItem as IBillRequestData).amount),
						serviceName: (tarifItem as IBillRequestData).payee._,
						providerService: (tarifItem as IBillRequestData).payee._,
						isShowServiceName: true,
					};
					const providerAccountIdFieldName = getContractNumberFieldName(currentServiceData as IGetServiceDataResponse);
					return { ...payload, [providerAccountIdFieldName]: (tarifItem as IBillRequestData).contractNumber._ };
				});
			const payload: IOtherServicePaymentRequest = {
				...serviceRequestData,
				serviceProviderData: [{ ...serviceRequestData?.serviceProviderData[0], subServices }],
			};

			setServiceRequestData(payload);
		}
	}, [serviceRequestData?.serviceProviderData.length, billRequestData]);

	const handleChangeServiceProviderData = (serviceFieldsData: IOtherServiceProviderServiceFieldsData) => {
		if (serviceRequestData) {
			const payload: IOtherServicePaymentRequest = {
				...serviceRequestData,
				serviceProviderData: [
					{
						...serviceRequestData.serviceProviderData[0],
						serviceFieldsData,
					},
				],
			};
			setServiceRequestData(payload);
		}
	};

	const getServices = async (): Promise<void> => {
		try {
			setIsLoading(true);
			await dispatch(
				getServicesByCategory({
					options: {
						...SERVICES_DEFAULT_REQUEST_BODY.options,
						serviceId: [serviceId],
						serviceCategoryId: [categoryId],
					},
					offset: 0,
					limit: 1,
					locale: LOCALE.UK,
				})
			).unwrap();
		} catch (error) {
			history.replace(ROUTER_URL.NOT_FOUND);
		} finally {
			setIsLoading(false);
		}
	};

	const getCurrentServiceData = async (serviceId: string, contractId: string): Promise<void> => {
		try {
			setIsLoading(true);

			const response: AxiosResponse<IGetServiceDataResponse> = await BillService.getServiceData({
				serviceId,
				contractId,
			});
			setCurrentServiceData(response.data);
		} catch (error) {
			history.replace(ROUTER_URL.ERROR);
		} finally {
			setIsLoading(false);
		}
	};

	const getServiceFee = async (amount: number): Promise<void> => {
		try {
			const response = await BillService.getServiceFee(amount, (currentService as IServicesItemResponse).code);
			setFee(response.data.fee);
			setTotalAmount(amount);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handlePayByCard = async (cardFromId: string, provider: StringOrNull): Promise<void> => {
		if (serviceRequestData) {
			let reqBody = {
				...serviceRequestData,
				data3DS2: getData3DS(),
				cardFromId,
			};

			if (provider === PAYMENT_PROVIDER.RADABANK) {
				const radabankData = await RadabankService.getInternalReqPayload({ userId: serviceRequestData.userId });
				const deviceData = await RadabankService.getReqPayload({});

				reqBody = {
					...reqBody,
					payToken: btoa(JSON.stringify({ ...radabankData, userId: undefined, ...deviceData })),
					payTokenType: PAY_TOKEN_TYPE.RADABANK,
				};
			}

			const response: AxiosResponse<IPaymentResponse[]> = await BillService.otherServicePay(reqBody);
			history.push(getServicePaymentStatusRoute(categoryId, serviceId, response.data[0].billId));
		}
	};

	const handlePayByWallet = async (payToken: string, payTokenType: PAY_TOKEN_TYPE): Promise<void> => {
		try {
			if (serviceRequestData) {
				const response: AxiosResponse<IPaymentResponse[]> = await BillService.otherServicePay({
					...serviceRequestData,
					payToken,
					payTokenType,
					data3DS2: getData3DS(),
				});
				history.push(getServicePaymentStatusRoute(categoryId, serviceId, response.data[0].billId));
			}
		} catch (err) {
			dispatch(showToast({ message: getErrorMessage(err) }));
		}
	};

	const handlePayByAnotherCard = async (): Promise<void> => {
		if (serviceRequestData) {
			const response: AxiosResponse<IPaymentResponse[]> = await BillService.otherServicePay(serviceRequestData);
			const redirectUrl = response.data[0].link;

			if (Capacitor.isNativePlatform()) {
				await Browser.open({ url: redirectUrl });
			} else {
				window.location.href = redirectUrl;
			}
		}
	};

	const handleSubmit = async (): Promise<void> => {
		try {
			handleOpen();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const getServiceDetailsData = async (): Promise<void> => {
		try {
			setIsLoading(true);
			const totalAmount = +findPortmoneProviderAmountFieldValue(serviceRequestData as IOtherServicePaymentRequest);
			await getServiceFee(totalAmount);
			setIsShowDetails(true);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<section className={classNames('page', styles.wrapper)}>
				{!!(currentService && currentServiceData) && (
					<div className="services-tab-content">
						<ServiceBillRequestFields
							serviceData={currentServiceData}
							onChange={handleChangeServiceProviderData}
							onSubmit={getServiceDetailsData}
							serviceItemData={currentService}
							onClickBack={() =>
								isShowDetails ? setIsShowDetails(false) : history.push(getServicesByCategoryRoute(categoryId))
							}
							onGetBillRequestData={(value) => setBillRequestData(value)}
						/>
						{!!isShowDetails && (
							<>
								<div className={styles.details}>
									<div className={styles.details__item}>
										<Typography variant="subtitle1">Комісія:</Typography>
										<Typography variant="subtitle1">{getAmountTitle(convertCoinsToUAH(fee))}</Typography>
									</div>
									<div className={styles.details__item}>
										<Typography variant="subtitle1">Сума до оплати:</Typography>
										<Typography variant="subtitle1">{getAmountTitle(convertCoinsToUAH(totalAmount + fee))}</Typography>
									</div>
								</div>
								<CustomButton
									label={`Сплатити ${getAmountTitle(convertCoinsToUAH(totalAmount + fee))}`}
									onClick={handleSubmit}
									disabled={!totalAmount}
								/>
							</>
						)}
					</div>
				)}
			</section>
			<PaymentMethodModal
				pageName={FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SERVICES}
				isOpen={isOpen}
				onClose={handleClose}
				totalAmount={convertCoinsToUAH(totalAmount + fee)}
				onPayByCard={handlePayByCard}
				onPayByAnotherCard={handlePayByAnotherCard}
				onPayByWallet={handlePayByWallet}
			/>
			<Navbar />
			{isLoading && <Loader />}
		</>
	);
};

import { FC } from 'react';
import { FormCustomAutocomplete } from 'components/shared/FormAutocomplete';
import { ISelectOption } from 'utils/types';

interface SurveyEducationMartialStatusProps {
	isLoading: boolean;
	martialStatusOptions?: ISelectOption[];
	educationOptions?: ISelectOption[];
}

export const SurveyEducationMartialStatus: FC<SurveyEducationMartialStatusProps> = ({
	isLoading,
	martialStatusOptions = [],
	educationOptions = [],
}) => {
	return (
		<>
			<FormCustomAutocomplete
				name="marital_status"
				label="Сімейний стан"
				options={martialStatusOptions}
				loading={isLoading}
			/>
			<FormCustomAutocomplete name="edication" label="Освіта" options={educationOptions} loading={isLoading} />
		</>
	);
};

import { Capacitor } from '@capacitor/core';
// Hooks
import useKeyboardWeb from 'utils/hooks/use-keyboard-web';
import useKeyboardMobile from './use-keyboard-mobile';

const isNative = Capacitor.isNativePlatform();

export const useKeyboard = isNative ? useKeyboardMobile : useKeyboardWeb;

export * from './use-debounce';
export * from './use-device';
export * from './use-formik-field';
export * from './use-formik-field-error';
export * from './use-go-back';
export * from './use-initialize';
export * from './use-ionic-back-button';
export * from './use-key-press';
export * from './use-keyboard-mobile';
export * from './use-loading';
export * from './use-long-press';
export * from './use-match-media';
export * from './use-measure-non-rendered-element';
export * from './use-open';
export * from './use-permission';
export * from './use-save-form-values-on-unmount';
export * from './use-stepper';
export * from './use-throttling';
export * from './use-toastify';
export * from './use-varaible-virtual-list';
export * from './useDispatchWithTryCatch';

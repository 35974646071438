import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import classNames from 'classnames';
import { getServicesByCategory } from 'store/bill/actions';
import { resetOtherServicesList } from 'store/bill/reducer';
import { getServicesSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { Navbar } from 'components/Navbar';
import { ServicesHeader, ServicesList } from 'components/OtherServices';
import { Loader } from 'components/shared';
import {
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	FIREBASE_EVENT_ANALYTICS_PAGE,
	SERVICES_DEFAULT_REQUEST_BODY,
} from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import { getErrorMessage, getServiceByIdRoute } from 'utils/helpers';
import { FirebaseAnalytics } from 'utils/services';
import { ServicesRequest } from 'utils/types';
import styles from './index.module.scss';

export const ServicesPage: FC = () => {
	const dispatch = useAppDispatch();

	const history = useHistory();
	const { categoryId } = useParams<{ categoryId: string }>();
	const { state } = useLocation<{ from?: string }>();

	const services = useAppSelector(getServicesSelector);

	const [reqBody, setReqBody] = useState<ServicesRequest>({
		...SERVICES_DEFAULT_REQUEST_BODY,
		options: { ...SERVICES_DEFAULT_REQUEST_BODY.options, serviceCategoryId: [categoryId] },
	});

	useEffect(() => {
		return () => {
			dispatch(resetOtherServicesList());
		};
	}, []);

	useEffect(() => {
		handleGetServices(reqBody);
	}, [reqBody]);

	const handleGetServices = async (reqBody: ServicesRequest): Promise<void> => {
		try {
			await dispatch(getServicesByCategory(reqBody)).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleClickCard = (serviceId: string, serviceName: string) => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SERVICES,
			`${FIREBASE_EVENT_ANALYTICS_BUTTON.SERVICES}-${serviceName}`
		);
		history.push(getServiceByIdRoute(categoryId, serviceId));
	};

	return (
		<>
			<section className={classNames('page', styles.wrapper)}>
				<div className="services-tab-content">
					<ServicesHeader
						categoryId={categoryId}
						data={reqBody}
						onChange={(data) => setReqBody(data)}
						onClickBack={() => history.push(state?.from || ROUTER_URL.SERVICES_CATEGORIES)}
					/>
					<ServicesList data={reqBody} onChange={(data) => setReqBody(data)} onClickCard={handleClickCard} />
				</div>
				{!services.data.tableData.length && services.isLoading && <Loader />}
			</section>
			<Navbar />
		</>
	);
};

/* eslint-disable camelcase */
import { Device } from '@capacitor/device';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { USER_API } from 'core';
import dayjs from 'dayjs';
import { customHistory } from 'utils/constants';
import {
	DATE_FORMAT,
	ERROR_MESSAGE,
	RADABANK_BOOLEAN_VALUE,
	RADABANK_GET_IMAGE_TYPE,
	ROUTER_URL,
	STORAGE_KEY,
	USER_API_URL,
} from 'utils/enums';
import { convertUAHToCoins, removeEmptyProps } from 'utils/helpers';
import {
	IRadabankBaseReqData,
	IRadabankBaseResponse,
	IRadabankBudgetCodeResponse,
	IRadabankBudgetCreatePaymentRequest,
	IRadabankBudgetCreatePaymentResponse,
	IRadabankClientRequestOverRequest,
	IRadabankClientRequestOverResponse,
	IRadabankCountryRuleResponse,
	IRadabankCreateP2PDataRequest,
	IRadabankCreateP2PDataRequestResponse,
	IRadabankCreatePaymentRequest,
	IRadabankCreatePaymentResponse,
	IRadabankCurrencyRuleResponse,
	IRadabankFeeRequest,
	IRadabankFeeResponse,
	IRadabankGetStatusPaymentByIdRequest,
	IRadabankGetStatusPaymentByIdResponse,
	IRadabankInfoAddressCitiesRequest,
	IRadabankInfoAddressCitiesResponse,
	IRadabankInfoAddressCityTypeRequest,
	IRadabankInfoAddressCityTypeResponse,
	IRadabankInfoAddressDistrictRequest,
	IRadabankInfoAddressDistrictResponse,
	IRadabankInfoAddressRegionRequest,
	IRadabankInfoAddressRegionResponse,
	IRadabankInfoAddressStreetTypesRequest,
	IRadabankInfoAddressStreetTypesResponse,
	IRadabankInfoApproveCodeRequest,
	IRadabankInfoApproveCodeResponse,
	IRadabankInfoBeginRequest,
	IRadabankInfoBeginResponse,
	IRadabankInfoCommitDocumentRequest,
	IRadabankInfoCommitDocumentResponse,
	IRadabankInfoCommitImageRequest,
	IRadabankInfoCommitImageResponse,
	IRadabankInfoCreateStartDocumentRequest,
	IRadabankInfoCreateStartDocumentResponse,
	IRadabankInfoData,
	IRadabankInfoDiyaBeginRequest,
	IRadabankInfoDiyaBeginResponse,
	IRadabankInfoGetImageRequest,
	IRadabankInfoGetImageResponse,
	IRadabankInfoSendCodeRequest,
	IRadabankInfoSendCodeResponse,
	IRadabankInfoSetAnketaRequest,
	IRadabankInfoSetAnketaResponse,
	IRadabankInfoSetImageRequest,
	IRadabankInfoSetImageResponse,
	IRadabankInfoSetPasswordRequest,
	IRadabankInfoSetPasswordResponse,
	IRadabankInfoSprDataRequest,
	IRadabankInfoSprDataResponse,
	IRadabankInfoStatusRequest,
	IRadabankInfoStatusResponse,
	IRadabankInternalActivateRequest,
	IRadabankInternalAddRuleRequest,
	IRadabankInternalAuthData,
	IRadabankInternalAuthorizeRequest,
	IRadabankInternalAuthorizeResponse,
	IRadabankInternalBlockCardRequest,
	IRadabankInternalCardCurrentRestDataRequest,
	IRadabankInternalCardCurrentRestDataResponse,
	IRadabankInternalCardLockReasonsResponse,
	IRadabankInternalChangePinCardRequest,
	IRadabankInternalChangePinCardResponse,
	IRadabankInternalClientPhoneRequest,
	IRadabankInternalClientPhoneResponse,
	IRadabankInternalCreateCoBrandCardRequest,
	IRadabankInternalCreateCoBrandCardResponse,
	IRadabankInternalCurrentRuleDeleteRequest,
	IRadabankInternalCurrentRulesRequest,
	IRadabankInternalCurrentRulesResponse,
	IRadabankInternalDataCardLimitRequest,
	IRadabankInternalDataCardLimitResponse,
	IRadabankInternalDeblockCardRequest,
	IRadabankInternalDeblockCardResponse,
	IRadabankInternalGetCvvCardRequest,
	IRadabankInternalGetCvvCardResponse,
	IRadabankInternalGetOperationsListRequest,
	IRadabankInternalGetOperationsListResponse,
	IRadabankInternalListCurrentCardResponse,
	IRadabankInternalPrintDocumentDataRequest,
	IRadabankInternalPrintDocumentDataResponse,
	IRadabankInternalRemindPinCardRequest,
	IRadabankInternalRemindPinCardResponse,
	IRadabankInternalSelectedDocumentRequest,
	IRadabankInternalSelectedDocumentResponse,
	IRadabankInternalSetSmsCardRequest,
	IRadabankInternalSmsCardRequest,
	IRadabankInternalSmsCardResponse,
	IRadabankInternalVerifyActivateCodeRequest,
	IRadabankInternalVerifyActivateCodeResponse,
	IRadabankInternalVerifyCoBrandCardRequest,
	IRadabankInternalVerifyCoBrandCardResponse,
	IRadabankInternalVerifySmsRequest,
	IRadabankInternalVerifySmsResponse,
	IRadabankNPoshtaCreateRequest,
	IRadabankNPoshtaCreateResponse,
	IRadabankNPoshtaInternalActivateCardRequest,
	IRadabankNPoshtaInternalCardStatusRequest,
	IRadabankNPoshtaInternalCardStatusResponse,
	IRadabankNPoshtaInternalCityRequest,
	IRadabankNPoshtaInternalCityResponse,
	IRadabankNPoshtaInternalDivisionRequest,
	IRadabankNPoshtaInternalDivisionResponse,
	IRadabankNPoshtaInternalStreetRequest,
	IRadabankNPoshtaInternalStreetResponse,
	IRadabankTransferCreatePaymentRequest,
	IRadabankTransferCreatePaymentResponse,
	IRadabankTransferFeeRequest,
	IRadabankTransferFeeResponse,
	IRadabankVerifyTokenRequest,
	IRadabankVerifyTokenResponse,
} from 'utils/types';
import { StorageService } from './storage.service';

class RadabankService {
	private static baseReqData: null | Omit<IRadabankBaseReqData, 'userId'> = null;

	private static getReqPayload = async <T>(reqBody: T): Promise<T> => {
		const payload = { ...reqBody };

		if (!this.baseReqData) {
			const deviceInfo = await Device.getInfo();
			this.baseReqData = {
				devname: deviceInfo.model.toLowerCase(),
				devos: deviceInfo.operatingSystem.toLowerCase(),
				devosdata: deviceInfo.osVersion,
				devtypeauth: '1',
			};
		}

		return {
			...this.baseReqData,
			...payload,
		};
	};

	private static getAuthInfoReqPayload = async <T>(reqBody: Partial<T>): Promise<T> => {
		const payload = { ...reqBody };
		const infoData = await StorageService.get<IRadabankInfoData>(STORAGE_KEY.RADABANK_AUTH_INFO);
		return removeEmptyProps({
			...payload,
			...infoData,
		}) as T;
	};

	private static fetchData = async <Q extends Partial<IRadabankBaseReqData>, S>(
		url: string,
		reqBody: Q,
		axiosConfig?: AxiosRequestConfig<Q>
	): Promise<AxiosResponse<S>> => {
		const payload = await this.getReqPayload<Q>(reqBody);
		return USER_API.post(url, payload, axiosConfig);
	};

	private static handleUnathorizedUser = () => {
		setTimeout(() => customHistory.replace(ROUTER_URL.RADABANK_CARD_AUTH_INTERNAL_AUTHORIZE));
		throw new Error(ERROR_MESSAGE.RADABANK_SHOULD_LOGIN_IN);
	};

	static getInternalReqPayload = async <T extends { userId: string }>(
		reqBody: Partial<T>
	): Promise<T & IRadabankInternalAuthData> => {
		const payload = { ...reqBody };
		const internalAuthData = await this.getInternalAuthData();

		const tokenDate = dayjs(internalAuthData?.tokenExpDate, DATE_FORMAT.RADABANK_TOKEN_DATE);
		const isTokenDateExpired = dayjs().isAfter(tokenDate);

		if (internalAuthData?.internalData && payload?.userId) {
			try {
				await this.verifyInfoToken({ ...internalAuthData.internalData, userId: payload.userId });
			} catch (error) {
				const isAbortError = error?.message === ERROR_MESSAGE.ABORT_MESSAGE;

				if (error && !isAbortError) {
					this.handleUnathorizedUser();
				}
			}
		}

		if (!internalAuthData?.tokenExpDate || isTokenDateExpired) {
			this.handleUnathorizedUser();
		}

		return {
			...payload,
			...internalAuthData?.internalData,
		} as T & IRadabankInternalAuthData;
	};

	static getInternalAuthData = async (): Promise<{
		internalData: IRadabankInternalAuthData | null;
		tokenExpDate: string | null;
	} | null> => {
		const authData = await StorageService.get<IRadabankInternalAuthData>(STORAGE_KEY.RADABANK_AUTH_INTERNAL);
		const tokenExpDate = await StorageService.get<string>(STORAGE_KEY.RADABANK_TOKEN_EXP_DATE);
		return { internalData: authData, tokenExpDate };
	};

	static authCheckInternalClientPhone = async (
		reqBody: IRadabankInternalClientPhoneRequest
	): Promise<AxiosResponse<IRadabankInternalClientPhoneResponse>> =>
		this.fetchData(USER_API_URL.RADABANK_AUTH_INTERNAL_CLIENT_PHONE, reqBody);

	static authInfoBegin = async (
		reqBody: IRadabankInfoBeginRequest
	): Promise<AxiosResponse<IRadabankInfoBeginResponse>> =>
		this.fetchData(USER_API_URL.RADABANK_AUTH_INFO_BEGIN, { ...reqBody, limitcredit: RADABANK_BOOLEAN_VALUE.TRUE });

	static verifyInfoToken = async (
		reqBody: IRadabankVerifyTokenRequest
	): Promise<AxiosResponse<IRadabankVerifyTokenResponse>> =>
		this.fetchData(USER_API_URL.RADABANK_AUTH_INFO_VERIFY_TOKEN, reqBody);

	static checkInfoStatus = async (userId: string): Promise<AxiosResponse<IRadabankInfoStatusResponse>> => {
		const reqPayload = await this.getAuthInfoReqPayload<IRadabankInfoStatusRequest>({ userId });
		return this.fetchData(USER_API_URL.RADABANK_AUTH_INFO_STATUS, reqPayload);
	};

	static approveInfoCode = async (
		reqBody: IRadabankInfoApproveCodeRequest
	): Promise<AxiosResponse<IRadabankInfoApproveCodeResponse>> => {
		const reqPayload = await this.getAuthInfoReqPayload<IRadabankInfoApproveCodeRequest>(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_AUTH_INFO_APPROVE_CODE, reqPayload);
	};

	static sendInfoCode = async (userId: string): Promise<AxiosResponse<IRadabankInfoSendCodeResponse>> => {
		const reqPayload = await this.getAuthInfoReqPayload<IRadabankInfoSendCodeRequest>({ userId });
		return this.fetchData(USER_API_URL.RADABANK_AUTH_INFO_SEND_CODE, reqPayload);
	};

	static beginInfoDiya = async (userId: string): Promise<AxiosResponse<IRadabankInfoDiyaBeginResponse>> => {
		const reqPayload = await this.getAuthInfoReqPayload<IRadabankInfoDiyaBeginRequest>({ userId });
		return this.fetchData(USER_API_URL.RADABANK_AUTH_INFO_DIYA_BEGIN, reqPayload);
	};

	static setInfoImage = async (
		reqBody: Pick<IRadabankInfoSetImageRequest, 'userId' | 'imagecontext' | 'typecode'>
	): Promise<AxiosResponse<IRadabankInfoSetImageResponse>> => {
		const reqPayload: IRadabankInfoSetImageRequest = await this.getAuthInfoReqPayload<IRadabankInfoSetImageRequest>(
			reqBody
		);
		return this.fetchData(USER_API_URL.RADABANK_AUTH_INFO_IMAGE_SET, reqPayload);
	};

	static commitInfoImage = async (userId: string): Promise<AxiosResponse<IRadabankInfoCommitImageResponse>> => {
		const reqPayload = await this.getAuthInfoReqPayload<IRadabankInfoCommitImageRequest>({
			userId,
		});
		return this.fetchData(USER_API_URL.RADABANK_AUTH_INFO_IMAGE_COMMIT, reqPayload);
	};

	static getInfoSprData = async (userId: string): Promise<AxiosResponse<IRadabankInfoSprDataResponse>> => {
		const reqPayload = await this.getAuthInfoReqPayload<IRadabankInfoSprDataRequest>({
			userId,
		});
		return this.fetchData(USER_API_URL.RADABANK_AUTH_INFO_SPR_DATA, reqPayload);
	};

	static getInfoAddressRegion = async (userId: string): Promise<AxiosResponse<IRadabankInfoAddressRegionResponse>> => {
		const reqPayload = await this.getAuthInfoReqPayload<IRadabankInfoAddressRegionRequest>({
			userId,
		});
		return this.fetchData(USER_API_URL.RADABANK_AUTH_INFO_GET_ADDRESS_REGION, reqPayload);
	};

	static getInfoAddressDistrict = async (
		reqBody: Pick<IRadabankInfoAddressDistrictRequest, 'userId' | 'adr_region_id'>
	): Promise<AxiosResponse<IRadabankInfoAddressDistrictResponse>> => {
		const reqPayload = await this.getAuthInfoReqPayload<IRadabankInfoAddressDistrictRequest>(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_AUTH_INFO_GET_ADDRESS_DISTRICT, reqPayload);
	};

	static getInfoAddressCityTypes = async (
		reqBody: Pick<IRadabankInfoAddressCityTypeRequest, 'userId' | 'adr_region_id' | 'adr_district_id'>
	): Promise<AxiosResponse<IRadabankInfoAddressCityTypeResponse>> => {
		const reqPayload = await this.getAuthInfoReqPayload<IRadabankInfoAddressCityTypeRequest>(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_AUTH_INFO_GET_ADDRESS_CITY_TYPES, reqPayload);
	};

	static getInfoAddressCities = async (
		reqBody: Pick<IRadabankInfoAddressCitiesRequest, 'userId' | 'adr_region_id' | 'adr_district_id' | 'adr_citytype_id'>
	): Promise<AxiosResponse<IRadabankInfoAddressCitiesResponse>> => {
		const reqPayload = await this.getAuthInfoReqPayload<IRadabankInfoAddressCitiesRequest>(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_AUTH_INFO_GET_ADDRESS_CITIES, reqPayload);
	};

	static getInfoAddressStreetTypes = async (
		reqBody: Pick<
			IRadabankInfoAddressStreetTypesRequest,
			'userId' | 'adr_region_id' | 'adr_district_id' | 'adr_city_id'
		>
	): Promise<AxiosResponse<IRadabankInfoAddressStreetTypesResponse>> => {
		const reqPayload = await this.getAuthInfoReqPayload<IRadabankInfoAddressStreetTypesRequest>(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_AUTH_INFO_GET_ADDRESS_STREET_TYPES, reqPayload);
	};

	static setInfoAnketa = async (
		reqBody: Omit<IRadabankInfoSetAnketaRequest, 'infotoken' | 'infocode'>
	): Promise<AxiosResponse<IRadabankInfoSetAnketaResponse>> => {
		const reqPayload = await this.getAuthInfoReqPayload<IRadabankInfoSetAnketaRequest>(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_AUTH_INFO_SET_ANKETA, {
			...reqPayload,
			amount_in: `${convertUAHToCoins(reqPayload.amount_in)}`,
			amount_out: `${convertUAHToCoins(reqPayload.amount_out)}`,
			amount_add: `${convertUAHToCoins(reqPayload.amount_add)}`,
		});
	};

	static setInfoPassword = async (userpassword: string): Promise<AxiosResponse<IRadabankInfoSetPasswordResponse>> => {
		const reqPayload = await this.getAuthInfoReqPayload<IRadabankInfoSetPasswordRequest>({ userpassword });
		return this.fetchData(USER_API_URL.RADABANK_AUTH_INFO_SET_PASSWORD, reqPayload);
	};

	static createInfoStartDocument = async (
		userId: string
	): Promise<AxiosResponse<IRadabankInfoCreateStartDocumentResponse>> => {
		const reqPayload = await this.getAuthInfoReqPayload<IRadabankInfoCreateStartDocumentRequest>({ userId });
		return this.fetchData(USER_API_URL.RADABANK_AUTH_INFO_CREATE_START_DOCUMENT, reqPayload);
	};

	static getInfoImage = async (
		userId: string,
		typeimage: RADABANK_GET_IMAGE_TYPE
	): Promise<AxiosResponse<IRadabankInfoGetImageResponse>> => {
		const reqPayload = await this.getAuthInfoReqPayload<IRadabankInfoGetImageRequest>({ userId, typeimage });
		return this.fetchData(USER_API_URL.RADABANK_AUTH_INFO_GET_IMAGE, reqPayload);
	};

	static commitInfoStartDocument = async (
		userId: string
	): Promise<AxiosResponse<IRadabankInfoCommitDocumentResponse>> => {
		const reqPayload = await this.getAuthInfoReqPayload<IRadabankInfoCommitDocumentRequest>({ userId });
		return this.fetchData(USER_API_URL.RADABANK_AUTH_INFO_COMMIT_START_DOCUMENT, reqPayload);
	};

	static commitInfoSignDocument = async (
		userId: string
	): Promise<AxiosResponse<IRadabankInfoCommitDocumentResponse>> => {
		const reqPayload = await this.getAuthInfoReqPayload<IRadabankInfoCommitDocumentRequest>({ userId });
		return this.fetchData(USER_API_URL.RADABANK_AUTH_INFO_COMMIT_SIGN_DOCUMENT, reqPayload);
	};

	static startActivateInternal = async (
		reqBody: IRadabankInfoBeginRequest
	): Promise<AxiosResponse<IRadabankBaseResponse>> => {
		return this.fetchData(USER_API_URL.RADABANK_AUTH_START_ACTIVATE_INTERNAL, reqBody);
	};

	static internalVerifyActivateCode = async (
		reqBody: IRadabankInternalVerifyActivateCodeRequest
	): Promise<AxiosResponse<IRadabankInternalVerifyActivateCodeResponse>> => {
		return this.fetchData(USER_API_URL.RADABANK_AUTH_INTERNAL_VERIFY_ACTIVATE_CODE, reqBody);
	};

	static activateInternal = async (
		reqBody: IRadabankInternalActivateRequest
	): Promise<AxiosResponse<IRadabankBaseResponse>> => {
		return this.fetchData(USER_API_URL.RADABANK_AUTH_INTERNAL_ACTIVATE, reqBody);
	};

	static authorizeInternal = async (reqBody: IRadabankInternalAuthorizeRequest): Promise<void> => {
		const response = await this.fetchData<IRadabankInternalAuthorizeRequest, IRadabankInternalAuthorizeResponse>(
			USER_API_URL.RADABANK_AUTH_INTERNAL_AUTHORIZE,
			reqBody
		);
		await StorageService.remove(STORAGE_KEY.RADABANK_AUTH_INFO);
		await StorageService.set(STORAGE_KEY.RADABANK_AUTH_INTERNAL, {
			clientcode: response.data.clientcode,
			token: response.data.token,
		});
	};

	static verifyInternalSms = async (reqData: IRadabankInternalVerifySmsRequest): Promise<void> => {
		const internalAuthData = await this.getInternalAuthData();
		if (!internalAuthData?.internalData) throw new Error(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
		const response = await this.fetchData<IRadabankInternalVerifySmsRequest, IRadabankInternalVerifySmsResponse>(
			USER_API_URL.RADABANK_AUTH_INTERNAL_SMS_VERIFY,
			{ ...reqData, ...internalAuthData.internalData }
		);
		await StorageService.set(STORAGE_KEY.RADABANK_TOKEN_EXP_DATE, response.data.expdate);
	};

	static createCoBrandCard = async (userId: string): Promise<void> => {
		const reqPayload = await this.getInternalReqPayload({ userId });
		const response = await this.fetchData<
			IRadabankInternalCreateCoBrandCardRequest,
			IRadabankInternalCreateCoBrandCardResponse
		>(USER_API_URL.RADABANK_AUTH_INTERNAL_CREATE_CO_BRAND_CARD, reqPayload);
		await StorageService.set(STORAGE_KEY.RADABANK_CREATE_CO_BRAND_CARD_REQUEST_ID, response.data.requestid);
	};

	static verifyCoBrandCard = async (
		reqBody: IRadabankInternalVerifyCoBrandCardRequest
	): Promise<AxiosResponse<IRadabankInternalVerifyCoBrandCardResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData<IRadabankInternalVerifyCoBrandCardRequest, IRadabankInternalVerifyCoBrandCardResponse>(
			USER_API_URL.RADABANK_AUTH_INTERNAL_VERIFY_CO_BRAND_CARD,
			reqPayload
		);
	};

	static getListCurrentCard = async (
		userId: string
	): Promise<AxiosResponse<IRadabankInternalListCurrentCardResponse>> => {
		const reqPayload = await this.getInternalReqPayload({ userId });
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_LIST_CURRENT_CARD, reqPayload);
	};

	static getListClosedCard = async (
		userId: string
	): Promise<AxiosResponse<IRadabankInternalListCurrentCardResponse>> => {
		const reqPayload = await this.getInternalReqPayload({ userId });
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_LIST_CLOSED_CARD, reqPayload);
	};

	static getCvvCode = async (
		reqBody: IRadabankInternalGetCvvCardRequest
	): Promise<AxiosResponse<IRadabankInternalGetCvvCardResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_GET_CVV_CARD, reqPayload);
	};

	static getOperationsList = async (
		reqBody: IRadabankInternalGetOperationsListRequest
	): Promise<AxiosResponse<IRadabankInternalGetOperationsListResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_GET_OPERATIONS_LIST, reqPayload);
	};

	static createCardPayment = async (
		reqBody: IRadabankCreatePaymentRequest
	): Promise<AxiosResponse<IRadabankCreatePaymentResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_CARD_CREATE_PAYMENT, reqPayload);
	};

	static getCardFee = async (reqBody: IRadabankFeeRequest): Promise<AxiosResponse<IRadabankFeeResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_CARD_FEE, reqPayload);
	};

	static getStatusPaymentById = async (
		reqBody: IRadabankGetStatusPaymentByIdRequest
	): Promise<AxiosResponse<IRadabankGetStatusPaymentByIdResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_GET_STATUS_PAYMENT_BY_ID, reqPayload);
	};

	static createTransferPayment = async (
		reqBody: IRadabankTransferCreatePaymentRequest | IRadabankBudgetCreatePaymentRequest
	): Promise<AxiosResponse<IRadabankTransferCreatePaymentResponse | IRadabankBudgetCreatePaymentResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_TRANSFER_CREATE_PAYMENT, reqPayload);
	};

	static getTransferFee = async (
		reqBody: IRadabankTransferFeeRequest
	): Promise<AxiosResponse<IRadabankTransferFeeResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_TRANSFER_FEE, reqPayload);
	};

	static getCountryRule = async (userId: string): Promise<AxiosResponse<IRadabankCountryRuleResponse>> => {
		const reqPayload = await this.getInternalReqPayload({ userId });
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_GET_COUNTRY_RULE, reqPayload);
	};

	static getCurrencyRule = async (userId: string): Promise<AxiosResponse<IRadabankCurrencyRuleResponse>> => {
		const reqPayload = await this.getInternalReqPayload({ userId });
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_GET_CURRENCY_RULE, reqPayload);
	};

	static getBudgetCode = async (userId: string): Promise<AxiosResponse<IRadabankBudgetCodeResponse>> => {
		const reqPayload = await this.getInternalReqPayload({ userId });
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_GET_BUDGET_CODE, reqPayload);
	};

	// secure
	static getInternalCardLockReasons = async (
		userId: string
	): Promise<AxiosResponse<IRadabankInternalCardLockReasonsResponse>> => {
		const reqPayload = await this.getInternalReqPayload({ userId });
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_GET_CARD_LOCK_REASONS, reqPayload);
	};

	static blockInternalCard = async (
		reqBody: IRadabankInternalBlockCardRequest
	): Promise<AxiosResponse<IRadabankBaseResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_BLOCK_CARD, reqPayload);
	};

	static deblockInternalCard = async (
		reqBody: IRadabankInternalDeblockCardRequest
	): Promise<AxiosResponse<IRadabankInternalDeblockCardResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_DEBLOCK_CARD, reqPayload);
	};

	static remindPinCard = async (
		reqBody: IRadabankInternalRemindPinCardRequest
	): Promise<AxiosResponse<IRadabankInternalRemindPinCardResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_REMIND_PIN_CARD, reqPayload);
	};

	static changePinCard = async (
		reqBody: IRadabankInternalChangePinCardRequest
	): Promise<AxiosResponse<IRadabankInternalChangePinCardResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_CHANGE_PIN_CARD, reqPayload);
	};

	static getCardCurrentRestData = async (
		reqBody: IRadabankInternalCardCurrentRestDataRequest
	): Promise<AxiosResponse<IRadabankInternalCardCurrentRestDataResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_GET_CARD_CURRENT_REST_DATA, reqPayload);
	};

	static internalClientRequestOver = async (
		reqBody: IRadabankClientRequestOverRequest
	): Promise<AxiosResponse<IRadabankClientRequestOverResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_CLIENT_REQUEST_OVER, reqPayload);
	};

	static getDataCardLimit = async (
		reqBody: IRadabankInternalDataCardLimitRequest
	): Promise<AxiosResponse<IRadabankInternalDataCardLimitResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_GET_DATA_CARD_LIMIT, reqPayload);
	};

	static getInternalSprData = async (userId: string): Promise<AxiosResponse<IRadabankInfoSprDataResponse>> => {
		const reqPayload = await this.getInternalReqPayload({
			userId,
		});
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_GET_SPR_DATA, reqPayload);
	};

	static getInternalSelectedDocument = async (
		reqBody: IRadabankInternalSelectedDocumentRequest
	): Promise<AxiosResponse<IRadabankInternalSelectedDocumentResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_GET_SELECTED_DOCUMENT, reqPayload);
	};

	static getCurrentRules = async (
		reqBody: IRadabankInternalCurrentRulesRequest
	): Promise<AxiosResponse<IRadabankInternalCurrentRulesResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_GET_CURRENT_RULES, reqPayload);
	};

	static addCurrentRule = async (
		reqBody: IRadabankInternalAddRuleRequest
	): Promise<AxiosResponse<IRadabankBaseResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_ADD_RULE, reqPayload);
	};

	static deleteCurrentRule = async (
		reqBody: IRadabankInternalCurrentRuleDeleteRequest
	): Promise<AxiosResponse<IRadabankBaseResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_DELETE_RULE, reqPayload);
	};

	static printInternalDocumentData = async (
		reqBody: IRadabankInternalPrintDocumentDataRequest
	): Promise<AxiosResponse<IRadabankInternalPrintDocumentDataResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_PRINT_DOCUMENT_DATA, reqPayload);
	};

	static getInternalSmsCard = async (
		reqBody: IRadabankInternalSmsCardRequest
	): Promise<AxiosResponse<IRadabankInternalSmsCardResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_SMS_CARD, reqPayload);
	};

	static setInternalCardSms = async (
		reqBody: IRadabankInternalSetSmsCardRequest
	): Promise<AxiosResponse<IRadabankBaseResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_SET_CARD_SMS, reqPayload);
	};

	static createP2PData = async (
		reqBody: IRadabankCreateP2PDataRequest
	): Promise<AxiosResponse<IRadabankCreateP2PDataRequestResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_INTERNAL_CREATE_P2P_DATA, reqPayload);
	};

	// NPoshta
	static getNPoshtaInternalCity = async (
		reqBody: IRadabankNPoshtaInternalCityRequest
	): Promise<AxiosResponse<IRadabankNPoshtaInternalCityResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_NPOSHTA_GET_INTERNAL_CITY, reqPayload);
	};

	static getNPoshtaInternalStreet = async (
		reqBody: IRadabankNPoshtaInternalStreetRequest
	): Promise<AxiosResponse<IRadabankNPoshtaInternalStreetResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_NPOSHTA_GET_INTERNAL_STREET, reqPayload);
	};

	static getNPoshtaInternalDivisions = async (
		reqBody: IRadabankNPoshtaInternalDivisionRequest
	): Promise<AxiosResponse<IRadabankNPoshtaInternalDivisionResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_NPOSHTA_GET_INTERNAL_DIVISION, reqPayload);
	};

	static createNPoshtaInternalRequest = async (
		reqBody: IRadabankNPoshtaCreateRequest
	): Promise<AxiosResponse<IRadabankNPoshtaCreateResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_NPOSHTA_INTERNAL_CREATE_REQUEST, reqPayload);
	};

	static getNPoshtaInternalCardStatus = async (
		reqBody: IRadabankNPoshtaInternalCardStatusRequest
	): Promise<AxiosResponse<IRadabankNPoshtaInternalCardStatusResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_NPOSHTA_GET_INTERNAL_CARD_STATUS, reqPayload);
	};

	static activateNPoshtaInternalCard = async (
		reqBody: IRadabankNPoshtaInternalActivateCardRequest
	): Promise<AxiosResponse<IRadabankBaseResponse>> => {
		const reqPayload = await this.getInternalReqPayload(reqBody);
		return this.fetchData(USER_API_URL.RADABANK_NPOSHTA_INTERNAL_ACTIVATE_CARD, reqPayload);
	};
}

export { RadabankService };

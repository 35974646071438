import { FC, HTMLProps, ReactNode } from 'react';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './index.module.scss';

interface CardItemProps extends HTMLProps<HTMLDivElement> {
	label: string;
	icon?: ReactNode;
}

export const CardItem: FC<CardItemProps> = ({ label, onClick, children, icon, className, ...props }) => (
	<div
		{...props}
		className={classNames(styles.content, className, {
			[styles.content_view]: !onClick,
		})}
		onClick={onClick}
	>
		<Typography className={styles.content__label} variant="body1" component="div">
			<div className={styles.title__icon}>{icon}</div>
			{label}
		</Typography>

		<Typography variant="body2" component="div" className={styles.content__value}>
			{children}
		</Typography>
	</div>
);

import { FC } from 'react';
import { Typography } from '@mui/material';
import { FormTextField } from 'components/shared/FormTextField';

export const SurveyContactPerson: FC = () => {
	return (
		<>
			<Typography>ПІБ контактної особи</Typography>
			<FormTextField name="contact_lastname" label="Прізвище" />
			<FormTextField name="contact_firstname" label="Ім’я" />
			<FormTextField name="contact_middlename" label="По-батькові" />
		</>
	);
};

import { useEffect } from 'react';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { IconButton } from '@mui/material';
import { ReactComponent as AddCircleSVG } from 'assets/icons/add-circle.svg';
import { ReactComponent as TrashSVG } from 'assets/icons/trash.svg';
import { AxiosResponse } from 'axios';
import { deleteCard, getCardsList } from 'store/cards/actions';
import { getCardsSelector } from 'store/cards/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeConfirmModal, openConfirmModal } from 'store/system/reducer';
import { showToast } from 'store/toastify/reducer';
import { getIsUserHasFullAccessSelector, getUserProfileSelector } from 'store/user-service/selectors';
import { CardPreview, CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { CardService, FirebaseAnalytics } from 'utils/services';
import { ICardRequestResponse, ICardsReducer, IUserProfileResponse } from 'utils/types';
import styles from './index.module.scss';

export const UserCards = () => {
	const dispatch = useAppDispatch();

	const cardsReducer: ICardsReducer = useAppSelector(getCardsSelector);
	const userProfile: IUserProfileResponse | null = useAppSelector(getUserProfileSelector);
	const isHasFullAccess = useAppSelector(getIsUserHasFullAccessSelector);

	useEffect(() => {
		// fetch cards after web was view closed
		Browser.addListener('browserFinished', () => {
			if (userProfile) {
				getUserCards(userProfile.userId);
			}
		});

		return () => {
			Browser.removeAllListeners();
		};
	}, []);

	const handleClickAddCard = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SETTINGS,
				FIREBASE_EVENT_ANALYTICS_BUTTON.ADD_CARD
			);
			const response: AxiosResponse<ICardRequestResponse> = await CardService.createRequest({
				userId: userProfile?.userId as string,
				returnUrl: `${process.env.REACT_APP_URL}${ROUTER_URL.SETTINGS}`,
			});

			// in an app-url-litener we close the browser for ios
			if (Capacitor.isNativePlatform()) {
				await Browser.open({ url: response.data.link });
			} else {
				window.location.href = response.data.link;
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const deleteUserCard = async (cardId: string): Promise<void> => {
		try {
			if (userProfile) {
				await dispatch(deleteCard({ cardId, userId: userProfile.userId, cardType: 'OWN' })).unwrap();
			}
			dispatch(closeConfirmModal());
			dispatch(showToast({ message: 'Карту видалено успішно', color: 'success' }));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const getUserCards = async (userId: string): Promise<void> => {
		try {
			await dispatch(getCardsList(userId)).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleClickDeleteCard = (cardId: string): void => {
		dispatch(
			openConfirmModal({
				title: 'Видалити картку',
				icon: <TrashSVG />,
				submitButtonLabel: 'Видалити',
				subTitle: 'Ви впевнені, що бажаєте видалити картку?',
				onSubmit: () => deleteUserCard(cardId),
			})
		);
	};

	return (
		<div className={styles.wrapper}>
			{cardsReducer.list.map((item) => (
				<div className={styles.list__item} key={item.cardId}>
					<CardPreview data={item} />
					<IconButton onClick={() => handleClickDeleteCard(item.cardId)}>
						<TrashSVG />
					</IconButton>
				</div>
			))}
			{isHasFullAccess && (
				<CustomButton fill="clear" startIcon={<AddCircleSVG />} label="Додати картку" onClick={handleClickAddCard} />
			)}
		</div>
	);
};

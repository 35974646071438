import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Typography } from '@mui/material';
import { getBillsReport } from 'store/bill/actions';
import { resetBillsReport } from 'store/bill/reducer';
import { getBillsReportSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getReportPaymentReceipt } from 'store/report/actions';
import { resetReportReceipt } from 'store/report/reducer';
import { getReportPaymentReceiptSelector } from 'store/report/selectors';
import { openDocumentViewer, setAppLoading } from 'store/system/reducer';
import { showToast } from 'store/toastify/reducer';
import { getUserProfileSelector } from 'store/user-service/selectors';
import { BillsPaymentDetails, BillsReportProviderItem } from 'components/Bills';
import { ReceiptPageHeader } from 'components/Receipt';
import { Loader, ProviderAccordionGroup } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { DATE_FORMAT, PAYMENT_STATUS, PDF_TYPE, ROUTER_URL } from 'utils/enums';
import {
	formatDateView,
	getErrorMessage,
	getPortmoneSubBillsByService,
	getReportDownloadFileName,
} from 'utils/helpers';
import { FirebaseAnalytics } from 'utils/services';
import { IBillsSubBillItem, IReportPaymentReceiptResponse } from 'utils/types';
import styles from './index.module.scss';

export const ServicesBillsReportPage: FC = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const params = useParams<{ id: string }>();

	const billsReport = useAppSelector(getBillsReportSelector);
	const reportReceipt = useAppSelector(getReportPaymentReceiptSelector);
	const profile = useAppSelector(getUserProfileSelector);

	const [subBills, setSubBills] = useState<IBillsSubBillItem[][]>([]);

	useEffect(() => {
		return () => {
			dispatch(resetBillsReport());
			dispatch(resetReportReceipt());
		};
	}, []);

	useEffect(() => {
		if (profile && params.id) {
			handleGetBillsReport(params.id);
		}
	}, [profile]);

	useEffect(() => {
		if (billsReport.data && profile) {
			handleGetReportData(billsReport.data.id, profile.userId);
			const subBillsPayload = getPortmoneSubBillsByService(billsReport.data.subBills);
			setSubBills(subBillsPayload);
		}
	}, [billsReport.data]);

	const handleGetBillsReport = async (billId: string): Promise<void> => {
		try {
			await dispatch(getBillsReport(billId)).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleGetReportData = async (id: string, userId: string): Promise<void> => {
		try {
			await dispatch(getReportPaymentReceipt({ id, userId })).unwrap();
		} catch (error) {
			if (error?.statusCode !== 400 && error?.error !== 'BadRequest') {
				dispatch(showToast({ message: getErrorMessage(error) }));
			}
		}
	};

	const handleClickDownload = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SERVICES_BILLS_REPORT,
				FIREBASE_EVENT_ANALYTICS_BUTTON.DOWNLOAD
			);
			if (profile) {
				dispatch(setAppLoading(true));
				if (!reportReceipt.data || (reportReceipt.data as IReportPaymentReceiptResponse)?.s3URL === null) {
					throw new Error('КО не знайдено');
				} else {
					dispatch(
						openDocumentViewer({
							url: reportReceipt.data.s3URL,
							isShareEnabled: true,
							fileName: getReportDownloadFileName(reportReceipt.data, PDF_TYPE.PAYMENT),
						})
					);
				}
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SERVICES_BILLS_REPORT,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		history.push(ROUTER_URL.SERVICES_ARCHIVE_LIST);
	};

	return (
		<div className="page">
			<div className="receipt-page-content">
				{!!billsReport.data && (
					<>
						<ReceiptPageHeader
							title={
								<Typography variant="subtitle1" component="span">
									{`Платіж № ${billsReport.data.shortenedId}`}
								</Typography>
							}
							description={
								<Typography className={styles.header__description} variant="body2" component="span">
									{formatDateView(billsReport.data.createdAt, DATE_FORMAT.DATE_TIME)}
								</Typography>
							}
							onBackClick={handleGoBack}
							onDownload={billsReport.data.subBills[0].status === PAYMENT_STATUS.PAID ? handleClickDownload : undefined}
						/>
						<div className="report-bills-content">
							<ProviderAccordionGroup>
								{subBills.map((item) => (
									<BillsReportProviderItem key={`report-service-provider-${item[0].id}`} serviceList={item} />
								))}
							</ProviderAccordionGroup>
							<BillsPaymentDetails data={billsReport.data} />
						</div>
					</>
				)}
			</div>
			{billsReport.isLoading && <Loader />}
		</div>
	);
};

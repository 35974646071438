/* eslint-disable no-nested-ternary */
import { FC, useEffect, useRef, useState } from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { TextField, Typography } from '@mui/material';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { getBillReceiptSelector } from 'store/bill/selectors';
import { useAppSelector } from 'store/hooks';
import { Checkbox } from 'components/shared/Checkbox';
import { CURRENCY_LABEL } from 'utils/enums';
import {
	getAmountTitle,
	getAmountToPayString,
	getProviderServiceTitle,
	getServiceTariffItemsList,
	handleKeyDownServiceAmount,
} from 'utils/helpers';
import { IBillRequestData, IProviderServiceResponse, ITariffItemData } from 'utils/types';
import { ServiceTariffItem } from './ServiceTariffItem';
import styles from './index.module.scss';

interface EditServiceAmountToPayProps {
	data: IProviderServiceResponse;
	incomeBalance: number;
	isChecked: boolean;
	onSelect: (data: IProviderServiceResponse) => void;
	onChange: (data: IProviderServiceResponse) => void;
	feeAmount?: number | null;
}

export const EditServiceAmountToPay: FC<EditServiceAmountToPayProps> = ({
	data,
	isChecked,
	onSelect,
	onChange,
	incomeBalance,
	feeAmount,
}) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const billReceipt = useAppSelector(getBillReceiptSelector);

	const [isDisabled, setIsDisabled] = useState(false);
	const [tarifItemsList, setTarifItemsList] = useState<ITariffItemData[]>([]);

	useEffect(() => {
		setIsDisabled(billReceipt.isLoading || !!billReceipt.data?.isArchived);
	}, [billReceipt, data]);

	useEffect(() => {
		if ((data.tarifItems as IBillRequestData)?.tariff_items) {
			const payload = getServiceTariffItemsList((data.tarifItems as IBillRequestData).tariff_items);
			setTarifItemsList(payload);
		}
	}, [data.tarifItems]);

	const handleChangeValue = ({ value }: NumberFormatValues): void => {
		onChange({ ...data, amountToPay: value === '' ? 0 : +value });
		if (inputRef.current && +value === 0) {
			setTimeout(() => {
				(inputRef.current as HTMLInputElement).setSelectionRange(1, 1);
			});
		}
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				<div className={styles.content__info}>
					{/* eslint-disable-next-line no-nested-ternary */}
					{data.tarifItems?.error ? (
						<Typography variant="subtitle2" fontWeight="normal">
							{data.tarifItems?.error as string}
						</Typography>
					) : tarifItemsList.length ? (
						<div className={styles.tariff__list}>
							{tarifItemsList.map((item, index) => (
								<ServiceTariffItem key={`tarif-list-${item.name}-${index}`} data={item} />
							))}
						</div>
					) : (
						<>
							<Typography className={styles.content__title} variant="subtitle2">
								{getProviderServiceTitle(data)}
							</Typography>
							{Number.isFinite(feeAmount) ? (
								<Typography variant="caption">Комісія: {getAmountTitle(feeAmount as number)}</Typography>
							) : (
								<Typography variant="caption">
									Баланс:
									<span
										className={classNames(styles.content__info_accented, {
											[styles.content__info_positive]: incomeBalance <= 0,
										})}
									>{` ${getAmountTitle(getAmountToPayString(incomeBalance))} `}</span>
									на {dayjs(data.createdAt).format('DD.MM.YYYY')}
								</Typography>
							)}
							<NumericFormat
								suffix={CURRENCY_LABEL.UAH}
								id={`${data.id}-input`}
								customInput={TextField}
								inputRef={inputRef}
								decimalScale={2}
								value={data.amountToPay}
								onValueChange={handleChangeValue}
								allowNegative={false}
								allowLeadingZeros
								fixedDecimalScale
								defaultValue={0}
								thousandSeparator=" "
								size="small"
								inputProps={{ inputMode: 'decimal', autoComplete: 'off' }}
								disabled={isDisabled}
								allowedDecimalSeparators={[',']}
								onKeyDown={(event) => handleKeyDownServiceAmount(event, data.amountToPay)}
							/>
						</>
					)}
				</div>
				{!data.tarifItems && !data.isArchived && (
					<Checkbox
						id={`${data.id}-checkbox`}
						checked={isChecked}
						onChange={() => onSelect(data)}
						disabled={isDisabled}
					/>
				)}
			</div>
		</div>
	);
};

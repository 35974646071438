import { ChangeEvent, FC, useEffect, useState } from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { ReactComponent as RadioIcon } from 'assets/icons/radio.svg';
import { ReactComponent as RadioCheckedIcon } from 'assets/icons/radio-checked.svg';
import classNames from 'classnames';
import { Field, useFormikContext } from 'formik';
import { getUserProfileSelector, showToast, useAppDispatch, useAppSelector } from 'store';
import { CustomAutocomplete, CustomButton, IBANInput } from 'components/shared';
import CustomSwitch from 'components/shared/CustomSwitch';
import { checkIsRadabankIban, getErrorMessage, getTextFieldValidationProps } from 'utils/helpers';
import { RadabankService } from 'utils/services';
import { IRadabankTransferCreatePaymentRequest, ISelectOption, IUserProfileResponse } from 'utils/types';
import styles from './index.module.scss';

export const FormContent: FC = () => {
	const dispatch = useAppDispatch();

	const { setFieldValue, errors, touched, isValid, values, setValues } = useFormikContext<
		IRadabankTransferCreatePaymentRequest & { isResident: boolean; isIPN: boolean }
	>();

	const profile = useAppSelector(getUserProfileSelector);

	const [isRadabankClient, setIsRadabankClient] = useState(true);
	const [countriesOptionsList, setCountriesOptionsList] = useState<ISelectOption<string>[]>([]);
	const [isLoadingCountriesList, setIsLoadingCountriesList] = useState(false);

	useEffect(() => {
		if (values.ibanrec.length >= 10) {
			const isRadabankIban = checkIsRadabankIban(values.ibanrec);
			setIsRadabankClient(isRadabankIban);
			if (isRadabankIban) {
				setValues({ ...values, namerec: '', innrec: '', docnumberrec: '', docseriarec: '' });
			}
		}
	}, [values.ibanrec]);

	useEffect(() => {
		setValues(values.isIPN ? { ...values, docnumberrec: '', docseriarec: '' } : { ...values, namerec: '', innrec: '' });
	}, [values.isIPN]);

	useEffect(() => {
		if (!values.isResident && !countriesOptionsList.length) {
			hangleGetCountiesList();
		} else if (values.isResident) {
			setFieldValue('countryrec', '');
		}
	}, [values.isResident]);

	const hangleGetCountiesList = async (): Promise<void> => {
		try {
			setIsLoadingCountriesList(true);
			const response = await RadabankService.getCountryRule((profile as IUserProfileResponse).userId);
			setCountriesOptionsList(response.data.countrys.map((item) => ({ value: item.id, label: item.name })));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoadingCountriesList(false);
		}
	};

	const handleChangeInnRecValue = (event: ChangeEvent<HTMLInputElement>): void => {
		const value = event.target.value;
		if (/^\d*$/.test(value)) {
			setFieldValue('innrec', value);
		}
	};

	const handleChangeAmountValue = ({ value }: NumberFormatValues): void => {
		setFieldValue('amount', value);
	};

	const handleChangePassportSeria = (event: ChangeEvent<HTMLInputElement>) => {
		if (
			!event.target.value ||
			event.target.value < values.docseriarec ||
			/^[а-яА-ЯіІїЇєЄ']{0,2}$/.test(event.target.value)
		) {
			setFieldValue(event.target.name, event.target.value.toUpperCase());
		}
	};

	const handleChangePassportNumber = (event: ChangeEvent<HTMLInputElement>) => {
		if (
			!event.target.value ||
			event.target.value < values.docnumberrec ||
			(values.docseriarec ? /^\d{0,6}$/ : /^\d{0,9}$/).test(event.target.value)
		) {
			setFieldValue(event.target.name, event.target.value.toUpperCase());
		}
	};

	return (
		<>
			<div className={styles.content}>
				<Field
					name="ibanrec"
					as={IBANInput}
					label="IBAN отримувача"
					{...getTextFieldValidationProps(errors, touched, 'ibanrec')}
				/>
				{!isRadabankClient && (
					<div className={styles.column}>
						<RadioGroup
							className={styles.row}
							defaultValue={values.isIPN}
							value={values.isIPN}
							onChange={(event) => setFieldValue('isIPN', JSON.parse(event.target.value))}
						>
							<FormControlLabel
								value={true}
								control={<Radio icon={<RadioIcon />} checkedIcon={<RadioCheckedIcon />} />}
								label="ІПН або ЄДРПОУ"
								componentsProps={{
									typography: {
										variant: 'caption',
									},
								}}
							/>
							<FormControlLabel
								value={false}
								control={<Radio icon={<RadioIcon />} checkedIcon={<RadioCheckedIcon />} />}
								label="ІПН відсутній"
								componentsProps={{
									typography: {
										variant: 'caption',
									},
								}}
							/>
						</RadioGroup>
						{values.isIPN ? (
							<Field
								name="innrec"
								as={TextField}
								label="Введіть ІПН або ЄДРПО"
								inputProps={{ inputMode: 'decimal', autoComplete: 'off' }}
								onChange={handleChangeInnRecValue}
								{...getTextFieldValidationProps(errors, touched, 'innrec')}
							/>
						) : (
							<Field
								name="docseriarec"
								label="Серія паспорта"
								as={TextField}
								onChange={handleChangePassportSeria}
								inputProps={{ maxLength: 2 }}
								{...getTextFieldValidationProps(errors, touched, 'docseriarec')}
							/>
						)}
					</div>
				)}
				<div className={classNames(styles.column, styles.column_big)}>
					{!isRadabankClient && values.isIPN ? (
						<Field
							name="namerec"
							as={TextField}
							label="ПІБ отримувача"
							{...getTextFieldValidationProps(errors, touched, 'namerec')}
						/>
					) : (
						!isRadabankClient && (
							<Field
								name="docnumberrec"
								as={TextField}
								label="Номер паспорта"
								inputProps={{ inputMode: 'decimal', autoComplete: 'off' }}
								onChange={handleChangePassportNumber}
								{...getTextFieldValidationProps(errors, touched, 'docnumberrec')}
							/>
						)
					)}
					<Field
						name="isResident"
						as={FormControlLabel}
						label="Отримувач переказу нерезидент"
						control={<CustomSwitch checked={!values.isResident} />}
						labelPlacement="start"
						componentsProps={{
							typography: {
								variant: 'subtitle1',
								onClick: () => setFieldValue('isResident', !values.isResident),
							},
						}}
						value={values.isResident}
						onChange={() => setFieldValue('isResident', !values.isResident)}
					/>
					{!values.isResident && (
						<Field
							name="countryrec"
							label="Країна отримувача"
							component={CustomAutocomplete}
							options={countriesOptionsList}
							loading={isLoadingCountriesList}
							{...getTextFieldValidationProps(errors, touched, 'countryrec')}
						/>
					)}
					<Field
						name="amount"
						label="Сума переказу"
						as={NumericFormat}
						{...getTextFieldValidationProps(errors, touched, 'amount')}
						customInput={TextField}
						onValueChange={handleChangeAmountValue}
						onChange={undefined}
						allowNegative={false}
						allowLeadingZeros
						fixedDecimalScale
						defaultValue={0}
						thousandSeparator=" "
						inputProps={{ inputMode: 'decimal', autoComplete: 'off' }}
						decimalScale={2}
						allowedDecimalSeparators={[',']}
					/>
				</div>
				<Field
					name="destination"
					as={TextField}
					label="Призначення"
					multiline
					{...getTextFieldValidationProps(errors, touched, 'destination')}
				/>
			</div>
			<CustomButton type="submit" label="Далі" disabled={!isValid} />
		</>
	);
};

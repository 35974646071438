import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { CustomButton } from 'components/shared';
import { IRadabankInfoSetAnketaForm } from 'utils/types';
import styles from './index.module.scss';

export interface SurveyStepControlsProps {
	stepsCount: number;
	currentStep: number;
	onPreviousStep: () => void;
	onNextStep: () => void;
}

export const SurveyStepControls: FC<SurveyStepControlsProps> = ({
	stepsCount,
	currentStep,
	onPreviousStep,
	onNextStep,
}) => {
	const { formState, trigger } = useFormContext<IRadabankInfoSetAnketaForm>();

	useEffect(() => {
		trigger();
	}, [currentStep]);

	const isFirstStep = !currentStep;

	const isLastStep = currentStep === stepsCount - 1;

	const nextStepButtonText = isLastStep ? 'Продовжити' : 'Далі';

	const nextStepButtonType = isLastStep ? 'submit' : 'button';

	return (
		<div className={styles.controls}>
			{!isFirstStep && <CustomButton fill="outline" label="Назад" onClick={onPreviousStep} />}
			<CustomButton
				type={nextStepButtonType}
				label={nextStepButtonText}
				disabled={!formState.isValid}
				onClick={onNextStep}
			/>
		</div>
	);
};

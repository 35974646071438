import { FC, MouseEvent, useState } from 'react';
import { Browser } from '@capacitor/browser';
import { MenuList } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { getRadabankCurrentCardOverdraftDataSelector, showToast, useAppDispatch, useAppSelector } from 'store';
import {
	CardOverdraftAmountInput,
	RadabankDocumentViewButton,
	RadabankSubmissionCheckbox,
} from 'components/RadabankCard';
import { CustomButton } from 'components/shared';
import { getErrorMessage, getTextFieldValidationProps } from 'utils/helpers';
import { IRadabankClientRequestOverRequest } from 'utils/types';
import styles from './index.module.scss';

interface RadabankOverdraftAmountFormContentProps {
	onSubmit: (event: MouseEvent<HTMLIonButtonElement>) => void;
}

export const RadabankOverdraftAmountFormContent: FC<RadabankOverdraftAmountFormContentProps> = ({ onSubmit }) => {
	const dispatch = useAppDispatch();

	const { setFieldValue, touched, errors } = useFormikContext<IRadabankClientRequestOverRequest>();

	const overdraftData = useAppSelector(getRadabankCurrentCardOverdraftDataSelector);

	const [isSubmitted, setIsSubmitted] = useState(false);

	const handleClickCreditLimitPassport = async (): Promise<void> => {
		try {
			await Browser.open({ url: process.env.REACT_APP_RADABANK_PASSPORT_OF_CREDIT_LIMIT_URL as string });
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleClickCreditLimitTerms = async (): Promise<void> => {
		try {
			await Browser.open({ url: process.env.REACT_APP_RADABANK_CREDIT_LIMIT_TERMS_URL as string });
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const amountValidationProps = getTextFieldValidationProps(errors, touched, 'amount');

	return (
		<>
			{!!overdraftData.data && (
				<Field
					name="amount"
					as={CardOverdraftAmountInput}
					onChange={(value: number) => setFieldValue('amount', value > 0 ? value.toString() : '')}
					min={+overdraftData.data.overamountmin}
					max={+overdraftData.data.overamountmax}
					error={amountValidationProps?.error}
				/>
			)}
			<MenuList className={styles.content__documents}>
				<RadabankDocumentViewButton onClick={handleClickCreditLimitPassport} label="Паспорт кредитного ліміту" />
				<RadabankDocumentViewButton onClick={handleClickCreditLimitTerms} label="Умови продукту" />
			</MenuList>
			<RadabankSubmissionCheckbox
				className={styles.content__submission}
				// eslint-disable-next-line max-len
				label="Натискаючи кнопки «Далі» та «Встановити», я прошу встановити ліміт овердрафту згідно з умовами ДКБО ФО та відповідно до встановлених Банком правил. Підтверджую, що ознайомлений з паспортом споживчого кредиту та умовами, що є невід’ємною частиною договору"
				onChange={(value: boolean) => setIsSubmitted(value)}
				checked={isSubmitted}
			/>
			<CustomButton
				className={styles.content__submit}
				label="Далі"
				disabled={!!amountValidationProps?.helperText || !isSubmitted}
				onClick={onSubmit}
			/>
		</>
	);
};

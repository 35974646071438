import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { BiometryErrorType } from '@aparajita/capacitor-biometric-auth';
import { Capacitor } from '@capacitor/core';
import { AxiosResponse } from 'axios';
import { useAppDispatch } from 'store/hooks';
import { setUserProfile } from 'store/user-service/reducer';
import { ConfirmOTPCode } from 'components/Auth/ConfirmOTPCode';
import { ArrowBackButton, CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE, OTP_LENGTH } from 'utils/constants';
import { ERROR_CODE, ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import { getErrorMessage, getErrorResponse, redirectNotAuthorizedUser } from 'utils/helpers';
import { useToastify } from 'utils/hooks/use-toastify';
import { BiometryService, FirebaseAnalytics, StorageService, UserService } from 'utils/services';
import { IErrorResponse, IUserResponse } from 'utils/types';

export const VerifyUserSignupPage: FC = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const [code, setCode] = useState('');
	const [isValid, setIsValid] = useState(true);
	const [isFetching, setIsFetching] = useState(false);
	const [error, setError] = useState('');
	const [phone, setPhone] = useState('');

	const isFormDisabled = code.length !== OTP_LENGTH || !isValid;

	const errorToast = useToastify(!!error, error, () => setError(''));

	useEffect(() => {
		StorageService.get<string>(STORAGE_KEY.PHONE).then((value: string | null) => {
			if (!value) {
				redirectNotAuthorizedUser();
			} else {
				setPhone(value);
			}
		});
	}, []);

	const handleChangeOtp = (value: string): void => {
		if (!isValid) {
			setIsValid(true);
		}
		setCode(value);
	};

	const handleConfirmCode = async (): Promise<void> => {
		try {
			setIsFetching(true);
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.VERIFY_USER,
				FIREBASE_EVENT_ANALYTICS_BUTTON.VERIFY_USER
			);
			const response: AxiosResponse<IUserResponse> = await UserService.confirmUser({
				code,
			});
			if (response.data) {
				dispatch(setUserProfile(response.data));
			}
			StorageService.remove(STORAGE_KEY.VERIFICATION_ID);
			StorageService.remove(STORAGE_KEY.VERIFICATION_ID_DATE);

			if (Capacitor.isNativePlatform() && BiometryService.isAvailable) {
				await BiometryService.auth({
					androidTitle: 'Використовувати біометричну автентифікацію?',
				});
				await BiometryService.setBiometryType(BiometryService.primaryBiometryType);
			}

			history.push(ROUTER_URL.AUTH_UPDATE_PROFILE);
		} catch (error) {
			const errorResponse: IErrorResponse | undefined = getErrorResponse(error);
			if (errorResponse?.errorData?.code === ERROR_CODE.INVALID_CONFIRM_CODE) {
				setIsValid(false);
			} else if (error.code === BiometryErrorType.biometryNotAvailable || error.code === BiometryErrorType.userCancel) {
				history.push(ROUTER_URL.AUTH_UPDATE_PROFILE);
			}
			setError(errorResponse?.errorData?.message || errorResponse?.message || error.message);
		} finally {
			setIsFetching(false);
		}
	};

	const handleClickResend = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.VERIFY_USER,
				FIREBASE_EVENT_ANALYTICS_BUTTON.RESEND_OTP
			);
			setIsFetching(true);
			await UserService.verifyUserResend();
		} catch (error) {
			setError(getErrorMessage(error));
		} finally {
			setIsFetching(false);
		}
	};

	const handleClickBack = (): void => {
		FirebaseAnalytics.logEvent(FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.VERIFY_USER, FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK);
		history.goBack();
	};

	const handleSubmitOtp = async () => {
		if (isFormDisabled) return;

		await handleConfirmCode();
	};

	return (
		<>
			<ArrowBackButton onClick={handleClickBack} />
			<ConfirmOTPCode
				onSubmit={handleSubmitOtp}
				phone={phone}
				value={code}
				onChange={handleChangeOtp}
				isValid={isValid}
				onResendCode={handleClickResend}
			/>
			<CustomButton
				disabled={isFormDisabled}
				label="Продовжити"
				onClick={handleConfirmCode}
				isLoading={isFetching}
				size="large"
			/>
			{errorToast}
		</>
	);
};

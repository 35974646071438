import { ChangeEvent, FC } from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { TextField, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { CustomAutocomplete, CustomButton, PhoneInput } from 'components/shared';
import { CURRENCY_LABEL, RADABANK_BOOLEAN_VALUE } from 'utils/enums';
import { getTextFieldValidationProps, getUnmaskedPhone } from 'utils/helpers';
import { IRadabankClientRequestOverRequest, IRadabankInfoSprDataResponse } from 'utils/types';
import styles from './index.module.scss';

interface RadabankOverdraftLetterFormContentProps {
	internalSprData: IRadabankInfoSprDataResponse;
}

export const RadabankOverdraftLetterFormContent: FC<RadabankOverdraftLetterFormContentProps> = ({
	internalSprData,
}) => {
	const { values, setFieldValue, touched, errors, isValid } = useFormikContext<IRadabankClientRequestOverRequest>();

	const handleChangeAmountValue = (name: string, { value }: NumberFormatValues): void => {
		setFieldValue(name, value);
	};

	const handleChangeSwitch = (event: ChangeEvent<HTMLInputElement> | string): void => {
		const name = typeof event === 'string' ? event : event.target.name;
		setFieldValue(
			name,
			values[name as keyof typeof values] === RADABANK_BOOLEAN_VALUE.FALSE
				? RADABANK_BOOLEAN_VALUE.TRUE
				: RADABANK_BOOLEAN_VALUE.FALSE
		);
	};

	const handleChangePhoneNumber = (event: ChangeEvent<HTMLInputElement>) => {
		const phoneNumber = getUnmaskedPhone(event.target.value);
		// format should be 090000000
		setFieldValue('contact_phone', phoneNumber.slice(2));
	};

	return (
		<div className={styles.content}>
			<Field
				name="maritial_status"
				label="Сімейний стан"
				component={CustomAutocomplete}
				options={internalSprData.marital_status}
				isSearchDisabled
				{...getTextFieldValidationProps(errors, touched, 'maritial_status')}
			/>
			<Field
				name="education"
				label="Освіта"
				component={CustomAutocomplete}
				options={internalSprData.edication}
				isSearchDisabled
				{...getTextFieldValidationProps(errors, touched, 'education')}
			/>
			<div>
				<Typography marginBottom="var(--spacing-2)">
					Притягались ви або члени вашої родини до кримінальної відповідальності?
				</Typography>
				<div className={styles.row}>
					<CustomButton
						fill={values.criminal === RADABANK_BOOLEAN_VALUE.TRUE ? 'solid' : 'outline'}
						label="Так"
						onClick={() => handleChangeSwitch('criminal')}
					/>
					<CustomButton
						fill={values.criminal === RADABANK_BOOLEAN_VALUE.FALSE ? 'solid' : 'outline'}
						label="Ні"
						onClick={() => handleChangeSwitch('criminal')}
					/>
				</div>
			</div>
			<div className={styles.separator} />
			<Field
				name="term_work"
				label="Строк роботи на поточному робочому місці"
				component={CustomAutocomplete}
				options={internalSprData.term_work}
				isSearchDisabled
				{...getTextFieldValidationProps(errors, touched, 'term_work')}
			/>
			<Field
				name="amount_out"
				label="Щомісячні витрати(гривень)"
				as={NumericFormat}
				{...getTextFieldValidationProps(errors, touched, 'amount_out')}
				suffix={CURRENCY_LABEL.UAH}
				customInput={TextField}
				decimalScale={0}
				onValueChange={(props: NumberFormatValues) => handleChangeAmountValue('amount_out', props)}
				onChange={undefined}
				allowNegative={false}
				allowLeadingZeros
				fixedDecimalScale
				defaultValue={0}
				thousandSeparator=" "
				inputProps={{ inputMode: 'decimal', autoComplete: 'off' }}
			/>
			<div className={styles.separator} />
			<div className={styles.column}>
				<Typography>ПІБ контактної особи</Typography>
				<Field
					name="contact_lastname"
					label="Прізвище"
					as={TextField}
					{...getTextFieldValidationProps(errors, touched, 'contact_lastname')}
				/>
				<Field
					name="contact_firstname"
					label="Ім’я"
					as={TextField}
					{...getTextFieldValidationProps(errors, touched, 'contact_firstname')}
				/>
				<Field
					name="contact_middlename"
					label="По-батькові"
					as={TextField}
					{...getTextFieldValidationProps(errors, touched, 'contact_middlename')}
				/>
			</div>
			<div className={styles.column}>
				<Typography>Номер телефону контактної особи</Typography>
				<Field
					name="contact_phone"
					as={PhoneInput}
					onChange={handleChangePhoneNumber}
					{...getTextFieldValidationProps(errors, touched, 'contact_phone')}
				/>
			</div>
			<Field
				name="contact_person"
				label="Ступінь відносин з контактною особою"
				component={CustomAutocomplete}
				options={internalSprData.contact_person}
				isSearchDisabled
				{...getTextFieldValidationProps(errors, touched, 'contact_person')}
			/>
			<CustomButton type="submit" className={styles.content__submit} label="Встановити" disabled={!isValid} />
		</div>
	);
};

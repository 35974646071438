import { TouchEvent, useRef } from 'react';
import { TypeOrNull } from 'utils/types';

export const useLongPress = (onPress: () => void, delay = 1000) => {
	const timerRef = useRef<TypeOrNull<NodeJS.Timeout>>(null);

	const onTouchStart = (e: TouchEvent) => {
		e.stopPropagation();
		timerRef.current = setTimeout(onPress, delay);
	};

	const onTouchEnd = (e: TouchEvent) => {
		e.stopPropagation();

		if (timerRef.current) {
			clearTimeout(timerRef.current);
			timerRef.current = null;
		}
	};

	return {
		onTouchStart,
		onTouchEnd,
	};
};

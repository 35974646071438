import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import { ReactComponent as ClipboardText } from 'assets/icons/clipboard-text.svg';
import classNames from 'classnames';
import { getServicesCategories } from 'store/bill/actions';
import { getServicesCategoriesSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { Navbar } from 'components/Navbar';
import { ServicesCategoriesHeader, ServicesCategoriesList } from 'components/OtherServices';
import { Loader } from 'components/shared';
import {
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	FIREBASE_EVENT_ANALYTICS_PAGE,
	SERVICES_CATEGORIES_DEFAULT_REQUEST_BODY,
} from 'utils/constants';
import { BILL_TYPE, ROUTER_URL } from 'utils/enums';
import { getErrorMessage, getServicesByCategoryRoute } from 'utils/helpers';
import { FirebaseAnalytics } from 'utils/services';
import { IServicesCategoriesRequest } from 'utils/types';
import styles from './index.module.scss';

export const ServicesCategoriesPage: FC = () => {
	const history = useHistory();
	const { state } = useLocation<{ from?: string }>();

	const dispatch = useAppDispatch();

	const categories = useAppSelector(getServicesCategoriesSelector);

	const [reqBody, setReqBody] = useState<IServicesCategoriesRequest>({
		...SERVICES_CATEGORIES_DEFAULT_REQUEST_BODY,
		billType: BILL_TYPE.OTHER,
	});

	useEffect(() => {
		handleGetServicesCategories(reqBody);
	}, [reqBody]);

	const handleGetServicesCategories = async (reqBody: IServicesCategoriesRequest): Promise<void> => {
		try {
			await dispatch(getServicesCategories(reqBody)).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleClickCard = (categoryId: string, categoryName: string) => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SERVICES,
			`${FIREBASE_EVENT_ANALYTICS_BUTTON.CATEGORIES}-${categoryName}`
		);
		history.push(getServicesByCategoryRoute(categoryId));
	};

	const handleGoBack = () => {
		if(state.from) {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SERVICES,
				FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
			);
			history.push(state.from)
		}
	}

	return (
		<>
			<div className={classNames('page', styles.wrapper)}>
				<div className="services-tab-content">
					<ServicesCategoriesHeader
						onClickBack={state?.from ? handleGoBack : undefined}
						className={styles.header}
						title={state?.from ? 'Платежі' : ''}
						data={reqBody}
						onChange={(data) => setReqBody(data)}
					>
						<Link to={ROUTER_URL.SERVICES_ARCHIVE_LIST}>
							<Tooltip title="Архів платежів" placement="bottom-start">
								<IconButton className={styles.header__button}>
									<ClipboardText />
								</IconButton>
							</Tooltip>
						</Link>
					</ServicesCategoriesHeader>
					<ServicesCategoriesList data={reqBody} onChange={(data) => setReqBody(data)} onClickCard={handleClickCard} />
				</div>
				{!categories.data.tableData.length && categories.isLoading && <Loader />}
			</div>
			<Navbar />
		</>
	);
};

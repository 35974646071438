import { FC } from 'react';
import QRCode from 'react-qr-code';
import { Capacitor } from '@capacitor/core';
import { Typography } from '@mui/material';
import { ReactComponent as CheckSvg } from 'assets/icons/check-circle.svg';
import { RadabankCardPreview } from 'components/RadabankCard';
import { CustomButton } from 'components/shared';
import { ADVANTAGES_LIST } from './constants';
import styles from './index.module.scss';

interface CardBannerProps {
	isActionsDisabled?: boolean;

	onClick: () => void;
}

export const CardBanner: FC<CardBannerProps> = ({ isActionsDisabled = false, onClick }) => {
	const cardSubtitle = Capacitor.isNativePlatform()
		? 'Замов картку та отримай безліч переваг:'
		: 'Замов картку в мобільному додатку та отримай безліч переваг:';

	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				<RadabankCardPreview className={styles.card} />
				<Typography
					className={styles.title}
					variant="subtitle1"
					color="var(--color-primary-500)"
					marginBottom="var(--spacing-3)"
				>
					{cardSubtitle}
				</Typography>
				<ul className={styles.advantages__list}>
					{ADVANTAGES_LIST.map((item) => (
						<li key={item}>
							<CheckSvg />
							<Typography variant="caption" color="var(--color-primary-500)">
								{item}
							</Typography>
						</li>
					))}
				</ul>
				{!Capacitor.isNativePlatform() && (
					<div className={styles.qr}>
						<QRCode
							size={256}
							// style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
							value="https://hostpay.tech/"
							width="100%"
							height="100%"
							// viewBox={`0 0 256 256`}
						/>
					</div>
				)}
				{!isActionsDisabled && <CustomButton label="Замовити" onClick={onClick} />}
			</div>
		</div>
	);
};

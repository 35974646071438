import { ChangeEvent, FC, useEffect, useState } from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { TextField } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { getUserProfileSelector, showToast, useAppDispatch, useAppSelector } from 'store';
import { CustomAutocomplete, CustomButton, IBANInput } from 'components/shared';
import { getErrorMessage, getTextFieldValidationProps } from 'utils/helpers';
import { RadabankService } from 'utils/services';
import { IRadabankBudgetCreatePaymentRequest, ISelectOption, IUserProfileResponse } from 'utils/types';
import styles from './index.module.scss';

export const FormContent: FC = () => {
	const dispatch = useAppDispatch();

	const { setFieldValue, errors, touched, isValid } = useFormikContext<IRadabankBudgetCreatePaymentRequest>();

	const profile = useAppSelector(getUserProfileSelector);

	const [budgetCodeOptionsList, setBudgetCodeOptionsList] = useState<ISelectOption<string>[]>([]);
	const [isLoadingBudgetCodeList, setIsLoadingBudgetCodeList] = useState(false);

	useEffect(() => {
		hangleGetCountiesList();
	}, []);

	const hangleGetCountiesList = async (): Promise<void> => {
		try {
			setIsLoadingBudgetCodeList(true);
			const response = await RadabankService.getBudgetCode((profile as IUserProfileResponse).userId);
			setBudgetCodeOptionsList(response.data.budgetcodes.map((item) => ({ value: item.id, label: item.name })));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoadingBudgetCodeList(false);
		}
	};

	const handleChangeInnRecValue = (event: ChangeEvent<HTMLInputElement>): void => {
		const value = event.target.value;
		if (/^\d*$/.test(value)) {
			setFieldValue('innrec', value);
		}
	};

	const handleChangeAmountValue = ({ value }: NumberFormatValues): void => {
		setFieldValue('amount', value);
	};

	return (
		<>
			<div className={styles.content}>
				<Field
					name="ibanrec"
					as={IBANInput}
					label="IBAN отримувача"
					{...getTextFieldValidationProps(errors, touched, 'ibanrec')}
				/>
				<Field
					name="innrec"
					as={TextField}
					label="Введіть ЄДРПО"
					inputProps={{ inputMode: 'decimal', autoComplete: 'off' }}
					onChange={handleChangeInnRecValue}
					{...getTextFieldValidationProps(errors, touched, 'innrec')}
				/>
				<Field
					name="budgetcode"
					label="Код виду сплати"
					component={CustomAutocomplete}
					options={budgetCodeOptionsList}
					loading={isLoadingBudgetCodeList}
					{...getTextFieldValidationProps(errors, touched, 'budgetcode')}
				/>
				<Field
					name="budgetadd"
					as={TextField}
					label="Додаткова інформація запису"
					{...getTextFieldValidationProps(errors, touched, 'budgetadd')}
				/>
				<Field
					name="namerec"
					as={TextField}
					label="Отримувач"
					{...getTextFieldValidationProps(errors, touched, 'namerec')}
				/>
				<Field
					name="amount"
					label="Сума переказу"
					as={NumericFormat}
					{...getTextFieldValidationProps(errors, touched, 'amount')}
					customInput={TextField}
					onValueChange={handleChangeAmountValue}
					onChange={undefined}
					allowNegative={false}
					allowLeadingZeros
					fixedDecimalScale
					defaultValue={0}
					thousandSeparator=" "
					inputProps={{ inputMode: 'decimal', autoComplete: 'off' }}
					decimalScale={2}
					allowedDecimalSeparators={[',']}
				/>
			</div>
			<CustomButton type="submit" label="Далі" disabled={!isValid} />
		</>
	);
};

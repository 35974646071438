import { FC } from 'react';
import { MenuItem, Typography } from '@mui/material';
import { getLocaleAmountString } from 'utils/helpers';
import { IRadabankInternalOperation } from 'utils/types';
import styles from './index.module.scss';

interface OperationItemProps {
	data: IRadabankInternalOperation;
}

export const OperationItem: FC<OperationItemProps> = ({ data }) => (
	<MenuItem className={styles.content} disableRipple>
		<img
			className={styles.content__icon}
			src={`${process.env.REACT_APP_RADABANK_CATEGORY_IMAGE_URL}/${data.merchantpicture}`}
			alt=""
		/>
		<div className={styles.content__info}>
			<Typography variant="caption">
				{`${data.categoryname.slice(0, 1).toUpperCase()}${data.categoryname.slice(1)}`}
			</Typography>
			<Typography variant="body2">{`${data.merchantkey} ${data.merchantname}`}</Typography>
			{!!data.comment && <Typography fontSize="10px">Коментар: {data.comment}</Typography>}
		</div>
		<Typography className={styles.content__amount}>{getLocaleAmountString(data.amount)}</Typography>
	</MenuItem>
);

import { FC, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { SplashScreen } from '@capacitor/splash-screen';
import {
	getCardsList,
	getIsAppUrlListenerInProgressSelector,
	getUserProfile,
	setUserProfile,
	showToast,
	updateIsAppUrlListenerInProgress,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { Loader } from 'components/shared';
import { ACTION_TIMEOUT_DELAY, PUBLIC_URL_LIST } from 'utils/constants';
import { ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import {
	checkIsSessionError,
	getErrorMessage,
	getReceiptPayRedirectUrl,
	getReceiptStatusRoute,
	getServicePaymentStatusRoute,
	getWindowLocationData,
	parseQueryString,
	redirectNotAuthorizedUser,
	removeReceiptList,
} from 'utils/helpers';
import { useDevice } from 'utils/hooks';
import { PushNotificationsService, SessionsService, StorageService } from 'utils/services';
import styles from './index.module.scss';

export const AppUrlListener: FC = () => {
	const history = useHistory();

	const { isMobile } = useDevice();

	const { pathname, search } = useLocation();

	const dispatch = useAppDispatch();

	const isAppUrlListenerInProgress = useAppSelector(getIsAppUrlListenerInProgressSelector);

	const content = useMemo(() => {
		if (isAppUrlListenerInProgress && !isMobile) {
			return (
				<div className={styles.wrapper}>
					<Loader />
				</div>
			);
		}
		return null;
	}, [isAppUrlListenerInProgress, isMobile]);

	useEffect(() => {
		handleInit();
	}, []);

	const handleInit = async (): Promise<void> => {
		try {
			const sessionId = await StorageService.get(STORAGE_KEY.SESSION_ID);
			if (!sessionId) {
				const sessionResponse = await SessionsService.getSession();
				await StorageService.set(STORAGE_KEY.SESSION_ID, sessionResponse.data.sessionId);
			}
			if (Capacitor.isNativePlatform()) {
				const status = await Network.getStatus();
				if (!status.connected) {
					history.replace(ROUTER_URL.ERROR_NETWORK);
				}

				await PushNotificationsService.init();

				await App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
					const locationData = getWindowLocationData(event.url);
					if (locationData.length) {
						await getAction(locationData[0], locationData[1]);
					}
				});

				await getAction(pathname, search);
			} else {
				await getAction(pathname, search);
			}
		} finally {
			dispatch(updateIsAppUrlListenerInProgress(false));
			setTimeout(() => {
				SplashScreen.hide();
			}, ACTION_TIMEOUT_DELAY);
		}
	};

	const getAction = async (pathname: string, queryString?: string): Promise<void> => {
		try {
			if (
				PUBLIC_URL_LIST.includes(pathname as ROUTER_URL) ||
				pathname.includes(ROUTER_URL.RECEIPT_PAY_PUBLIC_STATUS.replace(':billId', ''))
			) {
				return;
			}
			if (pathname.includes(ROUTER_URL.RECEIPT_PAY_DEEPLINK) && queryString) {
				const url = await getReceiptPayRedirectUrl(queryString);
				handleRedirect(url, url === ROUTER_URL.RECEIPT_PAY_PUBLIC ? queryString : '');
				return;
			}

			if (pathname.includes(ROUTER_URL.PAYMENT_STATUS_MAIN)) {
				const params = pathname
					.split(ROUTER_URL.PAYMENT_STATUS_MAIN)
					.filter((item: string) => !!item)[0]
					.split('/')
					.filter((item) => !!item);

				// check and get service or receipt payment status, for a receipt payment status apartmentAccountId is hashed string
				const redirectUrl =
					params.length === 2
						? getReceiptStatusRoute(params[0], params[1])
						: getServicePaymentStatusRoute(params[0], params[1], params[2]);
				handleRedirect(redirectUrl);
				Browser.close().catch((error) => {
					console.error(error);
				});
			}

			if (pathname.includes(ROUTER_URL.SETTINGS) && Capacitor.isNativePlatform()) {
				const userProfile = await dispatch(getUserProfile()).unwrap();
				getUserCards(userProfile.userId);
				Browser.close().catch((error) => {
					console.error(error);
				});
			}

			if (pathname.includes(ROUTER_URL.RECEIPT_MAIN)) {
				const userProfile = await dispatch(getUserProfile()).unwrap();
				getUserCards(userProfile.userId);
				await removeReceiptList();
				Browser.close().catch((error) => {
					console.error(error);
				});
			}

			await checkUserSession();

			history.replace({ pathname, search: queryString });
		} catch (error) {
			if (error === 'No active window to close!') {
				console.error('No active window to close!');
			} else if (checkIsSessionError(error)) {
				redirectNotAuthorizedUser();
			}
		}
	};

	const handleRedirect = (redirectUrl: string, query?: string): void => {
		history.replace({
			pathname: redirectUrl,
			search: query,
		});
	};

	const checkUserSession = async (): Promise<void> => {
		try {
			const queryParams = parseQueryString<any>(search);

			if (queryParams.sessionId) {
				await StorageService.set(STORAGE_KEY.SESSION_ID, queryParams.sessionId);
			}

			if (queryParams.deviceFP) {
				await StorageService.set(STORAGE_KEY.DEVICE_FP, queryParams.deviceFP);
			}

			const sessionCheckResponse = await SessionsService.checkSession();
			const profile = sessionCheckResponse?.data;

			if (profile) {
				dispatch(setUserProfile(profile));
			} else {
				await redirectNotAuthorizedUser();
			}
		} catch (error) {
			if (!checkIsSessionError(error)) {
				dispatch(showToast({ message: getErrorMessage(error) }));
			} else {
				throw error;
			}
		}
	};

	const getUserCards = async (userId: string): Promise<void> => {
		try {
			await dispatch(getCardsList(userId)).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	return content;
};

import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { RadabankLayout } from 'layouts';
import {
	NotFoundPage,
	RadabankAuthInfoApproveCodePage,
	RadabankAuthInfoBeginPage,
	RadabankAuthInfoDiyaBeginPage,
	RadabankAuthInfoDocumentsSubmissionPage,
	RadabankAuthInfoImageSetPage,
	RadabankAuthInfoSetSignPage,
	RadabankAuthInfoSignDocumentsPage,
	RadabankAuthInfoSignDocumentsSubmissionPage,
	RadabankAuthInfoSurveyLetterPage,
	RadabankAuthInternalAuthorizePage,
	RadabankAuthOtherDevicePage,
	RadabankAuthStatusPage,
	RadabankBlockCardPage,
	RadabankCardDetailsAndDocumentsPage,
	RadabankCardOperationTopUpPage,
	RadabankCardOverdraftDetailsPage,
	RadabankCardOverdraftPage,
	RadabankCardSecureSettingsPage,
	RadabankCardSmsInfoPage,
	RadabankCreateCardBannerPage,
	RadabankInternalActivatePage,
	RadabankInternalCreateCardPage,
	RadabankInternalCreateCardSubmissionPage,
	RadabankMainPage,
	RadabankOperationBannerPage,
	RadabankOperationPage,
	RadabankOrderPlasticCardPage,
	RadabankPinCodeChangePage,
	RadabankPinCodeSmsPage,
	RadabankSettingsPage,
	RadabankUnblockCardPage,
} from 'pages';
import { RadabankCardOverdraftRequestBanner } from 'components/RadabankCard';
import { ROUTER_URL } from 'utils/enums';

export const RadabankRouter: FC = () => {
	return (
		<Switch>
			<Route path={ROUTER_URL.RADABANK_CARD}>
				<RadabankLayout>
					<Switch>
						<Route exact path={ROUTER_URL.RADABANK_CARD} component={RadabankMainPage} />
						<Route exact path={ROUTER_URL.RADABANK_CARD_CREATE_CARD_BANNER} component={RadabankCreateCardBannerPage} />
						<Route exact path={ROUTER_URL.RADABANK_CARD_AUTH_INFO_BEGIN} component={RadabankAuthInfoBeginPage} />
						<Route
							exact
							path={ROUTER_URL.RADABANK_CARD_AUTH_APPROVE_CODE}
							component={RadabankAuthInfoApproveCodePage}
						/>
						<Route
							exact
							path={ROUTER_URL.RADABANK_CARD_AUTH_INFO_DIYA_BEGIN}
							component={RadabankAuthInfoDiyaBeginPage}
						/>
						<Route
							exact
							path={ROUTER_URL.RADABANK_CARD_AUTH_DOCUMENTS_SUBMISSION}
							component={RadabankAuthInfoDocumentsSubmissionPage}
						/>
						<Route
							exact
							path={ROUTER_URL.RADABANK_CARD_AUTH_INFO_IMAGE_SET_PHOTO}
							component={RadabankAuthInfoImageSetPage}
						/>
						<Route
							exact
							path={ROUTER_URL.RADABANK_CARD_AUTH_INFO_SET_ANKETA}
							component={RadabankAuthInfoSurveyLetterPage}
						/>
						<Route
							exact
							path={ROUTER_URL.RADABANK_CARD_AUTH_INFO_SIGN_DOCUMENTS}
							component={RadabankAuthInfoSignDocumentsPage}
						/>
						<Route
							exact
							path={ROUTER_URL.RADABANK_CARD_AUTH_INFO_IMAGE_SET_SIGN}
							component={RadabankAuthInfoSetSignPage}
						/>
						<Route
							exact
							path={ROUTER_URL.RADABANK_CARD_AUTH_SIGN_DOCUMENTS_SUBMISSION}
							component={RadabankAuthInfoSignDocumentsSubmissionPage}
						/>
						<Route
							exact
							path={ROUTER_URL.RADABANK_CARD_AUTH_INTERNAL_AUTHORIZE}
							component={RadabankAuthInternalAuthorizePage}
						/>
						<Route exact path={ROUTER_URL.RADABANK_CARD_INTERNAL_ACTIVATE} component={RadabankInternalActivatePage} />
						<Route
							exact
							path={ROUTER_URL.RADABANK_CARD_INTERNAL_CREATE_CARD}
							component={RadabankInternalCreateCardPage}
						/>
						<Route
							exact
							path={ROUTER_URL.RADABANK_CARD_INTERNAL_CREATE_CARD_SUBMITION}
							component={RadabankInternalCreateCardSubmissionPage}
						/>
						<Route exact path={ROUTER_URL.RADABANK_CARD_OPERATION} component={RadabankOperationPage} />
						<Route exact path={ROUTER_URL.RADABANK_CARD_OPERATION_BANNER} component={RadabankOperationBannerPage} />
						<Route exact path={ROUTER_URL.RADABANK_CARD_OPERATION_TOP_UP} component={RadabankCardOperationTopUpPage} />
						<Route exact path={ROUTER_URL.RADABANK_CARD_SETTINGS} component={RadabankSettingsPage} />
						<Route exact path={ROUTER_URL.RADABANK_CARD_ORDER} component={RadabankOrderPlasticCardPage} />
						<Route exact path={ROUTER_URL.RADABANK_CARD_CREDIT_LIMIT} component={RadabankCardOverdraftPage} />
						<Route
							exact
							path={ROUTER_URL.RADABANK_CARD_OVERDRAFT_DETAILS}
							component={RadabankCardOverdraftDetailsPage}
						/>
						<Route
							exact
							path={ROUTER_URL.RADABANK_CARD_CREDIT_LIMIT_IN_PROGRESS}
							component={RadabankCardOverdraftRequestBanner}
						/>
						<Route exact path={ROUTER_URL.RADABANK_CARD_BLOCK_CARD} component={RadabankBlockCardPage} />
						<Route exact path={ROUTER_URL.RADABANK_CARD_UNBLOCK_CARD} component={RadabankUnblockCardPage} />
						<Route exact path={ROUTER_URL.RADABANK_CARD_PIN_CODE_SMS} component={RadabankPinCodeSmsPage} />
						<Route exact path={ROUTER_URL.RADABANK_CARD_PIN_CODE_CHANGE} component={RadabankPinCodeChangePage} />
						<Route
							exact
							path={ROUTER_URL.RADABANK_CARD_DETAILS_AND_DOCUMENTS}
							component={RadabankCardDetailsAndDocumentsPage}
						/>
						<Route exact path={ROUTER_URL.RADABANK_CARD_SECURE_SETTINGS} component={RadabankCardSecureSettingsPage} />
						<Route exact path={ROUTER_URL.RADABANK_CARD_SMS_INFO} component={RadabankCardSmsInfoPage} />
						<Route exact path={ROUTER_URL.RADABANK_CARD_AUTH_STATUS} component={RadabankAuthStatusPage} />
						<Route exact path={ROUTER_URL.RADABANK_CARD_OTHER_DEVICE} component={RadabankAuthOtherDevicePage} />
						<Route path="*" component={NotFoundPage} />
					</Switch>
				</RadabankLayout>
			</Route>
		</Switch>
	);
};

import { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { BiometryErrorType } from '@aparajita/capacitor-biometric-auth';
import { Capacitor } from '@capacitor/core';
import dayjs from 'dayjs';
import { VerifyDeviceNative } from 'pages/Auth/VerifyDevice/VerifyDeviceNative';
import { VerifyDeviceWeb } from 'pages/Auth/VerifyDevice/VerifyDeviceWeb';
import { useAppDispatch } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { userLogin } from 'store/user-service/actions';
import {
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	FIREBASE_EVENT_ANALYTICS_PAGE,
	OTP_LENGTH,
	PASSWORD_LENGTH,
} from 'utils/constants';
import { CHECK_RESPONSE, ERROR_CODE, ERROR_MESSAGE, ROLE, ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import { encryptData, getErrorMessage, getErrorResponse, redirectNotAuthorizedUser } from 'utils/helpers';
import { useToastify } from 'utils/hooks/use-toastify';
import {
	BiometryService,
	FirebaseAnalytics,
	PushNotificationsService,
	StorageService,
	UserService,
} from 'utils/services';
import { IErrorResponse, UserLoginRequest } from 'utils/types';
import { passwordValidationSchema } from 'utils/validation';

export interface VerifyDeviceProps {
	password: string;
	isUserLoginSuccessed: boolean;
	otp: string;
	phone: string;
	onSubmit: () => void;
	onChangeOTP: (value: string) => void;
	onResendCode: () => Promise<void>;
	isValidOTP: boolean;
	isLoading: boolean;
	onClickBack: () => void;
	errorToast: ReactNode;
	isButtonDisabled: boolean;
}

interface VerifyDevicePageProps {
	data?: UserLoginRequest;
	onClickBack?: () => void;
}

export const VerifyDevicePage: FC<VerifyDevicePageProps> = ({ data, onClickBack }) => {
	const history = useHistory();
	const dispatch = useAppDispatch();

	const [phone, setPhone] = useState(data?.phone || '');
	const [password, setPassword] = useState(data?.password || '');
	const [passwordError, setPasswordError] = useState('');
	const [isUserLoginSuccessed, setIsUserLoginSuccessed] = useState(!!data);
	const [otp, setOtp] = useState('');
	const [isValidOTP, setIsValidOTP] = useState(true);
	const [error, setError] = useState('');
	const [touchedPassword, setTouchedPassword] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const errorToast = useToastify(!!error, error, () => setError(''));

	const isButtonDisabled = isUserLoginSuccessed
		? !isValidOTP || otp.length !== OTP_LENGTH
		: password.length !== PASSWORD_LENGTH;

	useEffect(() => {
		StorageService.get<string>(STORAGE_KEY.PHONE).then((result: string | null) => {
			if (result) {
				setPhone(result ? result.toString() : '');
			} else {
				redirectNotAuthorizedUser();
			}
		});
	}, []);

	const handleLogin = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.VERIFY_DEVICE,
				FIREBASE_EVENT_ANALYTICS_BUTTON.LOGIN
			);
			setIsLoading(true);
			if (phone) {
				const responseData = await dispatch(userLogin({ password, phone })).unwrap();
				await StorageService.set(STORAGE_KEY.USER_PASSWORD, encryptData(password));
				if (responseData.preLoginData?.deviceVerificationRequired === CHECK_RESPONSE.YES) {
					const verificationId: string | undefined = responseData.preLoginData?.deviceVerificationId;
					const storageVerificationId: string | null = await StorageService.get<string>(STORAGE_KEY.VERIFICATION_ID);
					if (verificationId !== storageVerificationId) {
						StorageService.set(STORAGE_KEY.VERIFICATION_ID, responseData.preLoginData?.deviceVerificationId);
						StorageService.set(STORAGE_KEY.VERIFICATION_ID_DATE, dayjs().toISOString());
					}
					setIsUserLoginSuccessed(true);
				} else if (responseData.profile) {
					await StorageService.set(STORAGE_KEY.IS_WAS_LOGGED_IN, true);
					history.push(ROUTER_URL.PROFILE);
				}
			}
		} catch (error) {
			const errorResponse: IErrorResponse | undefined = getErrorResponse(error);
			const message: string | undefined =
				errorResponse?.errorData?.code === ERROR_CODE.INVALID_PASSWORD_OR_NOT_FOUND
					? ERROR_MESSAGE.INVALID_PASSWORD_OR_NOT_FOUND
					: getErrorMessage(error);

			if (errorResponse?.errorData?.code === ERROR_CODE.INVALID_PASSWORD_OR_NOT_FOUND) {
				setPassword('');
			}
			setError(message);
		} finally {
			setIsLoading(false);
		}
	};

	const handleRedirectUser = async () => {
		await StorageService.set(STORAGE_KEY.IS_WAS_LOGGED_IN, true);

		if (PushNotificationsService.isEnabled === null && Capacitor.isNativePlatform()) {
			history.replace(ROUTER_URL.PUSH_NOTIFICATIONS_BANNER);
			return;
		}
		try {
			const responseData = await dispatch(userLogin({ password, phone })).unwrap();
			if (responseData.roles?.some((item) => item.name === ROLE.PUMB)) {
				history.replace(ROUTER_URL.SERVICES_CATEGORIES);
			} else {
				history.replace(ROUTER_URL.PROFILE);
			}
		} catch (error) {
			console.error(error)
		}
	};

	const handleConfirmDevice = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.VERIFY_DEVICE,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CONFIRM_DEVICE
			);
			setIsLoading(true);
			await UserService.confirmDevice({ code: otp });
			await dispatch(userLogin({ password, phone })).unwrap();
			StorageService.remove(STORAGE_KEY.VERIFICATION_ID);
			StorageService.remove(STORAGE_KEY.VERIFICATION_ID_DATE);
			if (Capacitor.isNativePlatform() && BiometryService.isAvailable) {
				await BiometryService.auth({
					androidTitle: 'Використовувати біометричну автентифікацію?',
				});
				await BiometryService.setBiometryType(BiometryService.primaryBiometryType);
			}
			handleRedirectUser();
		} catch (error) {
			const errorResponse = getErrorResponse(error);
			if (errorResponse?.errorData?.code === ERROR_CODE.DEVICE_CODE_IS_INCORRECT) {
				setIsValidOTP(false);
			} else if (error?.code === BiometryErrorType.userCancel) {
				handleRedirectUser();
			} else {
				dispatch(showToast({ message: getErrorMessage(error) }));
			}
		} finally {
			setIsLoading(false);
		}
	};

	const handleResendCode = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.VERIFY_DEVICE,
				FIREBASE_EVENT_ANALYTICS_BUTTON.RESEND_OTP
			);
			await UserService.verifyUserResend();
			StorageService.set(STORAGE_KEY.VERIFICATION_ID_DATE, dayjs().toISOString());
		} catch (error) {
			setError(getErrorMessage(error));
		}
	};

	const handleChangeOTP = (value: string): void => {
		setIsValidOTP(true);
		setOtp(value);
	};

	const handleClickBack = (): void => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.VERIFY_DEVICE,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		if (onClickBack) {
			onClickBack();
			return;
		}
		if (!isUserLoginSuccessed) {
			history.goBack();
		} else {
			setIsUserLoginSuccessed(false);
			if (Capacitor.isNativePlatform()) {
				setPassword('');
			}
		}
	};

	const validatePassword = async (value: string): Promise<void> => {
		try {
			await passwordValidationSchema.validate(value, { abortEarly: false });
			setPasswordError('');
		} catch (error) {
			setPasswordError(error.inner[0]?.message);
		}
	};

	const handleChangePassword = (event: ChangeEvent<HTMLInputElement>): void => {
		setPassword(event.target.value);
		validatePassword(event.target.value);
	};

	const handleBlur = (): void => {
		validatePassword(password);
		if (!touchedPassword) {
			setTouchedPassword(true);
		}
	};

	return !Capacitor.isNativePlatform() ? (
		<VerifyDeviceWeb
			onChangePassword={handleChangePassword}
			password={password}
			onBlur={handleBlur}
			onChangeOTP={handleChangeOTP}
			onResendCode={handleResendCode}
			onSubmit={isUserLoginSuccessed ? handleConfirmDevice : handleLogin}
			phone={phone}
			otp={otp}
			isLoading={isLoading}
			isValidOTP={isValidOTP}
			errorToast={errorToast}
			isUserLoginSuccessed={isUserLoginSuccessed}
			isError={!!passwordError && touchedPassword}
			onClickBack={handleClickBack}
			isButtonDisabled={isButtonDisabled}
		/>
	) : (
		<VerifyDeviceNative
			password={password}
			setPassword={setPassword}
			onSubmit={handleLogin}
			onSubmitOTP={handleConfirmDevice}
			isUserLoginSuccessed={isUserLoginSuccessed}
			error={error}
			onChangeOTP={handleChangeOTP}
			onResendCode={handleResendCode}
			isValidOTP={isValidOTP}
			phone={phone}
			otp={otp}
			isLoading={isLoading}
			onClickBack={handleClickBack}
			errorToast={errorToast}
			isButtonDisabled={isButtonDisabled}
		/>
	);
};

import { FC, useEffect, useMemo, useState } from 'react';
import { ReactComponent as WarningIcon } from 'assets/icons/info-circle.svg';
import { Form, Formik } from 'formik';
import {
	getRadabankCurrentCardOverdraftDataSelector,
	getRadabankCurrentCardSelector,
	getUserProfileSelector,
	openConfirmModal,
	showToast,
	updateRadabankOverdraftData,
	useAppDispatch,
	useAppSelector,
} from 'store';
import * as Yup from 'yup';
import { RadabankCardOverdraftRequestBanner } from 'components/RadabankCard';
import {
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	FIREBASE_EVENT_ANALYTICS_PAGE,
	RADABANK_CLIENT_REQUEST_OVER_INITIAL_VALUES,
} from 'utils/constants';
import { RADABANK_CLIENT_REQUEST_OVER_TYPE, VALIDATION_ERROR } from 'utils/enums';
import { convertUAHToCoins, getErrorMessage } from 'utils/helpers';
import { FirebaseAnalytics } from 'utils/services';
import { IRadabankClientRequestOverRequest, IRadabankInternalCardCurrentRestDataResponse } from 'utils/types';
import { FormContent } from './FormContent';
import styles from './index.module.scss';

export const RadabankCardOverdraftUpdate: FC = () => {
	const dispatch = useAppDispatch();

	const overdraft = useAppSelector(getRadabankCurrentCardOverdraftDataSelector);
	const currentCard = useAppSelector(getRadabankCurrentCardSelector);
	const profile = useAppSelector(getUserProfileSelector);

	const [initialValues, setInitialValues] = useState(RADABANK_CLIENT_REQUEST_OVER_INITIAL_VALUES);
	const [isShowBanner, setIsShowBanner] = useState(false);

	const radabankCardOverdraftUpdateRequestValidationSchema = useMemo(() => {
		const amountSchema = Yup.string()
			.required(VALIDATION_ERROR.REQUIRED)
			.test('is-multiple-of-1000', 'Сума має бути кратна 1000', (value) => {
				return !(+value % 1000);
			});
		let minAmountSchema: Yup.StringSchema = Yup.string();
		let maxAmountSchema: Yup.StringSchema = Yup.string();
		if (overdraft.data?.overamountmin) {
			minAmountSchema = amountSchema.concat(
				Yup.string().test('minsum', `Мінімальна сума ${overdraft.data.overamountmin}`, (value) => {
					return !!value && +value >= +(overdraft.data as IRadabankInternalCardCurrentRestDataResponse).overamountmin;
				})
			);
		}
		if (overdraft.data?.overamountmax) {
			maxAmountSchema = amountSchema.concat(
				Yup.string().test('maxsum', `Максимальна сума ${overdraft.data.overamountmax}`, (value) => {
					return !!value && +value <= +(overdraft.data as IRadabankInternalCardCurrentRestDataResponse).overamountmax;
				})
			);
		}
		return Yup.object().shape({
			amount: amountSchema.concat(minAmountSchema).concat(maxAmountSchema),
		});
	}, [overdraft.data]);

	useEffect(() => {
		if (overdraft.data && currentCard && profile) {
			setInitialValues({
				...initialValues,
				typerequest: RADABANK_CLIENT_REQUEST_OVER_TYPE.UPDATE,
				amount: overdraft.data.overdraft,
				cardid: currentCard.id,
				userId: profile.userId,
			});
		}
	}, [overdraft.data, currentCard, profile]);

	const handleUpdateOverdraft = async (values: IRadabankClientRequestOverRequest) => {
		try {
			await dispatch(
				updateRadabankOverdraftData({ ...values, amount: `${convertUAHToCoins(values.amount)}` })
			).unwrap();
			setIsShowBanner(true);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleSubmit = async (values: IRadabankClientRequestOverRequest): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_OVERDRAFT,
				FIREBASE_EVENT_ANALYTICS_BUTTON.UPDATE
			);

			if (values.typerequest === RADABANK_CLIENT_REQUEST_OVER_TYPE.CLOSE) {
				dispatch(
					openConfirmModal({
						title: 'Закрити кредитний ліміт',
						subTitle: 'Ви впевнені, що хочете закрити кредитний ліміт?',
						submitButtonLabel: 'Так, закрити',
						onSubmit: () => handleUpdateOverdraft(values),
						icon: <WarningIcon className={styles.icon} />,
					})
				);
				return;
			}

			await handleUpdateOverdraft(values);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	return isShowBanner ? (
		<RadabankCardOverdraftRequestBanner />
	) : (
		<Formik
			initialValues={initialValues}
			enableReinitialize
			onSubmit={handleSubmit}
			validationSchema={radabankCardOverdraftUpdateRequestValidationSchema}
		>
			<Form>
				<FormContent />
			</Form>
		</Formik>
	);
};

import { FC, MouseEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { IconButton, Typography } from '@mui/material';
import HostSVG from 'assets/icons/host-icon.svg';
// import { ReactComponent as PencilSVG } from 'assets/icons/pencil.svg';
import { ReactComponent as TrashSVG } from 'assets/icons/trash.svg';
import { deleteSingleReceiptOtherService } from 'store/bill/actions';
import { getBillReceiptSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeConfirmModal, openConfirmModal } from 'store/system/reducer';
import { showToast } from 'store/toastify/reducer';
import { getUserProfileSelector } from 'store/user-service/selectors';
import { Checkbox } from 'components/shared/Checkbox';
import { ProviderAccordion } from 'components/shared/ProviderAccordion';
import { ProviderPreview } from 'components/shared/ProviderPreview';
import { BILL_TYPE } from 'utils/enums';
import {
	checkIsAmountServiceField,
	convertCoinsToUAH,
	convertUAHToCoins,
	decryptData,
	getErrorMessage,
	getOtherPortmoneAmountFromTariffItems,
	getOtherProviderServicePayment,
	getPortmoneProviderPreviewData,
	getServiceTariffItemsList,
} from 'utils/helpers';
import { useDebounce, useOpen } from 'utils/hooks';
import { BillService } from 'utils/services';
import {
	IBillRequestData,
	IPayee,
	IReceiptOtherServiceResponse,
	IServiceBillRequestData,
	IServiceFieldValueResponse,
	ISingleReceiptPayProviderServiceRequest,
	ITariffItemData,
	ReceiptOtherServiceTariffItems,
} from 'utils/types';
import { ServiceTariffItem } from '../PaymentProviderItem/EditServiceAmountToPay/ServiceTariffItem';
import { EditServiceAmountToPay } from './EditServiceAmountToPay';
import { EditServiceFieldValuesModal } from './EditServiceFieldValuesModal';
import styles from './index.module.scss';

interface PaymentOtherProviderItemProps {
	data: IReceiptOtherServiceResponse;
	onChange: (serviceList: ISingleReceiptPayProviderServiceRequest[]) => void;
	onExpandChange?: (expanded: boolean) => void;
	selectedServiceList: ISingleReceiptPayProviderServiceRequest[];
	onChangeFee: (serviceId: string | number, amount: number) => void;
}

export const PaymentOtherProviderItem: FC<PaymentOtherProviderItemProps> = ({
	data,
	onChange,
	onExpandChange,
	selectedServiceList,
	onChangeFee,
}) => {
	const dispatch = useAppDispatch();

	const params = useParams<{ apartmentAccountId: string }>();

	const profile = useAppSelector(getUserProfileSelector);
	const receipt = useAppSelector(getBillReceiptSelector);

	const editModal = useOpen();

	const [amountToPay, setAmountToPay] = useState<string | null>(null);
	const [feeAmount, setFeeAmount] = useState(data.fee);
	const [tariffList, setTariffList] = useState<
		{
			tariffItems: ITariffItemData[];
			payee: IPayee;
		}[]
	>([]);

	const debouncedAmountToPay = useDebounce(amountToPay, 700);

	useEffect(() => {
		if (amountToPay !== null) {
			const payload = selectedServiceList.map((item) =>
				item.serviceId === data.id ? getOtherProviderServicePayment(data, amountToPay) : item
			);
			onChange(payload);
		}
	}, [amountToPay, data.serviceFieldsValues]);

	useEffect(() => {
		if (debouncedAmountToPay !== null && !receipt.data?.isArchived) {
			getServiceFee(+convertUAHToCoins(debouncedAmountToPay));
		}
	}, [debouncedAmountToPay, receipt.data?.isArchived]);

	useEffect(() => {
		let amountToPay: string | null = null;

		const tariffItemsAmount = getOtherPortmoneAmountFromTariffItems(
			data.tarifItems as ReceiptOtherServiceTariffItems | ReceiptOtherServiceTariffItems[]
		);
		if (tariffItemsAmount) {
			amountToPay = tariffItemsAmount.toString();
		} else {
			amountToPay = data.amount - data.payed <= 0 ? '0' : (data.amount - data.payed).toString();
		}

		setAmountToPay(amountToPay);
	}, [data.amount, data.payed, receipt.data?.isArchived]);

	useEffect(() => {
		if (data.tarifItems && data?.billType === BILL_TYPE.THIRD) {
			const isTariffItemsArray = Array.isArray(data.tarifItems);
			const payload = isTariffItemsArray
				? (data.tarifItems as ReceiptOtherServiceTariffItems[]).map((item) => ({
						tariffItems: getServiceTariffItemsList(
							(item as IBillRequestData).tariff_items as { [key: string]: ITariffItemData }
						),
						payee: (item as IBillRequestData).payee as IPayee,
				  }))
				: [
						{
							tariffItems: getServiceTariffItemsList((data.tarifItems as IBillRequestData)?.tariff_items || {}),
							payee: (data.tarifItems as IBillRequestData).payee as IPayee,
						},
				  ];
			setTariffList(payload);
		}
	}, [data.tarifItems]);

	const getServiceFee = async (amountValue: number): Promise<void> => {
		try {
			const response = await BillService.getServiceFee(amountValue, data.code);
			const feeAmount = convertCoinsToUAH(response.data.fee);
			onChangeFee(data.id, feeAmount);
			setFeeAmount(feeAmount);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleSelectService = (event: SyntheticEvent): void => {
		event.stopPropagation();

		const isItemInSelectedServiceList = selectedServiceList.find(
			(item: ISingleReceiptPayProviderServiceRequest) => item.serviceId === data.id
		);
		if (amountToPay !== null) {
			const payload = isItemInSelectedServiceList
				? selectedServiceList.filter((item) => item.serviceId !== data.id)
				: [...selectedServiceList, getOtherProviderServicePayment(data, amountToPay)];
			onChange(payload);
		}
	};

	const handleChangeService = (amountToPay: string): void => {
		setAmountToPay(amountToPay);
	};

	const handleDeleteService = async (): Promise<void> => {
		try {
			if (profile) {
				await dispatch(
					deleteSingleReceiptOtherService({
						fieldsRequestData: data.serviceFieldsValues.map((item) => ({ id: item.id })),
						serviceRequestData: {
							apartmentAccountId: +decryptData(params.apartmentAccountId),
							serviceId: data.id,
							userId: profile.userId,
						},
					})
				).unwrap();
				onChange(selectedServiceList.filter((item) => item.serviceId !== data.id));
				dispatch(closeConfirmModal());
				dispatch(showToast({ message: 'Сервіс успішно видалено з ЄК', color: 'success' }));
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleClickDelete = (event: MouseEvent): void => {
		event.stopPropagation();
		dispatch(
			openConfirmModal({
				title: 'Видалити сервіс',
				subTitle: `Ви впевнені, що бажаєте видалити ${data.title ?? data.name} з інших платежів?`,
				icon: <TrashSVG />,
				submitButtonLabel: 'Так, видалити',
				onSubmit: handleDeleteService,
			})
		);
	};

	// const handleClickEdit = (event: MouseEvent): void => {
	// 	event.stopPropagation();
	// 	editModal.handleOpen();
	// };

	return (
		<>
			<ProviderAccordion
				title={
					<div className={styles.header}>
						<ProviderPreview
							className={styles.header__info}
							title={data.title ?? data.name}
							description={getPortmoneProviderPreviewData(data)}
							icon={data.categoryImage ?? data.imageUrl ?? HostSVG}
							code={data.code}
							providerType={data.provider}
							data={data}
						/>
						<div className={styles.header__actions}>
							<IconButton onClick={handleClickDelete}>
								<TrashSVG className={styles.icon__delete} />
							</IconButton>
							{/* https://jira.hostpay.tech/browse/HBILL-376 */}
							{/* <IconButton onClick={handleClickEdit}>
										<PencilSVG className={styles.icon__edit} />
									</IconButton> */}
							{!receipt.data?.isArchived && (
								<Checkbox
									disableRipple
									onClickCapture={handleSelectService}
									id={`${data.id}${data.code}`}
									checked={!!selectedServiceList.find((item) => item.serviceId === data.id)}
									disabled={receipt.isLoading || receipt.data?.isArchived}
								/>
							)}
						</div>
					</div>
				}
				onChange={(_, expanded: boolean) => onExpandChange?.(expanded)}
			>
				{/* eslint-disable-next-line no-nested-ternary */}
				{data.billType === BILL_TYPE.FIRST ? (
					data.serviceFieldsValues
						.filter((item: IServiceFieldValueResponse) => checkIsAmountServiceField(item))
						.map((item: IServiceFieldValueResponse) => (
							<EditServiceAmountToPay
								key={item.id}
								fieldName={item.name}
								serviceData={data}
								onChange={handleChangeService}
								value={amountToPay ?? '0'}
								feeAmount={feeAmount}
								isDisabled={data.valuesDisabled || receipt.isLoading || !!receipt.data?.isArchived}
							/>
						))
				) : //  eslint-disable-next-line no-nested-ternary
				data.billType === BILL_TYPE.SECOND ? (
					// eslint-disable-next-line no-nested-ternary
					(data.tarifItems as IServiceBillRequestData)?.error ? (
						<Typography variant="subtitle2" fontWeight="normal">
							{(data.tarifItems as IServiceBillRequestData).error as string}
						</Typography>
					) : Object.keys((data.tarifItems as IBillRequestData)?.tariff_items)?.length ? (
						<div className={styles.tariff__list}>
							{getServiceTariffItemsList((data.tarifItems as IBillRequestData).tariff_items).map((item, index) => (
								<ServiceTariffItem key={`tarif-list-portmone-${data.code}-${item.name}-${index}`} data={item} />
							))}
						</div>
					) : (
						<EditServiceAmountToPay
							fieldName="amount"
							serviceData={data}
							onChange={handleChangeService}
							value={amountToPay ?? '0'}
							feeAmount={feeAmount}
							isDisabled={data.valuesDisabled || receipt.isLoading || !!receipt.data?.isArchived}
						/>
					)
				) : (data.tarifItems as ReceiptOtherServiceTariffItems | undefined)?.error ||
				  (Array.isArray(data.tarifItems) && data.tarifItems.every((item) => !!item.error)) ? (
					<Typography variant="subtitle2" fontWeight="normal">
						{(data.tarifItems as any)[0]?.error ??
							((data.tarifItems as ReceiptOtherServiceTariffItems).error as string)}
					</Typography>
				) : (
					<div className={styles.tariff__list}>
						{tariffList.map((tariffItem, index) => (
							<div key={`${tariffItem.payee._}-${index}`}>
								<Typography className={styles.title} variant="subtitle2" marginBottom="var(--spacing-2)">
									{tariffItem.payee._}
								</Typography>
								{tariffItem.tariffItems.map((item) => (
									<ServiceTariffItem
										key={`tarif-list-portmone-${data.code}-${tariffItem.payee._}-${item.name}`}
										data={item}
									/>
								))}
							</div>
						))}
					</div>
				)}
			</ProviderAccordion>
			<EditServiceFieldValuesModal isOpen={editModal.isOpen} onClose={editModal.handleClose} data={data} />
		</>
	);
};

import { FC } from 'react';
import { useHistory } from 'react-router';
import {
	createRadabankTransferPayment,
	getRadabankCurrentCardSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import {
	RadabankOperationDetailInfo,
	RadabankOperationDetailRow,
	RadabankSectionHeader,
} from 'components/RadabankCard';
import { CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import { getAmountTitle, getErrorMessage } from 'utils/helpers';
import { FirebaseAnalytics } from 'utils/services';
import { IRadabankBudgetCreatePaymentRequest, IRadabankTransferFeeResponse } from 'utils/types';
import styles from './index.module.scss';

interface OperationDetailsProps {
	feeData: IRadabankTransferFeeResponse;
	budgetPaymentData: IRadabankBudgetCreatePaymentRequest;
	onClickBack: () => void;
}

export const OperationDetails: FC<OperationDetailsProps> = ({ feeData, budgetPaymentData, onClickBack }) => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const cardData = useAppSelector(getRadabankCurrentCardSelector);

	const handleCreatePayment = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.OPERATION_TO_BUDGET_DETAILS,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CREATE_PAYMENT
			);
			dispatch(setAppLoading(true));
			await dispatch(createRadabankTransferPayment(budgetPaymentData)).unwrap();
			history.push(ROUTER_URL.RADABANK_CARD_OPERATION_BANNER);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.OPERATION_TO_BUDGET_DETAILS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		onClickBack();
	};

	return (
		<div className="host-card-page-color-white">
			<RadabankSectionHeader title="На інші картки або рахунки" onClickBack={handleGoBack} />
			<div className={styles.content}>
				<RadabankOperationDetailRow title="IBAN" description={budgetPaymentData.ibanrec} />
				<RadabankOperationDetailRow title="ЄДРПОУ" description={budgetPaymentData.innrec} />
				<RadabankOperationDetailRow title="Код виду сплати" description={budgetPaymentData.budgetcode} />
				<RadabankOperationDetailRow title="Додаткова інформація запису" description={budgetPaymentData.budgetadd} />
				<RadabankOperationDetailRow title="Отримувач" description={feeData.message1 || budgetPaymentData.namerec} />
				{cardData && (
					<>
						<RadabankOperationDetailRow
							title="Сума"
							description={getAmountTitle(budgetPaymentData.amount, cardData.curriso)}
						/>
						<RadabankOperationDetailRow
							title="Комісія"
							description={getAmountTitle(feeData.clientfee, cardData.curriso)}
							isShowInfo={!!feeData.message2}
						/>
					</>
				)}
			</div>
			{!!feeData.message2 && <RadabankOperationDetailInfo description={feeData.message2} />}
			<CustomButton label="Сплатити" onClick={handleCreatePayment} />
		</div>
	);
};

import { FC, useEffect, useState } from 'react';
import {
	getAppLoadingSelector,
	getRadabankCurrentCardOverdraftDataSelector,
	getRadabankCurrentCardSelector,
	getRadabankOverdraftData,
	getUserProfileSelector,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import {
	RadabankCardOverdarftProgress,
	RadabankCardOverdraftCreate,
	RadabankCardOverdraftUpdate,
	RadabankSectionHeader,
} from 'components/RadabankCard';
import { Loader } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { RADABANK_BOOLEAN_VALUE } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics } from 'utils/services';
import styles from './index.module.scss';

export const RadabankCardOverdraftPage: FC = () => {
	const handleClickBack = useGoBackRadabank();

	const dispatch = useAppDispatch();

	const currentCard = useAppSelector(getRadabankCurrentCardSelector);
	const profile = useAppSelector(getUserProfileSelector);
	const overdraft = useAppSelector(getRadabankCurrentCardOverdraftDataSelector);
	const isAppLoading = useAppSelector(getAppLoadingSelector);
	const [isShowSprData, setIsShowSprData] = useState(false);

	const [title, setTitle] = useState('');

	useEffect(() => {
		if (currentCard && profile) {
			let title = '';
			if (currentCard.isupdatelimit === RADABANK_BOOLEAN_VALUE.TRUE) {
				title = 'Керування лімітом';
			} else if (currentCard.iscreatelimit === RADABANK_BOOLEAN_VALUE.TRUE) {
				title = 'Встановлення ліміту';
			}

			setTitle(title);

			handleGetCardCurrentRestData(profile.userId, currentCard.id);
		}
	}, [currentCard, profile]);

	const handleGetCardCurrentRestData = async (userId: string, cardid: string): Promise<void> => {
		try {
			await dispatch(getRadabankOverdraftData({ cardid, userId })).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleClickBackButton = () => {
		if (isShowSprData) {
			setIsShowSprData(false);
		} else {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_OVERDRAFT,
				FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
			);
			handleClickBack();
		}
	};

	return (
		<div className="page">
			<RadabankSectionHeader title={title} onClickBack={handleClickBackButton} />
			{!!overdraft.data && !!currentCard && (
				<div className={styles.content}>
					<RadabankCardOverdarftProgress />
					{currentCard.isupdatelimit === RADABANK_BOOLEAN_VALUE.TRUE ? (
						<RadabankCardOverdraftUpdate />
					) : (
						<RadabankCardOverdraftCreate
							isShowSprData={isShowSprData}
							onChangeShowSprData={(value: boolean) => setIsShowSprData(value)}
						/>
					)}
				</div>
			)}
			{!isAppLoading && overdraft.isLoading && <Loader />}
		</div>
	);
};

import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import {
	getRadabankCurrentCardSelector,
	getUserProfileSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { RadabankOnboardingContent } from 'components/RadabankCard';
import { ArrowBackButton, CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { RADABANK_NPOSHTA_CARD_STATUS, ROUTER_URL } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics, RadabankService } from 'utils/services';
import { IUserProfileResponse } from 'utils/types';
import styles from './index.module.scss';

interface RadabankOrderPlasticCardStatusContentProps {
	statusId: string;
	onUpdateStatus: () => void;
}

export const RadabankOrderPlasticCardStatusContent: FC<RadabankOrderPlasticCardStatusContentProps> = ({
	statusId,
	onUpdateStatus,
}) => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const handleClickBack = useGoBackRadabank();

	const currentCard = useAppSelector(getRadabankCurrentCardSelector);
	const profile = useAppSelector(getUserProfileSelector);

	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');

	useEffect(() => {
		handleInit(statusId);
	}, [statusId]);

	const handleInit = (statusId: string) => {
		let title = '';
		let description = '';
		switch (statusId) {
			case RADABANK_NPOSHTA_CARD_STATUS.DISABLED:
				title = 'Перевипуск неможливий';
				description = 'Зверніться у підтримку';
				break;

			case RADABANK_NPOSHTA_CARD_STATUS.IN_PROGRESS:
				title = 'Картка виготовляється';
				description = 'Очікуйте на повідомлення';
				break;

			case RADABANK_NPOSHTA_CARD_STATUS.DELIVERING:
				title = 'Картка вже прямує до вас';
				description = 'Перевірте додаток Нової Пошти';
				break;

			case RADABANK_NPOSHTA_CARD_STATUS.CAN_ACTIVATE:
				title = 'Картка отримана!';
				description = 'Тепер ви можете її активувати';
				break;

			case RADABANK_NPOSHTA_CARD_STATUS.ACTIVATED:
				title = 'Картку скоро буде активовано';
				description = 'Процес активації може зайняти деякий час';
				break;

			default:
				break;
		}
		setTitle(title);
		setDescription(description);
	};

	const handleClickHome = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.ORDER_PLASTIC_CARD_STATUS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_HOME
		);
		history.push(ROUTER_URL.RADABANK_CARD);
	};

	const handleClickActivate = async () => {
		try {
			if (currentCard) {
				dispatch(setAppLoading(true));
				await RadabankService.activateNPoshtaInternalCard({
					cardid: currentCard.id,
					userId: (profile as IUserProfileResponse).userId,
				});
				await onUpdateStatus();
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.ORDER_PLASTIC_CARD_STATUS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};


	return (
		<div className="host-card-page-color-primary">
			<ArrowBackButton onClick={handleGoBack} color="white" />
			<RadabankOnboardingContent title={title} description={description} />
			{statusId === RADABANK_NPOSHTA_CARD_STATUS.CAN_ACTIVATE && (
				<CustomButton label="Активувати" color="clear" onClick={handleClickActivate} />
			)}
			<CustomButton
				className={classNames(styles.button_main, {
					[styles.button_main_margin]: statusId === RADABANK_NPOSHTA_CARD_STATUS.CAN_ACTIVATE,
				})}
				label="На головну"
				color="secondary"
				onClick={handleClickHome}
			/>
		</div>
	);
};

import { FC, ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { IonSpinner } from '@ionic/react';
import { Typography } from '@mui/material';
import { ReactComponent as Host } from 'assets/images/host-logo.svg';
import classNames from 'classnames';
import { CustomButton } from 'components/shared';
import { StatusIcon } from 'components/StatusIcon';
import { ROUTER_URL } from 'utils/enums';
import { getAmountTitle } from 'utils/helpers';
import { getIsPumbUserAccessSelector, useAppSelector } from '../../store';
import styles from './index.module.scss';

interface PaymentResultProps {
	isLoading: boolean;
	description: string;
	rejectedUrl: string;
	archiveUrl: string;
	totalAmount: number;
	isSuccess: boolean;
	isOnlySingleReceipt?: boolean;
}

const PaymentResult: FC<PaymentResultProps> = ({
	isSuccess,
	totalAmount,
	isLoading,
	description,
	rejectedUrl,
	archiveUrl,
	isOnlySingleReceipt = false,
}) => {
	const history = useHistory();
	const isUserPUMB = useAppSelector(getIsPumbUserAccessSelector);

	const [content, setContent] = useState<{
		title: string | ReactNode;
		description: string;
		icon: null | ReactNode;
	}>({
		title: 'Оплата в опрацюванні',
		description: '',
		icon: null,
	});

	useEffect(() => {
		let payload = { ...content };
		if (!isLoading) {
			if (isSuccess && totalAmount) {
				const titleText = isOnlySingleReceipt ? 'Сплачено успішно' : 'Платіж прийнято в обробку';
				payload = {
					title: isOnlySingleReceipt ? (
						<Typography
							className={styles.title_payed}
							variant="h5"
							marginTop="var(--spacing-7)"
							textAlign="center"
							component="div"
						>
							{`${titleText} `}
							<Typography variant="h5" textAlign="center" whiteSpace="nowrap">
								{getAmountTitle(totalAmount)}
							</Typography>
						</Typography>
					) : (
						titleText
					),
					description: isOnlySingleReceipt ? description : 'Квитанція в Архіві платежів',
					icon: <StatusIcon variant="success" />,
				};
			}

			if (!isSuccess) {
				payload = {
					title: 'Оплата не була здійснена',
					description: 'Спробуйте повторити платіж, щоб продовжити',
					icon: <StatusIcon variant="error" />,
				};
			}
		}
		setContent(payload);
	}, [isSuccess, description, totalAmount, isOnlySingleReceipt, isLoading]);

	// const getContentByStatus = (
	// 	isSuccess: boolean,
	// 	totalAmount: number,
	// 	description: string,
	// 	isOnlySingleReceipt: boolean
	// ): void => {};

	const handleClickConfirm = (): void => {
		if (isSuccess) {
			isUserPUMB ? history.push(ROUTER_URL.SERVICES_CATEGORIES) : history.push(ROUTER_URL.SERVICES_CATEGORIES);
		} else {
			history.push(rejectedUrl);
		}
	};

	const handleClickRedirectToArchive = (): void => {
		history.push(archiveUrl);
	};

	const handleGoToMainPage = () => {
		history.push(isUserPUMB ? ROUTER_URL.PROFILE : ROUTER_URL.SERVICES_CATEGORIES);
	};

	return (
		<div className={styles.payment}>
			<Link className={styles.payment__logo} to={ROUTER_URL.PROFILE}>
				<Host />
			</Link>
			<div className={styles.payment__content}>
				{!!content.icon && (
					<div
						className={classNames(styles.payment__icon, {
							[styles.payment__icon__success]: isSuccess,
							[styles.payment__icon__failed]: !isSuccess,
						})}
					>
						{content.icon}
					</div>
				)}
				{typeof content.title === 'string' ? (
					<Typography variant="h5" marginTop="var(--spacing-7)" textAlign="center">
						{content.title}
					</Typography>
				) : (
					content.title
				)}

				{!!content.description && (
					<Typography variant="body1" color="--color-neutral-500" marginTop="var(--spacing-3)" textAlign="center">
						{content.description}
					</Typography>
				)}

				{isLoading && <IonSpinner className={styles.loader} name="dots" />}

				<div className={styles.payment__actions}>
					{totalAmount ? (
						<>
							<CustomButton
								onClick={handleClickConfirm}
								label={isSuccess ? 'Повернутись на Головну' : 'Спробувати повторно'}
							/>
							<CustomButton fill="clear" onClick={handleClickRedirectToArchive} label="Архів платежів" />
						</>
					) : (
						!isLoading && <CustomButton onClick={handleGoToMainPage} label="Повернутись на Головну" />
					)}
				</div>
			</div>
		</div>
	);
};

export default PaymentResult;

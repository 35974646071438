import { FC, HTMLProps, PropsWithChildren, useEffect, useState } from 'react';
import { LinearProgress, Typography } from '@mui/material';
import { ReactComponent as InfoIcon } from 'assets/icons/info-circle.svg';
import classNames from 'classnames';
import { CustomButton, CustomModal } from 'components/shared';
import { useOpen } from 'utils/hooks';
import styles from './index.module.scss';

interface RadabankProgressProps extends HTMLProps<HTMLDivElement> {
	label: string;
	from: string;
	to: string;
	value: number;
}

export const RadabankProgress: FC<PropsWithChildren<RadabankProgressProps>> = ({
	label,
	from,
	to,
	value,
	className,
	children,
	...rest
}) => {
	const [progress, setProgress] = useState(0);
	const { isOpen, handleClose, handleOpen } = useOpen();

	useEffect(() => {
		const duration = 50;
		const intervalTime = 5;
		const steps = duration / intervalTime;
		const increment = value / steps;

		const interval = setInterval(() => {
			setProgress((prevProgress) => {
				const newProgress = prevProgress + increment;
				if (newProgress >= value) {
					clearInterval(interval);
					return value;
				}
				return newProgress;
			});
		}, intervalTime);

		return () => clearInterval(interval);
	}, [value]);

	return (
		<>
			<div {...rest} className={classNames(styles.wrapper, className)}>
				<div className={styles.row}>
					<Typography>{label}</Typography>
					{!!children && <InfoIcon onClick={handleOpen} />}
				</div>
				<LinearProgress className={styles.progress} variant="determinate" value={progress} />
				<div className={styles.range}>
					<Typography variant="caption" color="var(--color-neutral-500)" textAlign="left">
						{from}
					</Typography>
					<Typography variant="caption" color="var(--color-neutral-500)" textAlign="right">
						{to}
					</Typography>
				</div>
			</div>
			<CustomModal isCloseHeader={false} isOpen={isOpen} onClose={handleClose} isMobileView={false}>
				{children}
				<CustomButton onClick={handleClose} label='Зрозуміло' />
			</CustomModal>
		</>
	);
};

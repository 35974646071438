import { FC } from 'react';
import { Checkbox, FormControlLabel, FormControlLabelProps } from '@mui/material';

interface RadabankSubmissionCheckboxProps extends Omit<FormControlLabelProps, 'onChange' | 'control'> {
	onChange: (checked: boolean) => void;
}

export const RadabankSubmissionCheckbox: FC<RadabankSubmissionCheckboxProps> = ({
	checked,
	onChange,
	disabled,
	label,
	...rest
}) => (
	<FormControlLabel
		{...rest}
		label={label}
		control={<Checkbox checked={checked} onChange={() => onChange(!checked)} disabled={disabled} />}
		componentsProps={{
			typography: {
				color: 'var(--color-neutral-600)',
				variant: 'body2',
			},
		}}
	/>
);

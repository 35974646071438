import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { ReactComponent as Logo } from 'assets/icons/host-icon.svg';
import {
	getAppLoadingSelector,
	getRadabankCurrentCardOverdraftDataSelector,
	getRadabankCurrentCardSelector,
	getRadabankOverdraftData,
	getUserIdSelector,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { RadabankSectionHeader } from 'components/RadabankCard';
import {
	RadabankCardOverdarftPaymentDetails,
	RadabankCardOverdraftInformation,
} from 'components/RadabankCard/CardOverdraftDetails';
import { CustomButton, Loader } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { RADABANK_BOOLEAN_VALUE, RADABANK_CARD_BLOCK_LEVEL, ROUTER_URL } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics } from 'utils/services';
import styles from './index.module.scss';

export const RadabankCardOverdraftDetailsPage: FC = () => {
	const history = useHistory();
	const handleClickBack = useGoBackRadabank();

	const dispatch = useAppDispatch();

	const cardData = useAppSelector(getRadabankCurrentCardSelector);
	const userId = useAppSelector(getUserIdSelector);
	const overdraft = useAppSelector(getRadabankCurrentCardOverdraftDataSelector);
	const isAppLoading = useAppSelector(getAppLoadingSelector);

	const isCardBlocked = cardData?.blocklevel === RADABANK_CARD_BLOCK_LEVEL.FREEZED;

	const handleGetCreditLimitDetails = async (userId: string, cardid: string): Promise<void> => {
		try {
			await dispatch(getRadabankOverdraftData({ cardid, userId })).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	useEffect(() => {
		if (cardData && userId) {
			handleGetCreditLimitDetails(userId, cardData.id);
		}
	}, [userId, cardData?.id]);

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_OVERDRAFT_DETAILS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	const handleGoToCreditLimitPage = () => {
		let path = ROUTER_URL.RADABANK_CARD_CREDIT_LIMIT;

		if (
			cardData &&
			cardData.isrequestactive !== RADABANK_BOOLEAN_VALUE.FALSE &&
			cardData.blocklevel !== RADABANK_CARD_BLOCK_LEVEL.FREEZED
		) {
			path = ROUTER_URL.RADABANK_CARD_CREDIT_LIMIT_IN_PROGRESS;
		}

		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_OVERDRAFT_DETAILS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.CREDIT_LIMIT
		);
		history.replace(path);
	};

	return (
		<div className="page">
			<RadabankSectionHeader title="Кредитний ліміт" onClickBack={handleGoBack} />
			{!!overdraft.data && (
				<>
					<div className={styles.content}>
						<Logo className={styles.logo} />
						<div className={styles.details}>
							<RadabankCardOverdraftInformation />
							<RadabankCardOverdarftPaymentDetails />
						</div>
					</div>
					{!isCardBlocked && <CustomButton onClick={handleGoToCreditLimitPage} label="Керування кредитним лімітом" />}
				</>
			)}
			{!isAppLoading && overdraft.isLoading && <Loader />}
		</div>
	);
};

import { FC } from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as MastercardSVG } from 'assets/icons/mastercard.svg';
import { ReactComponent as VisaSVG } from 'assets/icons/visa.svg';
import { ICardListItemResponse } from 'utils/types';
import styles from './index.module.scss';

interface CardPreviewProps {
	data: ICardListItemResponse;
}

export const CardPreview: FC<CardPreviewProps> = ({ data }) => {
	return (
		<div className={styles.content}>
			<div className={styles.content__icon}>{data.panMasked.slice(0, 1) === '4' ? <VisaSVG /> : <MastercardSVG />}</div>
			<div className={styles.content__description}>
				<Typography variant="subtitle1">** {data.panMasked.split('*')[1]}</Typography>
				<Typography variant="body2">{data.cardName}</Typography>
			</div>
		</div>
	);
};

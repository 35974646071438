import { FC } from 'react';
import { MenuItem, MenuList, Typography } from '@mui/material';
import classNames from 'classnames';
import { getApartmentsSelector } from 'store/bill/selectors';
import { useAppSelector } from 'store/hooks';
import { ADD_APARTMENT_VARIANT_LIST, APARTMENTS_MAX_COUNT, FIREBASE_EVENT_ANALYTICS_BUTTON } from 'utils/constants';
import { ADD_APARTMENT_VARIANT } from 'utils/enums';
import { FirebaseAnalytics } from 'utils/services';
import styles from './index.module.scss';

interface AddApartmentVariantProps {
	onChange: (value: ADD_APARTMENT_VARIANT) => void;
	value: ADD_APARTMENT_VARIANT | null;
	pageName: string;
}

export const AddApartmentVariant: FC<AddApartmentVariantProps> = ({ onChange, value, pageName }) => {
	const aparments = useAppSelector(getApartmentsSelector);

	const handleChange = (value: ADD_APARTMENT_VARIANT): void => {
		let buttonName = FIREBASE_EVENT_ANALYTICS_BUTTON.APARTMENT_VARIANT_BY_ADDRESS;

		switch (value) {
			case ADD_APARTMENT_VARIANT.APARTMENT_ACCOUNT_ID:
				buttonName = FIREBASE_EVENT_ANALYTICS_BUTTON.APARTMENT_VARIANT_BY_HOST_ID;
				break;

			case ADD_APARTMENT_VARIANT.PROVIDER_ACCOUNT_ID:
				buttonName = FIREBASE_EVENT_ANALYTICS_BUTTON.APARTMENT_VARIANT_BY_PROVIDER_ACCOUNT_ID;
				break;

			default:
				break;
		}

		FirebaseAnalytics.logEvent(pageName, buttonName);
		onChange(value);
	};

	return (
		<>
			<Typography variant="h5">Додайте помешкання одним із наступних способів:</Typography>
			<MenuList className={styles.list}>
				{ADD_APARTMENT_VARIANT_LIST.map((item) => (
					<MenuItem
						key={item.value}
						className={classNames(styles.list__item, {
							[styles.list__item_active]: item.value === value,
						})}
						onClick={() => handleChange(item.value)}
						disabled={aparments.list.length === APARTMENTS_MAX_COUNT}
					>
						<div className={styles.icon}>
							<img src={item.icon} alt="" />
						</div>
						<Typography variant="subtitle1">{item.title}</Typography>
					</MenuItem>
				))}
			</MenuList>
		</>
	);
};

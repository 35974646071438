/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
	DATE_FORMAT,
	RADABANK_BOOLEAN_VALUE,
	RADABANK_CLIENT_REQUEST_OVER_TYPE,
	RADABANK_PAYMENT_STATUS,
	RADABANK_SMS_TYPE_ID,
} from 'utils/enums';
import { convertDateFormat, removeCodeFormPhone } from 'utils/helpers';
import { IRadabankInternalOperation, IRadabankReducer } from 'utils/types';
import {
	checkRadabankPlasticCardStatus,
	createRadabankPayment,
	createRadabankTransferPayment,
	deleteRadabankCardCurrentRule,
	getRadabankCardCurrentRules,
	getRadabankCardSmsPhonesList,
	getRadabankInfoSprData,
	getRadabankInfoStatus,
	getRadabankInternalCardsList,
	getRadabankInternalClientData,
	getRadabankInternalOperationsList,
	getRadabankOverdraftData,
	getRadabankStatementsByPeriod,
	getRadabankStatusPaymentById,
	setRadabankCardSmsPhone,
	updateRadabankOverdraftData,
	verifyCobrandCard,
	verifyInternalActivateCode,
} from './actions';
import {
	resetRadabankReducerOperation,
	resetRadabankReducerOperationsOperation,
	resetRadabankStatementsOperation,
	updateInfoStatusIdRadabankReducerOperation,
} from './operations';

export const radabankInitialState: IRadabankReducer = {
	radabankInternalClient: { data: null, isLoading: true },
	cards: { list: [], isLoading: false },
	currentCard: {
		id: '',
		operations: {
			list: [],
			count: 0,
			isLoading: false,
		},
		statement: {},
		overdraftData: { data: null, isLoading: false },
		currentRules: { list: [], isLoading: false },
		paymentsRequestIdList: [],
		smsInfo: { list: [], isLoading: false },
		plasticCardStatus: null,
	},
	infoStatus: null,
	infoSprData: {
		isLoading: false,
		data: null,
	},
};

const radabankCardStore = createSlice({
	name: 'radabankCardSlice',
	initialState: radabankInitialState,
	reducers: {
		resetRadabankReducer: resetRadabankReducerOperation,
		updateInfoStatusIdRadabankReducer: updateInfoStatusIdRadabankReducerOperation,
		resetRadabankReducerOperations: resetRadabankReducerOperationsOperation,
		resetRadabankStatements: resetRadabankStatementsOperation,
	},
	extraReducers: (builder) => {
		builder.addCase(getRadabankInternalClientData.pending, (state) => {
			state.radabankInternalClient.isLoading = true;
		});
		builder.addCase(getRadabankInternalClientData.fulfilled, (state, { payload }) => {
			state.radabankInternalClient.data = payload;
			state.radabankInternalClient.isLoading = false;
		});
		builder.addCase(getRadabankInternalClientData.rejected, (state) => {
			state.radabankInternalClient.isLoading = true;
		});

		builder.addCase(getRadabankInternalCardsList.pending, (state) => {
			state.cards.isLoading = true;
		});
		builder.addCase(getRadabankInternalCardsList.fulfilled, (state, { payload }) => {
			state.cards.list = payload.cards;
			state.currentCard = { ...state.currentCard, id: payload.cards?.[0]?.id };
			state.cards.isLoading = false;
		});
		builder.addCase(getRadabankInternalCardsList.rejected, (state) => {
			state.cards.isLoading = false;
		});

		builder.addCase(getRadabankInternalOperationsList.pending, (state) => {
			state.currentCard.operations.isLoading = true;
		});
		builder.addCase(getRadabankInternalOperationsList.fulfilled, (state, { payload }) => {
			const groupedOperationsByDate = [
				...new Set(
					payload.opers.map((item) =>
						convertDateFormat(item.dateonline, DATE_FORMAT.RADABANK_TOKEN_DATE).format(DATE_FORMAT.RADABANK_REQUEST)
					)
				),
			]
				.sort((a, b) =>
					convertDateFormat(b, DATE_FORMAT.RADABANK_REQUEST).diff(convertDateFormat(a, DATE_FORMAT.RADABANK_REQUEST))
				)
				.reduce(
					(acc, item) => [
						...acc,
						payload.opers.filter(
							(operation) =>
								convertDateFormat(operation.dateonline, DATE_FORMAT.RADABANK_TOKEN_DATE).format(
									DATE_FORMAT.RADABANK_REQUEST
								) === item
						),
					],
					[] as IRadabankInternalOperation[][]
				);
			// check is value should be updated by date response in a list, not to added
			const currentOpetationsList = [...state.currentCard.operations.list];
			const payloadCurrentDayOperationsIndex = currentOpetationsList.findIndex(
				(item) =>
					groupedOperationsByDate.length &&
					convertDateFormat(item[0]?.dateapprove, DATE_FORMAT.RADABANK_REQUEST).format(DATE_FORMAT.RADABANK_REQUEST) ===
						convertDateFormat(groupedOperationsByDate[0][0]?.dateapprove, DATE_FORMAT.RADABANK_REQUEST).format(
							DATE_FORMAT.RADABANK_REQUEST
						)
			);
			if (payloadCurrentDayOperationsIndex !== -1) {
				state.currentCard.operations.list = currentOpetationsList.map((item, index) =>
					index === payloadCurrentDayOperationsIndex ? groupedOperationsByDate[0] : item
				);
			} else {
				const lastOperationInStoreDate = currentOpetationsList.length
					? currentOpetationsList[currentOpetationsList.length - 1][0]?.dateonline
					: null;

				const firstOperationFromPayloadDate = groupedOperationsByDate.length
					? groupedOperationsByDate[0][0]?.dateonline
					: null;

				const isHistoryData =
					(firstOperationFromPayloadDate &&
						lastOperationInStoreDate &&
						convertDateFormat(firstOperationFromPayloadDate, DATE_FORMAT.RADABANK_TOKEN_DATE).isBefore(
							convertDateFormat(lastOperationInStoreDate, DATE_FORMAT.RADABANK_TOKEN_DATE)
						)) ||
					(!firstOperationFromPayloadDate && !lastOperationInStoreDate);

				state.currentCard.operations.list = isHistoryData
					? [...currentOpetationsList, ...groupedOperationsByDate]
					: [...groupedOperationsByDate, ...currentOpetationsList];

				state.currentCard.operations.count = payload.count;
			}
			state.currentCard.operations.isLoading = false;
		});
		builder.addCase(getRadabankStatementsByPeriod.fulfilled, (state, { payload }) => {
			const statementOperations = payload.opers.reduce((acc, curr) => {
				const formattedDateOfOperation = convertDateFormat(curr.dateonline, DATE_FORMAT.RADABANK_TOKEN_DATE).format(
					DATE_FORMAT.RADABANK_REQUEST
				);
				return {
					...acc,
					[formattedDateOfOperation]: acc[formattedDateOfOperation] ? [...acc[formattedDateOfOperation], curr] : [curr],
				};
			}, {} as Record<string, IRadabankInternalOperation[]>);
			state.currentCard.statement = statementOperations;
		});
		builder.addCase(getRadabankInternalOperationsList.rejected, (state) => {
			state.currentCard.operations.isLoading = false;
		});

		builder.addCase(createRadabankPayment.fulfilled, (state, { payload }) => {
			state.currentCard.paymentsRequestIdList.push(payload);
		});

		builder.addCase(createRadabankTransferPayment.fulfilled, (state, { payload }) => {
			state.currentCard.paymentsRequestIdList.push(payload);
		});

		builder.addCase(getRadabankStatusPaymentById.fulfilled, (state, { payload }) => {
			if (payload.status === RADABANK_PAYMENT_STATUS.SUCCESS || payload.status === RADABANK_PAYMENT_STATUS.REJECTED) {
				state.currentCard.paymentsRequestIdList = state.currentCard.paymentsRequestIdList.filter(
					(item) => item !== payload.requestid
				);
			}
		});

		builder.addCase(getRadabankOverdraftData.pending, (state) => {
			state.currentCard.overdraftData.isLoading = true;
		});
		builder.addCase(getRadabankOverdraftData.fulfilled, (state, { payload }) => {
			state.currentCard.overdraftData.data = payload;
			state.currentCard.overdraftData.isLoading = false;
		});
		builder.addCase(getRadabankOverdraftData.rejected, (state) => {
			state.currentCard.overdraftData.isLoading = false;
		});

		builder.addCase(updateRadabankOverdraftData.pending, (state) => {
			state.currentCard.overdraftData.isLoading = true;
		});
		builder.addCase(updateRadabankOverdraftData.fulfilled, (state, { payload }) => {
			if (state.currentCard.overdraftData.data) {
				if (payload.typerequest === RADABANK_CLIENT_REQUEST_OVER_TYPE.CLOSE) {
					state.currentCard.overdraftData.data = null;
				} else {
					state.currentCard.overdraftData.data.overdraft = payload.amount;
				}
			}
			state.currentCard.overdraftData.isLoading = false;
		});
		builder.addCase(updateRadabankOverdraftData.rejected, (state) => {
			state.currentCard.overdraftData.isLoading = false;
		});

		builder.addCase(getRadabankCardCurrentRules.pending, (state) => {
			state.currentCard.currentRules.isLoading = true;
		});
		builder.addCase(getRadabankCardCurrentRules.fulfilled, (state, { payload }) => {
			state.currentCard.currentRules.list = payload;
			state.currentCard.currentRules.isLoading = false;
		});
		builder.addCase(getRadabankCardCurrentRules.rejected, (state) => {
			state.currentCard.currentRules.isLoading = false;
		});

		builder.addCase(deleteRadabankCardCurrentRule.pending, (state) => {
			state.currentCard.currentRules.isLoading = true;
		});
		builder.addCase(deleteRadabankCardCurrentRule.fulfilled, (state, { payload }) => {
			state.currentCard.currentRules.list = state.currentCard.currentRules.list.filter(
				(item) => item.id !== payload.ruleid
			);
			state.currentCard.currentRules.isLoading = false;
		});
		builder.addCase(deleteRadabankCardCurrentRule.rejected, (state) => {
			state.currentCard.currentRules.isLoading = false;
		});

		builder.addCase(getRadabankCardSmsPhonesList.pending, (state) => {
			state.currentCard.smsInfo.isLoading = true;
		});
		builder.addCase(getRadabankCardSmsPhonesList.fulfilled, (state, { payload }) => {
			state.currentCard.smsInfo.list = payload.rules;
			state.currentCard.smsInfo.isLoading = false;
		});
		builder.addCase(getRadabankCardSmsPhonesList.rejected, (state) => {
			state.currentCard.smsInfo.isLoading = false;
		});

		builder.addCase(setRadabankCardSmsPhone.pending, (state) => {
			state.currentCard.smsInfo.isLoading = true;
		});
		builder.addCase(setRadabankCardSmsPhone.fulfilled, (state, { payload }) => {
			if (payload.typeid === RADABANK_SMS_TYPE_ID.DELETE) {
				state.currentCard.smsInfo.list = state.currentCard.smsInfo.list.filter(
					(item) => removeCodeFormPhone(item.phone) !== payload.phonedest
				);
			}
			state.currentCard.smsInfo.isLoading = false;
		});
		builder.addCase(setRadabankCardSmsPhone.rejected, (state) => {
			state.currentCard.smsInfo.isLoading = false;
		});
		builder.addCase(getRadabankInfoStatus.fulfilled, (state, { payload }) => {
			state.infoStatus = payload;
		});

		builder.addCase(getRadabankInfoSprData.pending, (state) => {
			state.infoSprData.isLoading = true;
		});
		builder.addCase(getRadabankInfoSprData.fulfilled, (state, { payload }) => {
			state.infoSprData.data = payload;
			state.infoSprData.isLoading = false;
		});
		builder.addCase(getRadabankInfoSprData.rejected, (state) => {
			state.infoSprData.isLoading = false;
		});
		builder.addCase(verifyInternalActivateCode.fulfilled, (state, { payload }) => {
			if (state.radabankInternalClient.data) {
				state.radabankInternalClient.data = {
					...state.radabankInternalClient.data,
					username: payload,
				};
			}
		});
		builder.addCase(verifyCobrandCard.fulfilled, (state, { payload }) => {
			if (state.radabankInternalClient.data && payload.finish !== RADABANK_BOOLEAN_VALUE.FALSE) {
				state.radabankInternalClient.data = {
					...state.radabankInternalClient.data,
					cardexists: payload.finish,
				};
			}
		});
		builder.addCase(checkRadabankPlasticCardStatus.fulfilled, (state, { payload }) => {
			state.currentCard.plasticCardStatus = payload;
		});
	},
});

export const {
	resetRadabankReducer,
	updateInfoStatusIdRadabankReducer,
	resetRadabankReducerOperations,
	resetRadabankStatements,
} = radabankCardStore.actions;

export default radabankCardStore.reducer;

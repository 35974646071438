import { FC } from 'react';
import { getRadabankInfoSprDataLoadingSelector, getRadabankInfoSprDataSelector, useAppSelector } from 'store';
import { FormNumberInput, FormTextField } from 'components/shared';
import { FormCustomAutocomplete } from 'components/shared/FormAutocomplete';
import { SocialStatusContainer } from './SocialStatusContainer';

export const SurveyLetterGeneralStep: FC = () => {
	const isLoading = useAppSelector(getRadabankInfoSprDataLoadingSelector);
	const infoSprData = useAppSelector(getRadabankInfoSprDataSelector);

	return (
		<>
			<FormTextField name="secret" label="Кодове слово" />
			<FormTextField name="email" label="Email" type="email" />
			<FormCustomAutocomplete
				name="social_status"
				label="Зайнятість"
				options={infoSprData?.socialStatus}
				loading={isLoading}
			/>
			<SocialStatusContainer>
				<FormTextField name="work_place" label="Місце роботи" />
				<FormTextField name="work_dolg" label="Посада" />
				<FormCustomAutocomplete
					name="term_work"
					label="Строк роботи на поточному місці"
					options={infoSprData?.termWork}
					loading={isLoading}
				/>
			</SocialStatusContainer>
			<FormNumberInput
				name="amount_in"
				label="Щомісячний дохід(гривень)"
				inputProps={{ inputMode: 'decimal', autoComplete: 'off' }}
			/>
			<FormNumberInput
				name="amount_out"
				label="Щомісячні витрати(гривень)"
				inputProps={{ inputMode: 'decimal', autoComplete: 'off' }}
			/>
		</>
	);
};

import { ChangeEvent, FC } from 'react';
import { NumberFormatValues, NumericFormat, PatternFormat } from 'react-number-format';
import { TextField } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { CustomButton } from 'components/shared';
import { getTextFieldValidationProps } from 'utils/helpers';
import { IRadabankCreatePaymentRequest } from 'utils/types';
import styles from './index.module.scss';

export const FormContent: FC = () => {
	const { setFieldValue, errors, touched, isValid } = useFormikContext<IRadabankCreatePaymentRequest>();

	const handleChangePrevent = (event: ChangeEvent<HTMLDivElement>): void => {
		event.preventDefault();
	};

	const handleChangeAmountValue = ({ value }: NumberFormatValues): void => {
		setFieldValue('amount', value);
	};

	return (
		<>
			<div className={styles.content}>
				<Field
					name="card"
					onValueChange={(props: NumberFormatValues) => setFieldValue('card', props.value)}
					as={PatternFormat}
					valueIsNumericString
					format="#### #### #### ####"
					customInput={TextField}
					label="Картка отримувача"
					onChange={handleChangePrevent}
					{...getTextFieldValidationProps(errors, touched, 'card')}
				/>
				<Field
					name="amount"
					label="Сума переказу"
					as={NumericFormat}
					{...getTextFieldValidationProps(errors, touched, 'amount')}
					customInput={TextField}
					onValueChange={handleChangeAmountValue}
					onChange={undefined}
					allowNegative={false}
					allowLeadingZeros
					fixedDecimalScale
					defaultValue={0}
					thousandSeparator=" "
					inputProps={{ inputMode: 'decimal', autoComplete: 'off' }}
					decimalScale={2}
					allowedDecimalSeparators={[',']}
				/>
				<Field name="comment" as={TextField} label="Коментар" multiline />
			</div>
			<CustomButton type="submit" label="Далі" disabled={!isValid} />
		</>
	);
};

import { FC, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import {
	getRadabankCurrentCardSelector,
	getUserProfileSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import {
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	FIREBASE_EVENT_ANALYTICS_PAGE,
	RADABANK_CARD_CREATE_PAYMENT_INITIAL_VALUES,
} from 'utils/constants';
import { convertUAHToCoins, getErrorMessage } from 'utils/helpers';
import { FirebaseAnalytics, RadabankService } from 'utils/services';
import { IRadabankCreatePaymentRequest, IRadabankFeeResponse } from 'utils/types';
import { radabankCardCreateCardPaymentValidationSchema } from 'utils/validation';
import { FormContent } from './FormContent';
import { OperationDetails } from './OperationDetails';
import styles from './index.module.scss';

export const RadabankOperationCard: FC = () => {
	const dispatch = useAppDispatch();

	const profile = useAppSelector(getUserProfileSelector);
	const cardData = useAppSelector(getRadabankCurrentCardSelector);

	const [initialValues, setInitialValues] = useState<IRadabankCreatePaymentRequest>(
		RADABANK_CARD_CREATE_PAYMENT_INITIAL_VALUES
	);
	const [isShowDetails, setIsShowDetails] = useState(false);
	const [feeData, setFeeData] = useState<IRadabankFeeResponse | null>(null);
	const [cardPaymentData, setCardPaymentData] = useState<IRadabankCreatePaymentRequest | null>(null);

	useEffect(() => {
		if (profile && cardData) {
			setInitialValues({ ...initialValues, userId: profile.userId, cardid: cardData.id });
		}
	}, [profile, cardData]);

	const handleSubmit = async (values: IRadabankCreatePaymentRequest) => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.OPERATION_TO_CARD,
				FIREBASE_EVENT_ANALYTICS_BUTTON.NEXT
			);
			dispatch(setAppLoading(true));
			const response = await RadabankService.getCardFee({ ...values, amount: `${convertUAHToCoins(values.amount)}` });
			setFeeData(response.data);
			setCardPaymentData({ ...values, amount: `${convertUAHToCoins(values.amount)}` });
			setIsShowDetails(true);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	return (
		<div className={styles.content}>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validationSchema={radabankCardCreateCardPaymentValidationSchema}
			>
				<Form>
					<FormContent />
				</Form>
			</Formik>
			{isShowDetails && !!cardPaymentData && !!feeData && (
				<OperationDetails
					feeData={feeData}
					cardPaymentData={cardPaymentData}
					onClickBack={() => setIsShowDetails(false)}
				/>
			)}
		</div>
	);
};

import { FC, useEffect } from 'react';
import { IconButton, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import {
	closeConfirmModal,
	deleteRadabankCardCurrentRule,
	getAppLoadingSelector,
	getRadabankCardCurrentRules,
	getRadabankCurrentCardRulesSelector,
	getRadabankCurrentCardSelector,
	getUserProfileSelector,
	openConfirmModal,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { Loader } from 'components/shared';
import { getErrorMessage } from 'utils/helpers';
import { IRadabankCurrentRuleItem } from 'utils/types';
import styles from './index.module.scss';

export const RadabankCardSecureSettingsRulesList: FC = () => {
	const dispatch = useAppDispatch();

	const cardRules = useAppSelector(getRadabankCurrentCardRulesSelector);
	const currentCard = useAppSelector(getRadabankCurrentCardSelector);
	const profile = useAppSelector(getUserProfileSelector);
	const isAppLoading = useAppSelector(getAppLoadingSelector);

	useEffect(() => {
		if (currentCard?.id && profile?.userId) {
			getCardRules(profile.userId, currentCard.id);
		}
	}, [currentCard?.id, profile?.userId]);

	const getCardRules = async (userId: string, cardid: string): Promise<void> => {
		try {
			await dispatch(getRadabankCardCurrentRules({ userId, cardid })).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleClickDelete = (data: IRadabankCurrentRuleItem) => {
		dispatch(
			openConfirmModal({
				title: 'Видалити правило',
				subTitle: 'Ви впевнені, що бажаєте видалити правило?',
				icon: <TrashIcon />,
				submitButtonLabel: 'Так, видалити',
				onSubmit: () => handleDeleteCurrentRule(data),
			})
		);
	};

	const handleDeleteCurrentRule = async (data: IRadabankCurrentRuleItem) => {
		try {
			if (currentCard && profile) {
				await dispatch(
					deleteRadabankCardCurrentRule({
						userId: profile.userId,
						cardid: currentCard.id,
						ruleid: data.id,
						rulename: data.name,
					})
				);
				dispatch(closeConfirmModal());
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	return (
		<>
			<ul className={styles.list}>
				{cardRules.list.length ? (
					<>
						{cardRules.list.map((item) => (
							<li className={styles.item} key={item.id}>
								<div className={styles.item__info}>
									<Typography>{item.name}</Typography>
									<Typography variant="caption" color="var(--color-neutral-500)">
										{`${item.datestart} - ${item.datefinish}`}
									</Typography>
								</div>
								<IconButton className={styles.button_delete} onClick={() => handleClickDelete(item)}>
									<CloseIcon />
								</IconButton>
							</li>
						))}
					</>
				) : (
					<Typography variant="caption" color="var(--color-neutral-600)" textAlign="center">
						У вас поки немає встановлених дозволів
					</Typography>
				)}
			</ul>
			{cardRules.isLoading && !isAppLoading && <Loader />}
		</>
	);
};

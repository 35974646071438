import { FC, useState } from 'react';
import {
	getUserProfileSelector,
	useAppSelector,
} from 'store';
import { ConfirmOTPCode } from 'components/Auth';
import { ArrowBackButton, CustomButton } from 'components/shared';
import {
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	FIREBASE_EVENT_ANALYTICS_PAGE,
	RADABANK_OTP_LENGTH,
} from 'utils/constants';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics } from 'utils/services';

interface RadabankInternalActivateConfirmationProps {
	onSubmit: (otp: string) => Promise<void>;
	onResendCode: () => void;
}

export const RadabankInternalActivateConfirmation: FC<RadabankInternalActivateConfirmationProps> = ({
	onSubmit,
	onResendCode,
}) => {
	const handleClickBack = useGoBackRadabank();

	const profile = useAppSelector(getUserProfileSelector);

	const [otp, setOtp] = useState('');

	const handleChangeOTP = (value: string): void => {
		setOtp(value);
	};

	const handleSubmit = () => {
		onSubmit(otp);
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.ACTIVATE_RB_USER,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	return (
		<div className="host-card-page-color-white-auth">
			<ArrowBackButton onClick={handleGoBack} />
			<div className="auth-content">
				<ConfirmOTPCode
					title="Для відкриття картки в HOST, необхідно підтвердити номер телефону. Введіть код із SMS"
					phone={profile?.phone ?? ''}
					value={otp}
					onChange={handleChangeOTP}
					isValid
					onResendCode={onResendCode}
					length={RADABANK_OTP_LENGTH}
				/>
			</div>
			<CustomButton label="Продовжити" onClick={handleSubmit} disabled={otp.length !== RADABANK_OTP_LENGTH} />
		</div>
	);
};

import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { MenuList, Typography } from '@mui/material';
import {
	getRadabankCurrentCardSelector,
	getUserProfileSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import {
	RadabankCardLockReasonItem,
	RadabankOnboardingContent,
	RadabankOperationDetailInfo,
	RadabankSectionHeader,
} from 'components/RadabankCard';
import { ArrowBackButton, CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics, RadabankService } from 'utils/services';
import { IRadabankRuleItem, IUserProfileResponse } from 'utils/types';
import styles from './index.module.scss';

export const RadabankBlockCardPage: FC = () => {
	const handleClickBack = useGoBackRadabank();

	const history = useHistory();

	const dispatch = useAppDispatch();

	const currentCard = useAppSelector(getRadabankCurrentCardSelector);
	const profile = useAppSelector(getUserProfileSelector);

	const [selectedCardLockReason, setSelectedCardLockReason] = useState('');
	const [reasonsList, setReasonsList] = useState<IRadabankRuleItem[]>([]);
	const [isShowBanner, setIsShowBanner] = useState(false);

	useEffect(() => {
		handleGetLockCardReasons();
	}, []);

	const handleGetLockCardReasons = async (): Promise<void> => {
		try {
			dispatch(setAppLoading(true));
			const response = await RadabankService.getInternalCardLockReasons((profile as IUserProfileResponse).userId);
			setReasonsList(response.data.reasons);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleClickBlockCard = async (): Promise<void> => {
		try {
			if (currentCard) {
				FirebaseAnalytics.logEvent(
					FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.BLOCK_CARD,
					FIREBASE_EVENT_ANALYTICS_BUTTON.BLOCK
				);
				dispatch(setAppLoading(true));
				await RadabankService.blockInternalCard({
					userId: (profile as IUserProfileResponse).userId,
					cardid: currentCard.id,
					reasonid: selectedCardLockReason,
				});
				setIsShowBanner(true);
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleChangeSelectedCardLockReason = (value: string) => {
		setSelectedCardLockReason(value);
	};

	const handleClickRedirectMain = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.BLOCK_CARD,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_HOME
		);
		history.push(ROUTER_URL.RADABANK_CARD);
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.BLOCK_CARD,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	return isShowBanner ? (
		<div className="host-card-page-color-primary">
			<ArrowBackButton onClick={handleGoBack} />
			<RadabankOnboardingContent
				title="Картка буде заблокована за кілька хвилин"
				description={['Ми повідомимо, коли все буде готово']}
			/>
			<CustomButton label="Зрозуміло" color="secondary" onClick={handleClickRedirectMain} />
		</div>
	) : (
		<div className="host-card-page-color-white-auth">
			<RadabankSectionHeader title="Блокування картки" onClickBack={handleGoBack} />
			<div className={styles.content}>
				<Typography variant="body2" color="var(--color-primary-500)" textAlign="center" marginBottom="var(--spacing-3)">
					Вкажіть причину блокування
				</Typography>
				<MenuList className={styles.list}>
					{reasonsList.map((item) => (
						<RadabankCardLockReasonItem
							key={item.id}
							onChange={handleChangeSelectedCardLockReason}
							label={item.name}
							value={item.id}
							isSelected={item.id === selectedCardLockReason}
						/>
					))}
				</MenuList>
				<RadabankOperationDetailInfo
					isError
					// eslint-disable-next-line max-len
					description="Після блокування викраденої або загубленої карти, її розблокування неможливе. Замість цього картку потрібно перевипустити. Розблокування та перевипуск картки є платними послугами. Вартість цих послуг вказана у тарифах."
				/>
			</div>
			<CustomButton onClick={handleClickBlockCard} label="Блокувати" disabled={!selectedCardLockReason} />
		</div>
	);
};
